import Square from "assets/imgs/dropdown/square.svg";
import Wide from "assets/imgs/dropdown/wide.svg";
import Portrait from "assets/imgs/dropdown/portrait.svg";

import Palette from "assets/imgs/dropdown/palette.svg";
import PaletteWhite from "assets/imgs/dropdown/palette_white.svg";
import PaletteMuted from "assets/imgs/dropdown/palette_muted.svg";
import PaletteWarm from "assets/imgs/dropdown/palette_warm.svg";
import PaletteCool from "assets/imgs/dropdown/palette_cool.svg";
import PaletteVibrant from "assets/imgs/dropdown/palette_vibrant.svg";
import PalettePastel from "assets/imgs/dropdown/palette_pastel.svg";
import PaletteBrand from "assets/imgs/dropdown/palette_brand.svg";

import Lightning from "assets/imgs/dropdown/lightning.svg";
import LightningStudio from "assets/imgs/dropdown/lightning_studio.svg";
import LightningLow from "assets/imgs/dropdown/lightning_low.svg";
import LightningGolden from "assets/imgs/dropdown/lightning_golden.svg";
import LightningBack from "assets/imgs/dropdown/lightning_back.svg";
import LightningDramatic from "assets/imgs/dropdown/lightning_dramatic.svg";
import LightningSoftbox from "assets/imgs/dropdown/lightning_softbox.svg";
import LightningNatural from "assets/imgs/dropdown/lightning_natural.svg";

import Composition from "assets/imgs/dropdown/composition.svg";

const varRatioOptions = [
    {
        key: 1,
        id: "square",
        icon: Square,
        color: "white",
        checked: true,
        label: "Square (1:1)",
    },
    {
        key: 2,
        id: "wide",
        icon: Wide,
        color: "white",
        checked: false,
        label: "wide (16:9)",
    },
    {
        key: 3,
        id: "portrait",
        icon: Portrait,
        color: "white",
        checked: false,
        label: "Portrait (9:16)",
    },
];

const varColorToneOptions = [
    { key: 1, id: "none", icon: PaletteWhite, checked: true, label: "None" },
    {
        key: 2,
        id: "black_white",
        icon: Palette,
        checked: false,
        label: "Black & White",
    },
    {
        key: 3,
        id: "muted_color",
        icon: PaletteMuted,
        checked: false,
        label: "Muted Color",
    },
    {
        key: 4,
        id: "warm_tone",
        icon: PaletteWarm,
        checked: false,
        label: "Warm Tone",
    },
    {
        key: 5,
        id: "cool_tone",
        icon: PaletteCool,
        checked: false,
        label: "Cool Tone",
    },
    {
        key: 6,
        id: "vibrant_color",
        icon: PaletteVibrant,
        checked: false,
        label: "Vibrant Colors",
    },
    {
        key: 7,
        id: "pastel_color",
        icon: PalettePastel,
        checked: false,
        label: "Pastel Colors",
    },
    {
        key: 8,
        id: "brand_color",
        icon: PaletteBrand,
        checked: false,
        label: "Brand Colors",
    },
];

const varLightningOptions = [
    { key: 1, id: "none", icon: Lightning, checked: true, label: "None" },
    {
        key: 2,
        id: "studio_lightning",
        icon: LightningStudio,
        checked: false,
        label: "Studio Lighting",
    },
    {
        key: 3,
        id: "low_lightning",
        icon: LightningLow,
        checked: false,
        label: "Low Lighting",
    },
    {
        key: 4,
        id: "golden_sunset",
        icon: LightningGolden,
        checked: false,
        label: "Golden Sunset",
    },
    {
        key: 5,
        id: "back_lightning",
        icon: LightningBack,
        checked: false,
        label: "Back Lighting",
    },
    {
        key: 6,
        id: "dramatic_lightning",
        icon: LightningDramatic,
        checked: false,
        label: "Dramatic Lighting",
    },
    {
        key: 7,
        id: "softbox_lightning",
        icon: LightningSoftbox,
        checked: false,
        label: "Softbox Lighting",
    },
    {
        key: 8,
        id: "natural_lightning",
        icon: LightningNatural,
        checked: false,
        label: "Natural Lighting",
    },
];

const varCompositionOptions = [
    { key: 1, id: "none", icon: Composition, checked: true, label: "None" },
    {
        key: 2,
        id: "blurry_background",
        icon: Composition,
        checked: false,
        label: "Blurry Background",
    },
    {
        key: 3,
        id: "close_up",
        icon: Composition,
        checked: false,
        label: "Close Up",
    },
    {
        key: 4,
        id: "wide_angle",
        icon: Composition,
        checked: false,
        label: "Wide Angle",
    },
    {
        key: 5,
        id: "shot",
        icon: Composition,
        checked: false,
        label: "Shot from Above",
    },
    {
        key: 6,
        id: "long",
        icon: Composition,
        checked: false,
        label: "Long Exposure",
    },
    {
        key: 7,
        id: "panoramic",
        icon: Composition,
        checked: false,
        label: "Panoramic Shots",
    },
    {
        key: 8,
        id: "macrophotography",
        icon: Composition,
        checked: false,
        label: "Macrophotography",
    },
];

const varEngineOptions = [
    {
        key: 1,
        id: "stability",
        color: "white",
        checked: true,
        label: "Stability AI Engine",
    },
    {
        key: 2,
        id: "stablediffusion3",
        color: "white",
        checked: false,
        label: "Stable Diffusion 3.0",
    },
];

export {
    varRatioOptions,
    varColorToneOptions,
    varLightningOptions,
    varCompositionOptions,
    varEngineOptions,
};