import { useState, useRef } from "react";
import loadingWebp from "assets/imgs/loading.webp";
import processingWebp from "assets/imgs/processing.webp";
import { LazyLoadImage, LazyLoadComponent } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

export default function CVideo({
  src,
  alt = "",
  addClass = "",
  width,
  height,
  onClick = () => { },
  placeholder = ''
}) {
  const videoRef = useRef(null);
  const [videoLoaded, setVideoLoaded] = useState(false);

  const handleVideoLoaded = () => {
    setVideoLoaded(true);
  };

  return (
    <div>
      {!src && (
        <LazyLoadImage
          className={addClass}
          alt={alt}
          effect="blur"
          src={processingWebp}
          width={width}
          height={height}
          onClick={() => {
            if (videoRef.current) {
              videoRef.current.play();
            }
          }}
        />
      )}
      {src && !videoLoaded && (
        <LazyLoadImage
          className={addClass}
          alt={alt}
          effect="blur"
          src={loadingWebp}
          width={width}
          height={height}
          onClick={() => {
            if (videoRef.current) {
              videoRef.current.play();
            }
          }}
        />
      )}
      <LazyLoadComponent>
        <video
          style={{ display: videoLoaded ? "block" : "none" }}
          controls
          ref={videoRef}
          onLoadedData={handleVideoLoaded}
          width={width}
          height={height}
          src={src}
          type="video/mp4"
        >
          Your browser does not support the video tag.
        </video>
      </LazyLoadComponent>
    </div>
  );
}