import { AdminLayout } from "components/layouts/AdminLayout";
import "./styles.scss";
import { format } from "date-fns";
import { useContext } from "react";
import { LayoutContext } from "components/contexts/LayoutContextContainer";
import { useEffect } from "react";
import { getApi } from "utils/customNetwork";
import { useState } from "react";
import { Slide } from "react-awesome-reveal";
import { ReactComponent as IconEdit } from "assets/imgs/action/icon_edit.svg";
import { ReactComponent as IconRemove } from "assets/imgs/action/icon_delete.svg";
import { ReactComponent as IconLogin } from "assets/imgs/admin/dashboard/login_black.svg";
import { ReactComponent as IconPig } from "assets/imgs/modal/pig.svg";
import { CInput } from "components/atoms/CInput";
import { CButton } from "components/atoms/CButton";
import useTable from "hooks/useTable";
import ConfirmationModal from "components/atoms/Modal/ConfirmationModal";
import { UserModal } from "components/atoms/Modal/UserModal";
import { CreditHistoryModal } from "components/atoms/Modal/CreditHistoryModal";
import getFullName from "utils/getFullName";
import { LICENSE } from "utils/constants";
import { varLicenseOptions } from "./variable";
import { CDropdown } from "components/atoms/CDropdown";
import { CommonTable } from "components/Table";
import { Avatar, Box, Tooltip } from "@mui/material";
import { ICONS } from "assets";
import { COLORS } from "utils/theme";
import moment from "moment";
import { commonSorting } from "utils/util";
const rowsPerPage = 10;

export function AdminUsers() {
  const { setLoading, showNoti, checkToken, setUser } =
    useContext(LayoutContext);
  const [showCreditModal, setShowCreditModal] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  // const [keyword, setKeyword] = useState("");
  const [page, setPage] = useState(1);
  const { slice, range } = useTable(filteredUsers, page, rowsPerPage);
  const [licenseOptions, setLicenseOptions] = useState(varLicenseOptions);
  const [selectedLicense, setSelectedLicense] = useState("license");
  // modal user area
  const [showUserModal, setShowUserModal] = useState(false);
  const [selUser, setSelUser] = useState();
  const getActiveMenu = (arr) => {
    // if(arr && arr.length() === 0) return null
    const chk = arr.find((k) => k.checked);
    if (chk) return chk;
    else return arr[0];
  };
  const handleLicenseRatio = (id, index, value) => {
    switch (id) {
      case "license_option":
        const customlicenseOptions = [...licenseOptions];
        customlicenseOptions.forEach((el) => (el.checked = false));
        customlicenseOptions[index].checked = value;
        setLicenseOptions(customlicenseOptions);
        setSelectedLicense(getActiveMenu(customlicenseOptions).id);
        break;
      default:
    }
  };
  const getDashboardData = async (showLoading = true) => {
    if (showLoading) setLoading(true);
    try {
      checkToken();
      const data = await getApi("/admin/getUsers", "POST", {});
      setUsers(data.users);
      // onSearch(data.users);
    } catch (err) {
      showNoti("error", err);
    }
    if (showLoading) setLoading(false);
  };

  // const onSearch = (orgUsers) => {
  //   setPage(1);
  //   const filtered = orgUsers.filter((user) => {
  //     const searchTerm = keyword.toLowerCase();
  //     const name = (user.first_name + " " + user.last_name).toLowerCase();
  //     const email = user.email.toLowerCase();
  //     const status = user.status.toLowerCase();
  //     const source = user.source.toLowerCase();

  //     if (Array.isArray(user.license)) {
  //       // Check if any license in the array matches the search term
  //       const licenseMatch = user.license.some((license) =>
  //         license.toLowerCase().includes(searchTerm)
  //       );
  //       return (
  //         name.includes(searchTerm) ||
  //         email.includes(searchTerm) ||
  //         licenseMatch ||
  //         status.includes(searchTerm) ||
  //         source.includes(searchTerm)
  //       );
  //     } else {
  //       // If license is not an array, simply check if it includes the search term
  //       const license = user.license.toLowerCase();
  //       return (
  //         name.includes(searchTerm) ||
  //         email.includes(searchTerm) ||
  //         license.includes(searchTerm) ||
  //         status.includes(searchTerm) ||
  //         source.includes(searchTerm)
  //       );
  //     }

  //     /* Old logic */
  //     // const license = user.license.toLowerCase();
  //     // return (
  //     //   name.includes(searchTerm) ||
  //     //   email.includes(searchTerm) ||
  //     //   license.includes(searchTerm)
  //     // );
  //   });

  //   setFilteredUsers(filtered);
  // };
  const handleCreateUser = () => {
    setSelUser();
    setShowUserModal(true);
  };
  const shortName = (userProfile) => {
    if (userProfile.first_name !== "") {
      return userProfile.first_name.substring(0, 2).toUpperCase();
    } else {
      return userProfile.email.substring(0, 2).toUpperCase();
    }
  };
  const onCloseUserModal = () => {
    setShowUserModal(false);
  };
  const handleTblEdit = (item) => {
    setSelUser(item);
    setShowUserModal(true);
  };

  const confirmDeletion = async (item) => {
    setLoading(true);

    try {
      // Ensure that checkToken, getApi, showNoti, and getDashboardData are defined
      checkToken();
      await getApi("/admin/deleteUser", "POST", { user_id: item.id });
      showNoti("success", "Delete user successfully");
      getDashboardData(false);
    } catch (err) {
      showNoti("error", err);
    }

    setLoading(false);
    setShowConfirmation(false);
    setItemToDelete(null);
  };

  const handleTblDelete = (item) => {
    setItemToDelete(item);
    setShowConfirmation(true);
  };

  const closeModal = () => {
    setShowConfirmation(false);
    setItemToDelete(null);
  };

  const handleTblCredit = (item) => {
    setSelUser(item);
    setShowCreditModal(true);
  };

  const handleTblUserLogin = async (item) => {
    setLoading(true);
    try {
      checkToken();
      const data = await getApi("/admin/getUserToken", "POST", {
        user_id: item.id,
      });

      const expired = Math.floor(Date.now() / 1000) + 60 * 60; // 60 mins

      setUser({ user: data.user, token: data.token, expired });
      //redirect to home
      window.open("/dashboard", "_blank", "noreferrer");
    } catch (err) {
      showNoti("error", err);
    }
    setLoading(false);
  };

  // const handleTblDelete = async (item) => {
  //   console.log(item);
  //   const isConfirmed = window.confirm(`Are you sure you want to delete user ${item.first_name + ' ' + item.last_name}?`);
  //   if (isConfirmed) {
  //     setLoading(true);
  //     try {
  //       checkToken();
  //       await getApi("/admin/deleteUser", "POST", { user_id: item.id });
  //       showNoti("success", "Delete user successfully");
  //       getDashboardData(false);
  //     } catch (err) {
  //       showNoti("error", err);
  //     }
  //     setLoading(false);
  //   }
  // };

  const onSearchLicense = (orgUsers) => {
    setPage(1);

    const searchLicense = selectedLicense.toLowerCase();
    const filteredLicense = orgUsers.filter((user) => {
      if (searchLicense !== "license") {
        if (Array.isArray(user.license)) {
          const licenseData = user.license.some((license) =>
            license.toLowerCase().includes(searchLicense)
          );
          return licenseData;
        } else {
          const license = user.license.toLowerCase();
          return license.includes(searchLicense);
        }
      }
    });

    if (searchLicense !== "license") {
      setFilteredUsers(filteredLicense);
    } else {
      setFilteredUsers(orgUsers);
    }
  };

  useEffect(() => {
    getDashboardData();
  }, []);

  // useEffect(() => {
  //   onSearch(users);
  // }, [keyword]);

  useEffect(() => {
    onSearchLicense(users);
  }, [selectedLicense]);

  const ActionsContainer = ({ item }) => {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
        }}
      >
        <Tooltip title="Edit">
          <ICONS.Edit
            onClick={() => handleTblEdit(item)}
            sx={{ color: COLORS.white, cursor: "pointer" }}
          />
        </Tooltip>
        <Tooltip title="Credit Histories">
          <IconPig
            onClick={() => handleTblCredit(item)}
            style={{ width: 24, height: 22, cursor: "pointer" }}
          />
        </Tooltip>
        <Tooltip title="Direct Login">
          <IconLogin
            onClick={() => handleTblUserLogin(item)}
            style={{ width: 24, height: 24, cursor: "pointer" }}
          />
        </Tooltip>
        <Tooltip title="Delete">
          <ICONS.Delete
            onClick={() => handleTblDelete(item)}
            sx={{ color: COLORS.white, cursor: "pointer" }}
          />
        </Tooltip>
      </Box>
    );
  };

  const adminUserColumns = [
    {
      id: "role",
      fieldName: "role",
      label: "Role",
      align: "center",
      cellStyle: {
        minWidth: 70,
      },
    },
    {
      id: "profile_img",
      fieldName: "profile_img",
      label: "Photo",
      render: ({ rowData }) => (
        <Avatar
          alt={rowData.first_name || rowData.email}
          src={rowData.profile_img}
          style={{ margin: "auto" }}
        />
      ),
      align: "center",
      cellStyle: {
        minWidth: 70,
      },
      sorting: false,
    },
    {
      id: "name",
      fieldName: "name",
      label: "Name",
      render: ({ rowData }) => getFullName(rowData),
      customSort: (dataA, dataB, order) => {
        return commonSorting(getFullName(dataA), getFullName(dataB), order);
      },
      align: "center",
    },
    {
      id: "email",
      fieldName: "email",
      label: "Email",
      align: "center",
    },
    {
      id: "license",
      fieldName: "license",
      label: "License Type",
      render: ({ rowData }) => rowData.license.join(", "),
      align: "center",
      sorting: false,
    },
    {
      id: "source",
      fieldName: "source",
      label: "Source",
      render: ({ rowData }) =>
        rowData.source === "paddle" ? "Paddle" : "JvZoo",
      align: "center",
      customSort: (dataA, dataB, order) => {
        return commonSorting(
          dataA.source === "paddle" ? "Paddle" : "JvZoo",
          dataB.source === "paddle" ? "Paddle" : "JvZoo",
          order
        );
      },
    },

    {
      id: "credits",
      fieldName: "credits",
      label: "Credits Usage",
      render: ({ rowData }) =>
        rowData.license.includes(LICENSE.PROFESSIONAL)
          ? "Unlimited"
          : rowData.credits,
      align: "center",
      sorting: false,
    },
    {
      id: "status",
      fieldName: "status",
      label: "Status",
      align: "center",
    },
    {
      id: "created_at",
      fieldName: "created_at",
      label: "Created At",
      render: ({ rowData }) =>
        moment(rowData?.created_at).format("DD-MM-YYYY hh:mm A"),
      align: "center",
      cellStyle: {
        minWidth: 130,
      },
      customSort: (dataA, dataB, order) => {
        return commonSorting(
          moment(dataA?.created_at).format("DD-MM-YYYY hh:mm A"),
          moment(dataB?.created_at).format("DD-MM-YYYY hh:mm A"),
          order
        );
      },
    },
    {
      id: "id",
      fieldName: "id",
      label: "Actions",
      align: "center",
      render: (row) => {
        return <ActionsContainer item={row.rowData} />;
      },
      cellStyle: {
        minWidth: 200,
      },
      sorting: false,
    },
  ];

  return (
    <AdminLayout
      page="dashboard"
      caption="Dashboard"
      message="Welcome back admin"
    >
      <div className="admin-users body-container">
        <div className="head">
          <Slide className="flex1">
            <h1>
              <span>Manage</span> Users
            </h1>
          </Slide>
        </div>
        <div className="btn-group mt-35">
          {/* <div className="search-area">
            <CInput
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
              addClass="full text-white"
            />
          </div> */}
          <div className="search-area license-section">
            <CDropdown
              addClass="full"
              id="license_option"
              action={handleLicenseRatio}
              activeMenu={getActiveMenu(licenseOptions)}
              menu={licenseOptions}
            />
          </div>
          {/* <div className="btn-mode">
            <CButton
              addClass="gradient"
              active={true}
              onClick={handleCreateUser}
            >
              Create User
            </CButton>
          </div> */}
        </div>
        {/* <div className="custom-table mt-25 mb-60">
          <table>
            <thead>
              <tr>
                <th className="tableHeader">Role</th>
                <th className="tableHeader">Photo</th>
                <th className="tableHeader">Name</th>
                <th className="tableHeader">Email</th>
                <th className="tableHeader">License Type</th>
                <th className="tableHeader">Source</th>
                <th className="tableHeader">Credits Usage</th>
                <th className="tableHeader">Status</th>
                <th className="tableHeader">Created At</th>
                <th className="tableHeader">Action</th>
              </tr>
            </thead>
            <tbody>
              {slice.length === 0 ? (
                <tr>
                  <td colSpan="9" className="tableCell text-center">
                    No records found
                  </td>
                </tr>
              ) : (
                slice.map((el) => (
                  <tr key={el.id}>
                    <td className="tableCell">{el.role}</td>
                    <td className="tableCell">
                      <div className="user-initial">
                        {el.profile_img ? (
                          <div className="user-avatar">
                            <img src={el.profile_img} alt="profile" />
                          </div>
                        ) : (
                          <div className="user-avatar">{shortName(el)}</div>
                        )}
                      </div>
                    </td>
                    <td className="tableCell">{getFullName(el)}</td>
                    <td className="tableCell">{el.email}</td>
                    <td className="tableCell">{el.license.join(", ")}</td>
                    <td className="tableCell">
                      {el.source === "paddle" ? "Paddle" : "JvZoo"}
                    </td>
                    <td className="tableCell">
                      {el.license.includes(LICENSE.PROFESSIONAL)
                        ? "Unlimited"
                        : el.credits}
                    </td>
                    <td className="tableCell">{el.status}</td>
                    <td className="tableCell">
                      {format(new Date(el.created_at), "dd MMM, yyyy")}
                    </td>
                    <td className="tableCell text-center">
                      <IconEdit
                        data-tooltip-id="tip-edit"
                        data-tooltip-variant="light"
                        data-tooltip-content="Edit"
                        onClick={() => handleTblEdit(el)}
                        className="mr-10"
                      />
                      <IconPig
                        data-tooltip-id="tip-credit"
                        data-tooltip-variant="light"
                        data-tooltip-content="Credit Histories"
                        onClick={() => handleTblCredit(el)}
                        className="mr-10"
                      />
                      <IconLogin
                        data-tooltip-id="tip-direct-login"
                        data-tooltip-variant="light"
                        data-tooltip-content="Direct Login"
                        onClick={() => handleTblUserLogin(el)}
                        className="mr-10"
                      />
                      <IconRemove
                        data-tooltip-id="tip-delete"
                        data-tooltip-variant="light"
                        data-tooltip-content="Delete"
                        onClick={() => handleTblDelete(el)}
                      />
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>

          <Tooltip id="tip-edit" />
          <Tooltip id="tip-direct-login" />
          <Tooltip id="tip-credit" />
          <Tooltip id="tip-delete" />
          <ConfirmationModal
            isOpen={showConfirmation}
            onClose={closeModal}
            onConfirm={() => confirmDeletion(itemToDelete)}
            itemModule="User"
            itemName={
              itemToDelete
                ? itemToDelete.first_name + " " + itemToDelete.last_name
                : ""
            }
          />
          {range.length > 0 && (
            <div className="pagination mb-60 mt-10">
              <button
                onClick={() => setPage(1)}
                disabled={page === 1 || range.length === 1}
              >
                {"<<"}
              </button>
              <button
                onClick={() => setPage(page - 1)}
                disabled={page === 1 || range.length === 1}
              >
                {"<"}
              </button>
              <span>
                Page{" "}
                <strong>
                  {page} of {range.length}
                </strong>{" "}
              </span>
              <button
                onClick={() => setPage(page + 1)}
                disabled={range.length === 1 || page === range.length}
              >
                {">"}
              </button>
              <button
                onClick={() => setPage(range.length)}
                disabled={page === range.length}
              >
                {">>"}
              </button>
            </div>
          )}
        </div> */}
        <CommonTable
          columns={adminUserColumns}
          rows={users}
          tableSearchEnable={true}
          searchableFields={[
            "role",
            "name",
            "email",
            "source",
            "credits",
            "status",
          ]}
          addHeaderButton={true}
          addHeaderButtonName={"Create User"}
          addHeaderButtonOnClick={handleCreateUser}
          tableSelectBox={true}
          tableSelectBoxName={"license"}
          tableSelectBoxOptions={varLicenseOptions?.map((item) => {
            return { id: item.id, name: item.label, value: item.id };
          })}
        />
      </div>
      {showConfirmation && (
        <ConfirmationModal
          isOpen={showConfirmation}
          onClose={closeModal}
          onConfirm={() => confirmDeletion(itemToDelete)}
          itemModule="User"
          itemName={
            itemToDelete
              ? itemToDelete.first_name + " " + itemToDelete.last_name
              : ""
          }
        />
      )}
      {showUserModal && (
        <UserModal
          show={showUserModal}
          user={selUser}
          onClose={onCloseUserModal}
          onHandleUpdate={() => getDashboardData(false)}
        />
      )}
      {showCreditModal && (
        <CreditHistoryModal
          show={showCreditModal}
          user={selUser}
          onClose={() => {
            setSelUser();
            setShowCreditModal(false);
          }}
        />
      )}
    </AdminLayout>
  );
}
