import { LICENSE } from "utils/constants";

const varLicenseOptions = [
    {
        key: 0,
        id: "license",
        color: "white",
        checked: true,
        label: "Select License"
    },
    {
        key: 1,
        id: "Starter",
        color: "white",
        checked: false,
        label: LICENSE.STARTER
    },
    {
        key: 2,
        id: "Business",
        color: "white",
        checked: false,
        label: LICENSE.BUSINESS
    },
    {
        key: 3,
        id: "Professional",
        color: "white",
        checked: false,
        label: LICENSE.PROFESSIONAL
    },
    {
        key: 4,
        id: "Animation",
        color: "white",
        checked: false,
        label: LICENSE.ANIMATION
    },
    {
        key: 5,
        id: "Agency",
        color: "white",
        checked: false,
        label: LICENSE.AGENCY
    },
];

export {
    varLicenseOptions
};