import { AdminLayout } from "components/layouts/AdminLayout";
import "./styles.scss";
import { useContext } from "react";
import { LayoutContext } from "components/contexts/LayoutContextContainer";
import { useEffect } from "react";
import { getApi } from "utils/customNetwork";
import { useState } from "react";
import { Slide } from "react-awesome-reveal";
// import { ReactComponent as IconRemove } from "assets/imgs/action/icon_delete.svg";
import { CInput } from "components/atoms/CInput";
// import { CButton } from "components/atoms/CButton";
import useTable from "hooks/useTable";
// import { format } from "date-fns";
import ConfirmationModal from "components/atoms/Modal/ConfirmationModal";
import getFullName from "utils/getFullName";
import { Box, Tooltip } from "@mui/material";
import { ICONS } from "assets";
import { COLORS } from "utils/theme";
import moment from "moment";
import { CommonTable } from "components/Table";
import { commonSorting } from "utils/util";

const rowsPerPage = 10;

export function AdminLicense() {
  const { setLoading, showNoti, checkToken } = useContext(LayoutContext);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [page, setPage] = useState(1);
  const { slice, range } = useTable(filteredData, page, rowsPerPage);
  // modal user area

  const getData = async (showLoading = true) => {
    if (showLoading) setLoading(true);
    try {
      checkToken();
      const data = await getApi("/admin/getLicenses", "POST", {});
      setData(data.data);
      onSearch(data.data);
    } catch (err) {
      showNoti("error", err);
    }
    if (showLoading) setLoading(false);
  };

  const onSearch = (orgData) => {
    setPage(1);
    const filtered = orgData.filter((user) => {
      const searchTerm = keyword.toLowerCase();
      const transaction_id = user.transaction_id.toLowerCase();
      const email = user.email.toLowerCase();
      const product_name = user.product_name.toLowerCase();
      const name = (
        user.user.first_name +
        " " +
        user.user.last_name
      ).toLowerCase();

      return (
        transaction_id.includes(searchTerm) ||
        name.includes(searchTerm) ||
        email.includes(searchTerm) ||
        product_name.includes(searchTerm)
      );
    });

    setFilteredData(filtered);
  };
  const confirmDeletion = async (item) => {
    setLoading(true);

    try {
      // Ensure that checkToken, getApi, showNoti, and getData are defined
      checkToken();
      await getApi("/admin/deleteLicense", "POST", { license_id: item.id });
      showNoti("success", "Delete license successfully");
      getData(false);
    } catch (err) {
      showNoti("error", err);
    }

    setLoading(false);
    setShowConfirmation(false);
    setItemToDelete(null);
  };
  const handleTblDelete = async (item) => {
    setItemToDelete(item);
    setShowConfirmation(true);
  };

  const closeModal = () => {
    setShowConfirmation(false);
    setItemToDelete(null);
  };
  useEffect(() => {
    getData();
  }, []);
  useEffect(() => {
    onSearch(data);
  }, [keyword]);

  const ActionsContainer = ({ item }) => {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
        }}
      >
        <Tooltip title="Delete">
          <ICONS.Delete
            onClick={() => handleTblDelete(item)}
            sx={{ color: COLORS.white, cursor: "pointer" }}
          />
        </Tooltip>
      </Box>
    );
  };

  const licenseColumns = [
    {
      id: "transaction_id",
      fieldName: "transaction_id",
      label: "TransactionID",
      sorting: false,
      cellStyle: {
        width: "150px",
        minWidth: "150px",
        maxWidth: "150px",
        wordWrap: "break-word",
      },
      rowStyle: {
        width: "150px",
        minWidth: "150px",
        maxWidth: "150px",
        wordWrap: "break-word",
      },
    },
    {
      id: "product_name",
      fieldName: "product_name",
      label: "Product",
      align: "center",
    },
    {
      id: "username",
      fieldName: "username",
      label: "UserName",
      render: ({ rowData }) => getFullName(rowData?.user),
      align: "center",
      customSort: (dataA, dataB, order) => {
        return commonSorting(
          getFullName(dataA?.user),
          getFullName(dataB?.user),
          order
        );
      },
    },
    {
      id: "email",
      fieldName: "email",
      label: "Email",
      align: "center",
    },
    {
      id: "credits",
      fieldName: "credits",
      label: "Credits",
      align: "center",
      customSort: (dataA, dataB, order) => {
        return commonSorting(dataA?.credits, dataB?.credits, order);
      },
    },
    {
      id: "amount",
      fieldName: "amount",
      label: "Amount",
      align: "center",
      customSort: (dataA, dataB, order) => {
        return commonSorting(dataA?.amount, dataB?.amount, order);
      },
    },
    {
      id: "purchase_date",
      fieldName: "purchase_date",
      label: "Purchase Date",
      render: ({ rowData }) =>
        moment(rowData?.purchase_date).format("DD-MM-YYYY hh:mm A"),
      align: "center",
      cellStyle: {
        minWidth: 150,
      },
      customSort: (dataA, dataB, order) => {
        return commonSorting(
          moment(dataA?.purchase_date).format("DD-MM-YYYY hh:mm A"),
          moment(dataB?.purchase_date).format("DD-MM-YYYY hh:mm A"),
          order
        );
      },
    },

    {
      id: "transaction_type",
      fieldName: "transaction_type",
      label: "Status",
      align: "center",
    },
    {
      id: "id",
      fieldName: "id",
      label: "Actions",
      align: "center",
      render: (row) => {
        return <ActionsContainer item={row.rowData} />;
      },
      cellStyle: {
        minWidth: 80,
      },
      sorting: false,
    },
  ];

  return (
    <AdminLayout
      page="dashboard"
      caption="Dashboard"
      message="Welcome back admin"
    >
      <div className="admin-license body-container">
        <div className="head">
          <Slide className="flex1">
            <h1>
              <span>Manage</span> Licenses
            </h1>
          </Slide>
        </div>
        {/* 
        <div className="btn-group mt-35">
          <div className="search-area flex1">
            <CInput
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
              addClass="full text-white"
            />
            {/* <CButton addClass="gradient" active={true} onClick={onSearch}>Search</CButton> */}
        {/* </div>
          <div className="flex1">&nbsp;</div>
        </div>  */}

        {/* <div className="custom-table mt-25 mb-60">
          <table>
            <thead>
              <tr>
                <th className="tableHeader">TransactionID</th>
                <th className="tableHeader">Product</th>
                <th className="tableHeader">Username</th>
                <th className="tableHeader">Email</th>
                <th className="tableHeader">Credits</th>
                <th className="tableHeader">Amount</th>
                <th className="tableHeader">Purchase Date</th>
                <th className="tableHeader">Status</th>
                <th className="tableHeader">Action</th>
              </tr>
            </thead>
            <tbody>
              {slice.length === 0 ? (
                <tr>
                  <td colSpan="9" className="tableCell text-center">
                    No records found
                  </td>
                </tr>
              ) : (
                slice.map((el) => (
                  <tr key={el.id}>
                    <td className="tableCell">{el.transaction_id}</td>
                    <td className="tableCell">{el.product_name}</td>
                    <td className="tableCell">
                      {getFullName(el?.user)}
                    </td>
                    <td className="tableCell">{el.email}</td>
                    <td className="tableCell">{el.credits}</td>
                    <td className="tableCell">{el.amount}</td>
                    <td>{format(new Date(el.purchase_date), "MMMM dd, yyyy")}</td>
                    <td className="tableCell">{el.transaction_type}</td>
                    <td className="tableCell text-center">
                      <IconRemove
                        data-tooltip-id="tip-delete"
                        data-tooltip-variant="light"
                        data-tooltip-content="Delete"
                        onClick={() => handleTblDelete(el)} />
                    </td>
                  </tr>
                )))}
            </tbody>
          </table>

          <Tooltip id="tip-delete" />
          <ConfirmationModal
            isOpen={showConfirmation}
            onClose={closeModal}
            onConfirm={() => confirmDeletion(itemToDelete)}
            itemModule="License"
            itemName={itemToDelete ? getFullName(itemToDelete?.user) : ''}
          />
          {range.length > 0 && (
            <div className="pagination mb-60 mt-10">
              <button
                onClick={() => setPage(1)}
                disabled={page === 1 || range.length === 1}
              >
                {"<<"}
              </button>
              <button
                onClick={() => setPage(page - 1)}
                disabled={page === 1 || range.length === 1}
              >
                {"<"}
              </button>
              <span>
                Page{" "}
                <strong>
                  {page} of {range.length}
                </strong>{" "}
              </span>
              <button
                onClick={() => setPage(page + 1)}
                disabled={range.length === 1 || page === range.length}
              >
                {">"}
              </button>
              <button
                onClick={() => setPage(range.length)}
                disabled={page === range.length}
              >
                {">>"}
              </button>
            </div>
          )}
        </div> */}
        <CommonTable
          columns={licenseColumns}
          rows={filteredData}
          tableSearchEnable={true}
          searchableFields={[
            "transaction_id",
            "product_name",
            "username",
            "email",
            "amount",
            "transaction_type",
          ]}
        />
        {showConfirmation && (
          <ConfirmationModal
            isOpen={showConfirmation}
            onClose={closeModal}
            onConfirm={() => confirmDeletion(itemToDelete)}
            itemModule="License"
            itemName={itemToDelete ? getFullName(itemToDelete?.user) : ""}
          />
        )}
      </div>
    </AdminLayout>
  );
}
