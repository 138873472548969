import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getApi } from "utils/customNetwork";
import { CButton } from "components/atoms/CButton";
import { CInput } from "components/atoms/CInput";
import { LayoutContext } from "components/contexts/LayoutContextContainer";
import { getNegativeText, getPositiveText } from "store/utils";
import { getOffsetX, getOffsetY, getScale, getSelectedItem } from "store/utils";
import useHistoryDispatch from "hooks/history/useHistoryStudio";
import { DRAW_OBJECT_TYPES } from "constants/studio";

import "react-fontpicker-ts-lite/dist/index.css";
import "./index.scss";

const SearchReplacePopup = () => {
  const dispatch = useDispatch();
  const { historyLayer } = useHistoryDispatch();
  const { showNoti, setLoading } = useContext(LayoutContext);
  const positiveText = useSelector(getPositiveText);
  const negativeText = useSelector(getNegativeText);
  const selectedItem = useSelector(getSelectedItem);
  const scale = useSelector(getScale);
  const offsetX = useSelector(getOffsetX);
  const offsetY = useSelector(getOffsetY);
  const [searchPrompt, setSearchPrompt] = useState("");

  const getImageObject = () => {
    let imageObject = null;
    if (selectedItem.type === DRAW_OBJECT_TYPES.image) {
      imageObject = selectedItem;
    } else if (selectedItem.type === DRAW_OBJECT_TYPES.group) {
      const objects = selectedItem.getObjects ? selectedItem.getObjects() : [];
      imageObject = objects.find(
        (item) => item.type === DRAW_OBJECT_TYPES.image
      );
    }

    return imageObject;
  };

  const onSearchAndReplace = async () => {
    historyLayer();

    if (!searchPrompt.trim()) {
      showNoti("error", "Please enter the search prompt");
      return;
    }
    
    if (!positiveText.trim()) {
      showNoti("error", "Please enter the prompt");
      return;
    }

    if (!selectedItem) {
      showNoti("error", "Please select a picture");
      return;
    }

    try {
      const formData = new FormData();

      const imageObject = getImageObject();
      if (!imageObject || imageObject.type !== DRAW_OBJECT_TYPES.image) {
        showNoti("error", "Please select a picture");
        return;
      }

      if (imageObject.imageType === "upload") {
        formData.append("type", "upload");
        formData.append("image", imageObject.data?.file);
      } else {
        formData.append("type", "url");
        formData.append("url", imageObject.data?.url);
      }

      formData.append("mode", "search");
      formData.append("prompt", positiveText);
      formData.append("width", imageObject.width);
      formData.append("height", imageObject.height);
      formData.append("search_prompt", searchPrompt);
      if (negativeText && negativeText != "") {
        formData.append("negative_prompt", negativeText);
      }

      setLoading(true);
      const data = await getApi("/generate/inpainting", "POST", formData);
      const img = new Image();
      img.crossOrigin = "anonymous";
      img.onload = function () {
        imageObject.setImg(img);
        imageObject.setImageType("url");
        imageObject.setData({ url: data.data?.image });

        imageObject.clearContext();
        imageObject.draw({ scale, offsetX, offsetY });
        imageObject.drawResizeRect({ scale, offsetX, offsetY });
      };
      img.src = data.data?.image;
    } catch (err) {
      showNoti("error", err);
    }
    setLoading(false);
  };

  useEffect(() => {
    // Event handler for detecting focus on <CInput>
    function handleFocus() {
      // Prevent shortcut key events from being triggered
      document.addEventListener("keydown", stopEventPropagation);
    }

    // Event handler for detecting loss of focus on <CInput>
    function handleBlur() {
      // Remove event listener to prevent propagation when not focused
      document.removeEventListener("keydown", stopEventPropagation);
    }

    // Event handler to stop event propagation
    function stopEventPropagation(event) {
      event.stopPropagation();
    }

    const inputElement = document.getElementById("search-object");
    if (inputElement) {
      inputElement.addEventListener("focus", handleFocus);
      inputElement.addEventListener("blur", handleBlur);
    }

    return () => {
      // Cleanup function to remove event listeners
      if (inputElement) {
        inputElement.removeEventListener("focus", handleFocus);
        inputElement.removeEventListener("blur", handleBlur);
      }
    };
  }, []);

  return (
    <div className="replace-object-popup">
      <div className="replace-object-popup__inner-div">
        <label>Search Prompt</label>
        <CInput
          id="search-object"
          value={searchPrompt}
          onChange={(e) => setSearchPrompt(e.target.value)}
          addClass="product"
        />
      </div>
      <CButton
        addClass="replace-object-popup__button full secondary py-10"
        onClick={onSearchAndReplace}
        size="medium"
      >
        Replace Object
      </CButton>
    </div>
  );
};

export default SearchReplacePopup;
