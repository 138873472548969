import { React, useState } from "react";
import Modal from "react-modal";
import { ReactComponent as IconClose } from "assets/imgs/icon_close.svg";
import { CInput } from "components/atoms/CInput";
import { CButton } from "components/atoms/CButton";
import "./index.scss";

export function AuthenticationModal({ isOpen, onClose, onSubmit }) {
    const [authenticationPassword, setAuthenticationPassword] = useState("");

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit(e, authenticationPassword); // Pass the event and password
    };

    return (
        <Modal
            isOpen={isOpen}
            ariaHideApp={false}
            onClick={onClose}
            className="authentication-modal"
        >
            <IconClose className="cursor-pointer ico-close" onClick={onClose} />
            <div className="authentication-area">
                <h2 className="text-center">Enter Password</h2>
                <form onSubmit={handleSubmit}>
                    <div className="authentication-card mt-15">
                        <CInput
                            type="password"
                            placeholder="Enter Password"
                            value={authenticationPassword}
                            name="authentication-password"
                            onChange={(e) => setAuthenticationPassword(e.target.value)}
                            addClass="product full"
                            required
                        />
                        <CButton
                            mainClass="mt-15"
                            addClass="gradient p-5I"
                            active={true}
                            onClick={onClose}
                        >
                            Close
                        </CButton>
                        <CButton
                            type="submit"
                            mainClass="mt-15"
                            addClass="gradient p-5I"
                            active={true}
                        >
                            Submit
                        </CButton>
                    </div>
                </form>
            </div>
        </Modal>
    );
}
