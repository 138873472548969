import CryptoJS from "crypto-js";

export function encryptValue(value, key) {
  var keyUtf8 = CryptoJS.enc.Utf8.parse(key);
  var iv = CryptoJS.lib.WordArray.random(16);

  var encrypted = CryptoJS.AES.encrypt(value, keyUtf8, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });

  var ivString = CryptoJS.enc.Base64.stringify(iv);
  var encryptedString = encrypted.toString();

  return encodeURIComponent(ivString + ":" + encryptedString);
}

export function decryptValue(combinedValue, key) {
  var keyUtf8 = CryptoJS.enc.Utf8.parse(key);

  var decodedValue = decodeURIComponent(combinedValue);
  var [ivBase64, encryptedString] = decodedValue.split(":");

  var iv = CryptoJS.enc.Base64.parse(ivBase64);
  var encrypted = CryptoJS.lib.CipherParams.create({
    ciphertext: CryptoJS.enc.Base64.parse(encryptedString),
  });

  var decrypted = CryptoJS.AES.decrypt(encrypted, keyUtf8, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });

  return decrypted.toString(CryptoJS.enc.Utf8);
}

export const encryptObjectValues = (obj) => {
  const result = {};
  const keys = Object.keys(obj);
  for (let i = 0; i < keys.length; i++) {
    let key = keys[i];
    let value = obj[key];

    if (typeof value === "string" && value.trim() !== "") {
      result[key] = encryptValue(value, process.env.REACT_APP_ENCRYPTION_KEY);
    } else if (
      typeof value === "object" &&
      value !== null &&
      !Array.isArray(value)
    ) {
      result[key] = encryptObjectValues(value);
    } else {
      result[key] = value;
    }
  }
  return result;
};

export const decryptObjectValues = (obj) => {
  const result = {};
  const keys = Object.keys(obj);

  for (let i = 0; i < keys.length; i++) {
    let key = keys[i];
    let value = obj[key];

    if (
      typeof value === "string" &&
      value.trim() !== "" &&
      isEncrypted(value)
    ) {
      result[key] = decryptValue(value, process.env.REACT_APP_ENCRYPTION_KEY);
    } else if (
      typeof value === "object" &&
      value !== null &&
      !Array.isArray(value)
    ) {
      result[key] = decryptObjectValues(value);
    } else {
      result[key] = value;
    }
  }
  return result;
};

const isEncrypted = (str) => {
  return str.length > 0;
};
