import { LayoutContext } from "components/contexts/LayoutContextContainer";
import { DashboardLayout } from "components/layouts/DashboardLayout";
import { convertHeicImage } from "pages/Edit/utils";
import UploadView from "components/atoms/UploadView";
import { useContext, useEffect, useState } from "react";
import { getApi } from "utils/customNetwork";
import { useDispatch, useSelector } from "react-redux";
import { getImage, getResult, getImageType } from "store/slices/imageToVideo/utils";
import { setImage, setResult, setImageType } from "store/slices/imageToVideo/reducer";
import ReactSlider from "react-slider";
import { CButton } from "components/atoms/CButton";
import { CToggle } from "components/atoms/CToggle";
import { CDropdown } from "components/atoms/CDropdown";
import { LICENSE } from "utils/constants";
import { ReactComponent as Info } from "assets/imgs/info.svg";
import { Tooltip } from "react-tooltip";
import { useNavigate, useSearchParams } from "react-router-dom";
import { checkPermission } from "utils/util";
import { VideoStatusModal } from "components/atoms/Modal/VideoStatusModal";
import { useLocalStorage } from "hooks";

import "./index.scss";

const ImageToVideo = () => {
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();
	const [stage, setStage] = useState(null); // edit || result
	const { setLoading, showNoti, user } = useContext(LayoutContext);
	const dispatch = useDispatch();
	const image = useSelector(getImage);
	const imageType = useSelector(getImageType);
	const result = useSelector(getResult);
	const [file, setFile] = useState(null);
	const [scale, setScale] = useState(1.8); // [ 0 .. 10 ]
	const [motion, setMotion] = useState(127); // [ 1 .. 255 ]
	const [width, setWidth] = useState(768);
	const [height, setHeight] = useState(768);
	const [video, setVideo] = useState({});
	const [useDiffImageDimention, setUseDiffImageDimention] = useState(false);
	const [showVideoStatusModal, setShowVideoStatusModal] = useState(false);
	const [isShownVideoStatusModal, setIsShownVideoStatusModal] = useLocalStorage("creativio_animation_status", true);
	const [diffImageDimentionsOptions, setDiffImageDimentionsOptions] = useState([
		{ key: 1, label: "768x768", width: 768, height: 768, checked: true },
		{ key: 2, label: "1024x576", width: 1024, height: 576, checked: false },
		{ key: 3, label: "576x1024", width: 576, height: 1024, checked: false },
	]);

	const getActiveMenu = (arr) => {
		const chk = arr.find((k) => k.checked);
		if (chk) return chk;
		else return arr[0];
	};

	const handleOptRatio = (id, index, value) => {
		switch (id) {
			case "image-dimentions":
				const c1 = [...diffImageDimentionsOptions];
				c1.forEach((el) => (el.checked = false));
				c1[index].checked = value;
				setDiffImageDimentionsOptions(c1);
				break;
			default:
		}
	}

	const handleImageUpload = async (event) => {
		const file = event.target.files[0];

		if (!file) return;
		setStage(null);
		const reader = new FileReader();

		if (file.type === "" || file.type === "image/heic") {
			const blob = await convertHeicImage(file);
			reader.readAsDataURL(blob);
		} else {
			reader.readAsDataURL(file);
		}

		reader.onload = (event) => {
			const imageElement = new Image();
			imageElement.src = event.target.result;
			imageElement.onload = function () {
				setWidth(imageElement.width);
				setHeight(imageElement.height);
			};
			setFile(file);
			dispatch(setImageType("upload"));
			dispatch(setImage(reader.result));
			setStage("edit");
		};
	};

	const handleImageToVideo = async () => {
		try {
			setLoading(true);
			const formData = new FormData();
			formData.append("type", imageType);
			if (imageType == "upload") {
				formData.append("image", file);
			}
			else {
				formData.append("url", image);
			}
			formData.append("scale", scale);
			formData.append("motion", motion);
			formData.append("height", height);
			formData.append("width", width);
			const result = await getApi(
				"/animation/generateAnimation",
				"POST",
				formData
			);
			if (isShownVideoStatusModal) {
				setVideo(result.data);
				setShowVideoStatusModal(true);
			}
			showNoti("success", "Animation generation is In Progress!");
			// dispatch(setResult(result.data));
			// setStage("result");
		} catch (e) {
			showNoti("error", e);
		}
		setLoading(false);
	};

	const closeVideoStatusModal = (show) => {
		setVideo({});
		setShowVideoStatusModal(false);
		if (show) {
			setIsShownVideoStatusModal(false);
		}
	}

	const getVideoStatus = async (video_id) => {
		console.log(video_id);
		try {
			setLoading(true);
			const data = await getApi("/animation/getAnimationStatus", "POST", { 
				video_id: video_id,
				user_id : user.user.id
			});
			showNoti("success", data.status);
		} catch (e) {
			showNoti("error", e);
		}
		setLoading(false);
	}

	const handleImageResize = async () => {
		try {
			setLoading(true);
			const formData = new FormData();
			formData.append("type", imageType);
			if (imageType == "upload") {
				formData.append("image", file);
			}
			else {
				formData.append("url", image);
			}
			formData.append("height", height);
			formData.append("width", width);
			formData.append("newHeight", Number(getActiveMenu(diffImageDimentionsOptions).height));
			formData.append("newWidth", Number(getActiveMenu(diffImageDimentionsOptions).width));
			const result = await getApi(
				"/animation/resizeImage",
				"POST",
				formData
			);
			dispatch(setImageType("url"));
			dispatch(setImage(result.data.url));
			setWidth(result.data.width);
			setHeight(result.data.height);
			setFile(null);
			showNoti("success", "Image Resized Successfully!");
			// dispatch(setResult(result.data));
			// setStage("result");
		} catch (e) {
			showNoti("error", e);
		}
		setLoading(false);
	};

	useEffect(() => {
		setStage(null);
		dispatch(setImageType("upload"));
		dispatch(setImage(null));
		dispatch(setResult(null));
		setHeight(768);
		setWidth(768);
	}, []);

	const checkParam = () => {
		const paramValue = searchParams.get("animation-image");
		if (paramValue) {
			const decode_x = atob(paramValue);
			const parameters = JSON.parse(decode_x);
			dispatch(setImageType("url"));
			dispatch(setImage(parameters.image));
			setWidth(parameters.width);
			setHeight(parameters.height);
			setStage("edit");
		}
	};

	useEffect(() => {
		if (!checkPermission(user)) {
			showNoti("error", "Permission denied. Please contact the administrator.");
			setTimeout(() => {
				navigate("/");
			}, 2000);
		} else {
			checkParam();
		}
	}, []);

	const renderEdit = () => {
		return (
			image && (
				<section className="edit-section">
					<div className="edit-item">
						{image && (
							<img src={image} alt="initial" className="image-container" />
						)}
					</div>
					<div className="edit-options">
						<div className="option-container">
							<h3>Generate Image to Animation</h3>
							<div className="slider-wrap">
								<div className="slider-caption">
									<p className="mb-5">Scale</p>
									<p>{scale}</p>
								</div>
								<ReactSlider
									className="slider"
									thumbClassName="slider-thumb"
									trackClassName="slider-track"
									onChange={(count) => setScale(count)}
									value={scale}
									step={0.1}
									min={0}
									max={10}
								/>
							</div>
							<div className="slider-wrap">
								<div className="slider-caption">
									<p className="mb-5">Motion</p>
									<p>{motion}</p>
								</div>
								<ReactSlider
									className="slider"
									thumbClassName="slider-thumb"
									trackClassName="slider-track"
									onChange={(count) => setMotion(count)}
									value={motion}
									min={1}
									max={255}
								/>
							</div>
							<div className="diff-dimentions-toggle cursor-pointer mt-10 flex" onClick={() => setUseDiffImageDimention(!useDiffImageDimention)}>
								<p>Use Different Image Dimentions?&nbsp;</p>
								<CToggle isOn={useDiffImageDimention} onClick={() => setUseDiffImageDimention(!useDiffImageDimention)} />
							</div>
							{useDiffImageDimention &&
								<div className="change-dimentions">
									<CDropdown
										id="image-dimentions"
										addClass="product full mt-10"
										action={handleOptRatio}
										activeMenu={getActiveMenu(diffImageDimentionsOptions)}
										menu={diffImageDimentionsOptions}
									/>
									<CButton
										addClass="gradient2 active full p-10I mt-10"
										mainClass="w-full"
										active={true}
										onClick={() => handleImageResize()}
									>
										Resize Image
									</CButton>
								</div>
							}
							<div className="row mt-15 mb-15">
								<CButton
									addClass="gradient2 active full p-10I"
									mainClass="w-full"
									active={true}
									onClick={() => handleImageToVideo()}
								>
									Generate Animation
								</CButton>
							</div>
						</div>
					</div>
				</section>
			)
		);
	};

	return (
		<DashboardLayout
			page="image-to-animation"
			caption="Product Studio"
			license={[[LICENSE.ANIMATION, LICENSE.BUSINESS], [LICENSE.ANIMATION, LICENSE.STARTER]]}
			message="Enhance Your product Photography with AI"
		>
			<main id="enhance" className="body-container image-to-animation">
				<UploadView
					content={{
						mainTitle: (
							<>
								<span>Image To Animation</span> Generation Tool&nbsp;
								<Info
									className="toolinfo"
									data-tooltip-id="tip-info"
									data-tooltip-variant="dark"
									data-tooltip-content="You can upload an image with dimensions of 768x768, 1024x576, or 576x1024, and the tool will generate an animation based on it."
								/>
								<Tooltip className="toolinfo1" id="tip-info" />
							</>
						),
						title:
							"Upload an image with 768x768, 1024x576 or 576x1024 dimensions to convert it into the animation.",
						subtitle: "upload an image",
					}}
					onInput={handleImageUpload}
				/>
				{stage === "edit" && renderEdit()}
				{stage === "result" && result?.video && image && (
					<div>
						<section className="video-section">
							<div className="item">
								<p className="description">Image</p>
								{image && (
									<img src={image} alt="initial" className="container" />
								)}
							</div>
							<div className="item">
								<p className="description">Animation</p>
								{result?.video && (
									<div className="video-wrap">
										<video className="container" controls>
											<source src={result.video} type="video/mp4" />
											Your browser does not support the video tag.
										</video>
									</div>
								)}
							</div>
						</section>
						<div className="row mt-5 regenerate-button">
							<CButton
								addClass="gradient2 active full p-10I"
								mainClass=""
								active={true}
								onClick={() => handleImageToVideo()}
							>
								ReGenerate Animation
							</CButton>
						</div>
					</div>
				)}
				{showVideoStatusModal && (
					<VideoStatusModal
						show={showVideoStatusModal}
						video={video}
						onClose={closeVideoStatusModal}
						onShowVideoStatus={getVideoStatus}
					/>
				)}
			</main>
		</DashboardLayout>
	);
};

export default ImageToVideo;
