import React, { useState, useEffect } from 'react';
import { AdminLayout } from 'components/layouts/AdminLayout';
import {
  BarChart,
  Bar,
  PieChart,
  Pie,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts';

import axios from 'axios'; // Import Axios library

import './styles.scss';

export function AdminStatistics() {
  const [userStats, setUserStats] = useState({
    total: 0,
    newUsersOverTime: [],
    activeVsInactive: [],
    licenseWiseUsers: []
  });
  
  useEffect(() => {
    fetchUserStatistics();
  }, []);
  const fetchUserStatistics = () => {
    axios.get('https://stage.creativio.ai/api/totalStatisticsusersData')
      .then(response => {
        const data = response.data.active_inactive_user;
        setUserStats({
          total: response.data.total_users,
          newUsersOverTime: response.data.monthly_user_count,
          activeVsInactive: response.data.active_inactive_user,
          licenseWiseUsers: response.data.license_wise_user_count
        });
      })
      .catch(error => {
        console.error('Error fetching user statistics:', error);
        // Handle error state, e.g., set default or empty state
        setUserStats({
          total: 0,
          newUsersOverTime: [],
          activeVsInactive: [],
          licenseWiseUsers: []
        });
      });
  };
  return (
    <AdminLayout
      page="dashboard"
      caption="Statistics"
      message="Welcome back admin"
    >
      <div className="admin-statistics body-container">
        <h2>Statistics</h2>
        <div className="charts-container">
          {/* Total Users */}
          <div className="chart-wrapper">
            <h3>Total Users: {userStats.total} (Monthly created users)</h3>
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={userStats.newUsersOverTime}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="month" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="newUsers" fill="#8884d8" />
              </BarChart>
            </ResponsiveContainer>
          </div>
          
          {/* Active vs Inactive Users */}
          <div className="chart-wrapper">
            <h3>Active vs Inactive Users</h3>
            <ResponsiveContainer width="100%" height={300}>
              <PieChart>
                <Pie
                  data={userStats.activeVsInactive}
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                  outerRadius={80}
                  fill="#8884d8"
                  dataKey="value"
                >
                  {userStats.activeVsInactive.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={entry.color} />
                  ))}
                </Pie>
                <Tooltip />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          </div>

          {/* License wise Users */}
          <div className="chart-wrapper">
            <h3>Total Users: {userStats.total} (License wise created users)</h3>
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={userStats.licenseWiseUsers}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="license" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="userCount" fill="#8884d8" />
              </BarChart>
            </ResponsiveContainer>
          </div>
          
        </div>
      </div>
    </AdminLayout>
  );
}
