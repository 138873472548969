import { DashboardLayout } from "components/layouts/DashboardLayout";
import { useContext, useEffect, useRef, useState } from "react";
import { Slide } from "react-awesome-reveal";
import "./styles.scss";
import Upload from "assets/imgs/upload.svg";
import Category1 from "assets/imgs/enhance/category1.png";
import Category2 from "assets/imgs/enhance/category2.png";
import Category3 from "assets/imgs/enhance/category3.png";
import Category4 from "assets/imgs/enhance/category4.png";
import { ReactComponent as Checked } from "assets/imgs/auth/checked.svg";
import { ReactComponent as UnChecked } from "assets/imgs/auth/unchecked.svg";
import { ReactComponent as ExitSvg } from "assets/imgs/studioCreator/exit.svg";

import CImage from "components/atoms/CImage";
import { CButton } from "components/atoms/CButton";
import { ReactComponent as Magic } from "assets/imgs/magic.svg";
import { CInput } from "components/atoms/CInput";
import { CTextarea } from "components/atoms/CTextarea";
import { CDropdown } from "components/atoms/CDropdown";
import { CToggle } from "components/atoms/CToggle";
import { getApi } from "utils/customNetwork";
import { LayoutContext } from "components/contexts/LayoutContextContainer";
import { ImageEditor, mainCanvasH } from "components/atoms/ImageEditor";
import {
  varBackground,
  varBackgroundSub,
  varBaseTexture,
  varCameraAngles,
  varColors,
  varComposition,
  varCustomStyle,
  varLightning,
  varMood,
  varPlacement,
  varPrompt,
  varRender,
  varStyle,
  varImageDimensions,
  varSurrounding,
  varVariation,
} from "./variable";
import BrushCanvas from "components/atoms/ImageEditor/BrushCanvas";
import CreatableSelect from "react-select/creatable";
import { useNavigate, useSearchParams } from "react-router-dom";
import { checkPermission, getLicense, isPossible } from "utils/util";
import { STAGES, colourStyles, convertHeicImage } from "./utils";
import { useMemo } from "react";
import { LICENSE } from "utils/constants";
import { ENGINE_TYPE } from "pages/Setting/constants";

const CATEGORIES = [
  { url: Category1 },
  { url: Category2 },
  { url: Category3 },
  { url: Category4 },
];

export function PhotoRoom() {
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  const brushCanvasRef = useRef(null);
  const [searchParams] = useSearchParams();
  const { showNoti, setLoading, user, checkToken } = useContext(LayoutContext);
  const [stage, setStage] = useState("upload"); // upload ||  edit || result
  const [newImage, setNewImage] = useState(null); // new image - file
  const [newResizeImage, setNewResizeImage] = useState(null); // new image - file
  const [newImgUrl, setNewImgUrl] = useState(null);
  const [maskImage, setMaskImage] = useState(null);
  const [imageWidth, setImageWidth] = useState(1024);
  const [imageHeight, setImageHeight] = useState(1024);
  const [orgImageWidth, setOrgImageWidth] = useState(1024);
  const [orgImageHeight, setOrgImageHeight] = useState(1024);
  const [magicText, setMagicText] = useState(""); // magic text in action area
  const [useBrand, setUseBrand] = useState(false);
  const [useBrandColor, setUseBrandColor] = useState(false);
  const [brands, setBrands] = useState([]);
  const [brandOptions, setBrandOptions] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [selectedImage, setSelectedImage] = useState("");
  const [selectedColor, setSelectedColor] = useState("");

  const [resultImg, setResultImg] = useState(null); // result image from brush image editor
  const [activeResultImg, setActiveResultImg] = useState(null); // old image from brush image editor
  const [resultImgs, setResultImgs] = useState([]);

  const [resultMagicText, setResultMagicText] = useState(""); // magic text in action area
  const [content, setContent] = useState(
    "a bottle on top of reflective surface smooth wooden with golden bokeh white wall pink sunset and ocean in the background "
  ); // product content
  // start tab magic
  const [activeTab, setActiveTab] = useState("magic"); // template | magic
  const [useBuilderText, setUseBuilderText] = useState(false); //
  const [product, setProduct] = useState("A bottle"); // magic editor product
  const [placementSub, setPlacementSub] = useState("reflective surface");
  const [placementOptions, setPlacementOptions] = useState(varPlacement);
  const [baseTextureSub, setBaseTextureSub] = useState("Wooden");
  const [baseTextureOptions, setBaseTextureOptions] = useState(varBaseTexture);
  const [surroundingSub, setSurroundingSub] = useState("golden bokeh");
  const [surroundingOptions, setSurroundingOptions] = useState(varSurrounding);
  // const [backgroundSub, setBackgroundSub] = useState("white wall");
  const [backgroundSubOptions, setBackgroundSubOptions] =
    useState(varBackgroundSub);
  const [backgroundOptions, setBackgroundOptions] = useState(varBackground);
  const [negativeText, setNegativeText] = useState(""); // negative text
  const [useNegativeText, setUseNegativeText] = useState(false);
  const [layers, setLayers] = useState([]);
  const [backupLayers, setBackupLayers] = useState([]);
  const [redoStep, setRedoStep] = useState(0);

  const [advanced, setAdvanced] = useState(false); // show advanced option
  const [lightningOptions, setLightningOptions] = useState(varLightning);
  const [lightningSub, setLightningSub] = useState("Warm light");
  const [colorsOptions, setColorsOptions] = useState(varColors);
  const [compositionOptions, setCompositionOptions] = useState(varComposition);
  const [cameraAngleOptions, setCameraAngleOptions] = useState(varCameraAngles);
  const [moodOptions, setMoodOptions] = useState(varMood);
  const [customStyleOptions, setCustomStyleOptions] = useState(varCustomStyle);
  // advanced option end
  const varVariation_ =
    user?.user?.platform === ENGINE_TYPE.creativio
      ? varVariation?.filter((item) => item?.id !== 4)
      : varVariation;
  const [variationOptions, setVariationOptions] = useState(varVariation_);
  const [renderOptions, setRenderOptions] = useState(varRender);
  const [promptOptions, setPromptOptions] = useState(varPrompt);
  const [styleOptions, setStyleOptions] = useState(varStyle);
  const [imageDimensionsOptions, setImageDimensionsOptions] =
    useState(varImageDimensions);
  const [useAspectRatio, setUseAspectRatio] = useState(false);
  const [useUpSize, setUseUpSize] = useState(false);
  const [useRemoveBg, setUseRemoveBg] = useState(true);

  // end tab magic
  // start tab template
  const [categoryOptions, setCategoryOptions] = useState([
    { key: 1, id: "Moundatin View", checked: true, label: "Moundatin View" },
    { key: 2, id: "Valley View", checked: false, label: "Valley View" },
  ]);

  // end tab template
  const [reGenerateBtnTitle, setReGenerateBtnTitle] =
    useState("Re-Generate Image");

  const handleBack = () => {
    setStage(STAGES.edit);
    setLayers([]);
    setBackupLayers([]);
    setRedoStep(0);
    setImageWidth(orgImageWidth);
    setImageHeight(orgImageHeight);
    // setImageWidth(mainCanvasH);
    // setImageHeight(mainCanvasH);
  };

  const onExit = () => {
    setStage(STAGES.upload);
    setNewImage(null);
    setNewResizeImage(null);
    setNewImgUrl(null);
    setMaskImage(null);
    setImageWidth(1024);
    setImageHeight(1024);
    setOrgImageWidth(1024);
    setOrgImageHeight(1024);
    setMagicText("");
    setAdvanced(false);
    setUseBuilderText(false);
    setUseNegativeText(false);
    setNegativeText("");
    setUseBrand(false);
  };

  const updateResizeImage = (imageData) => {
    setNewResizeImage(imageData);
  };

  const onImageUpload = () => {
    fileInputRef.current.click();
  };

  // const handleBackgroundSub = (selectedOption) => {
  //   setBackgroundSub(selectedOption.value);
  // };

  const parseReader = (reader) => {
    reader.onload = (event) => {
      const imageElement = new Image();
      imageElement.src = event.target.result;
      imageElement.onload = function () {
        const { newHeight, newWidth } = adjustSizes({
          height: imageElement.height,
          width: imageElement.width,
        });

        setNewImgUrl(reader.result);
        setImageWidth(newWidth);
        setImageHeight(newHeight);
        setOrgImageWidth(imageElement.width);
        setOrgImageHeight(imageElement.height);
      };
    };
  };

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];

    if (!file) return;

    const reader = new FileReader();

    if (file.type === "" || file.type === "image/heic") {
      const blob = await convertHeicImage(file);
      setNewImage(blob);
      parseReader(reader);
      reader.readAsDataURL(blob);
      return;
    }

    setNewImage(file);
    parseReader(reader);

    reader.readAsDataURL(file);
  };

  // useEffect(() => {
  //   if (newImage != null) {
  //     onEditImage();
  //   }
  // }, [newImage]);

  const adjustSizes = ({ height, width }) => {
    let newWidth = width;
    let newHeight = height;
    const aspectRatio = width / height;

    if (height > mainCanvasH) {
      newHeight = mainCanvasH;
      newWidth = mainCanvasH * aspectRatio;
    }

    return { newHeight, newWidth };
  };

  const onEditImage = async () => {
    if (useRemoveBg) {
      const formData = new FormData();
      formData.append("product", newImage);
      formData.append("type", "upload");
      formData.append("width", imageWidth);
      formData.append("height", imageHeight);
      try {
        setLoading(true);
        checkToken();
        const data = await getApi("/removeBackgroundImg", "POST", formData);
        const img = data.url;
        setStage("edit");
        setNewImgUrl(img);
        setMaskImage(data.mask);

        const { newHeight, newWidth } = adjustSizes({
          height: data.height,
          width: data.width,
        });

        setImageWidth(newWidth);
        setImageHeight(newHeight);
        setOrgImageWidth(data.width);
        setOrgImageHeight(data.height);
      } catch (err) {
        // setNewImage(null);
        showNoti("error", err);
      }
      setLoading(false);
    }
    else {
      const formData = new FormData();
      formData.append("image", newImage);
      formData.append("width", imageWidth);
      formData.append("height", imageHeight);
      try {
        setLoading(true);
        checkToken();
        const data = await getApi("/storeAsset", "POST", formData);
        setStage("edit");
      } catch (err) {
        // setNewImage(null);
        showNoti("error", err);
      }
      setLoading(false);
    }
  };

  const onMagicPrompt = async () => {
    if (magicText === "") {
      showNoti("info", "Please input some magic text");
      return;
    }
    try {
      setLoading(true);
      checkToken();
      const data = await getApi("/generateText", "POST", {
        message: magicText,
      });
      setMagicText(data.message);
    } catch (err) {
      showNoti("error", err);
    }
    setLoading(false);
  };

  const selResultImg = (index) => {
    setActiveResultImg(resultImgs[index]);
    setResultImg(resultImgs[index]);
    setMaskImage(resultImgs[index].mask);
    setImageWidth(resultImgs[index].width);
    setImageHeight(resultImgs[index].height);
    // setOrgImageWidth(resultImgs[index].width);
    // setOrgImageHeight(resultImgs[index].height);
  };

  const onChangeCanvas = (data) => {
    if (data === 0) {
      setReGenerateBtnTitle("Generate Image");
    } else {
      setReGenerateBtnTitle("Re-Generate Image");
    }
  };

  const onReGenerateImage = async () => {
    if (!brushCanvasRef.current) return resultImg?.image;
    let result = resultImg?.image;
    var height = Number(getActiveMenu(imageDimensionsOptions).height);
    var width = Number(getActiveMenu(imageDimensionsOptions).width);
    const base64image = brushCanvasRef.current.getImage(width, height);
    let prompt = resultMagicText;
    if (prompt === "") {
      showNoti("info", "Please add magic text");
      return result;
    }
    const image_count = Number(getActiveMenu(variationOptions).id) || 4;

    if (brushCanvasRef.current.isEdited() === 0) {
      onGenerateImage(true);
      return result;
    }
    const formData = new FormData();

    formData.append("positive_text", prompt);
    if (advanced && useNegativeText && negativeText !== "")
      formData.append("negative_text", negativeText);
      formData.append("width", Number(getActiveMenu(imageDimensionsOptions).width));
      formData.append("height", Number(getActiveMenu(imageDimensionsOptions).height));
      formData.append("product", activeResultImg?.image);
      formData.append("mask_image", base64image);
      formData.append("type", "upload");
      formData.append("image_count", image_count);
      formData.append("scale", Number(getActiveMenu(promptOptions).id));
      formData.append("steps", Number(getActiveMenu(renderOptions).id));
      formData.append("style", getActiveMenu(styleOptions).id);

    try {
      setLoading(true);
      checkToken();
      const data = await getApi("/changeBackgroundImage", "POST", formData);
      const imgs = data.data;
      const tmpResImgs = [...resultImgs, ...imgs];
      setResultImgs(tmpResImgs);
      setResultImg(imgs[0]);
      // setOrgImageWidth(imgs[0].width);
      // setOrgImageHeight(imgs[0].height);
      setResultMagicText(prompt);
      result = imgs[0]?.image;
      // brushCanvasRef.current.clearRect();
    } catch (err) {
      showNoti("error", err);
    }
    setLoading(false);
    return result;
  };

  const onGenerateImage = async (regenerate = false) => {
    let prompt = magicText;
    const image_count = Number(getActiveMenu(variationOptions).id) || 4;

    if (regenerate) {
      prompt = resultMagicText;
    } else {
      if (useBuilderText) {
        if (content === "") {
          showNoti("info", "Please add content detail");
          return;
        }
        if (magicText === "") {
          showNoti("info", "Please add magic text");
          return;
        }
        // prompt = content;
      } else {
        if (magicText === "") {
          showNoti("info", "Please add magic text");
          return;
        }
      }
    }
    if (advanced && useBrand && selectedImage == "") {
      showNoti("info", "Please select a logo");
      return;
    }
    const formData = new FormData();

    formData.append("positive_text", prompt);

    if (negativeText !== "") formData.append("negative_text", negativeText);

    formData.append("desiredWidth", Number(getActiveMenu(imageDimensionsOptions).width));
    formData.append("desiredHeight", Number(getActiveMenu(imageDimensionsOptions).height));
    formData.append("width", imageWidth);
    formData.append("height", imageHeight);
    formData.append("aspectRatio", useAspectRatio);
    formData.append("upSize", useUpSize);
    formData.append("product", newResizeImage);
    formData.append("type", "upload");
    formData.append("image_count", image_count);
    formData.append("scale", Number(getActiveMenu(promptOptions).id));
    formData.append("steps", Number(getActiveMenu(renderOptions).id));
    formData.append("style", getActiveMenu(styleOptions).id);
    formData.append("use_brand", advanced && useBrand ? 1 : 0);

    if (advanced && useBrand && brandOptions.length > 0) {
      formData.append("brand_id", Number(getActiveMenu(brandOptions).id));
    }

    if (advanced && useBrand && selectedImage != "") {
      formData.append("brand_logo", selectedImage);
    }

    try {
      setLoading(true);
      checkToken();
      const data = await getApi("/replaceBackgroundImage", "POST", formData);
      const imgs = data.data;

      setStage(STAGES.result);

      if (regenerate) {
        const tmpResImgs = [...resultImgs, ...imgs];
        setResultImgs(tmpResImgs);
        setResultMagicText(prompt);
      } else {
        setResultImgs(imgs);
        setActiveResultImg(imgs[0]);
        setResultImg(imgs[0]);
        setResultMagicText(prompt);
        setImageWidth(imgs[0].width);
        setImageHeight(imgs[0].height);
        // setOrgImageWidth(imgs[0].width);
        // setOrgImageHeight(imgs[0].height);
      }
    } catch (err) {
      showNoti("error", err);
    }
    setLoading(false);
  };

  const handleOptRatio = (id, index, value) => {
    switch (id) {
      case "placement":
        const c1 = [...placementOptions];
        c1.forEach((el) => (el.checked = false));
        c1[index].checked = value;
        setPlacementOptions(c1);
        break;
      case "base_texture":
        const c1_1 = [...baseTextureOptions];
        c1_1.forEach((el) => (el.checked = false));
        c1_1[index].checked = value;
        setBaseTextureOptions(c1_1);
        break;
      case "surrounding":
        const c2 = [...surroundingOptions];
        c2.forEach((el) => (el.checked = false));
        c2[index].checked = value;
        setSurroundingOptions(c2);
        break;
      case "background":
        const c3 = [...backgroundOptions];
        c3.forEach((el) => (el.checked = false));
        c3[index].checked = value;
        setBackgroundOptions(c3);
        break;
      case "variation":
        const c4 = [...varVariation_];
        c4.forEach((el) => (el.checked = false));
        c4[index].checked = value;
        setVariationOptions(c4);
        break;
      case "render":
        const c5 = [...renderOptions];
        c5.forEach((el) => (el.checked = false));
        c5[index].checked = value;
        setRenderOptions(c5);
        break;
      case "colors":
        const c6 = [...colorsOptions];
        c6.forEach((el) => (el.checked = false));
        c6[index].checked = value;
        setColorsOptions(c6);
        break;
      case "prompt":
        const c7 = [...promptOptions];
        c7.forEach((el) => (el.checked = false));
        c7[index].checked = value;
        setPromptOptions(c7);
        break;
      case "category":
        const c8 = [...categoryOptions];
        c8.forEach((el) => (el.checked = false));
        c8[index].checked = value;
        setCategoryOptions(c8);
        break;
      case "style":
        const c9 = [...styleOptions];
        c9.forEach((el) => (el.checked = false));
        c9[index].checked = value;
        setStyleOptions(c9);
        break;
      case "lightning":
        const c10 = [...lightningOptions];
        c10.forEach((el) => (el.checked = false));
        c10[index].checked = value;
        setLightningOptions(c10);
        break;
      case "composition":
        const c11 = [...compositionOptions];
        c11.forEach((el) => (el.checked = false));
        c11[index].checked = value;
        setCompositionOptions(c11);
        break;
      case "camera_angle":
        const c12 = [...cameraAngleOptions];
        c12.forEach((el) => (el.checked = false));
        c12[index].checked = value;
        setCameraAngleOptions(c12);
        break;
      case "custom_style":
        const c13 = [...customStyleOptions];
        c13.forEach((el) => (el.checked = false));
        c13[index].checked = value;
        setCustomStyleOptions(c13);
        break;
      case "mood":
        const c14 = [...moodOptions];
        c14.forEach((el) => (el.checked = false));
        c14[index].checked = value;
        setMoodOptions(c14);
        break;
      case "brand-option":
        const c15 = [...brandOptions];
        c15.forEach((el) => (el.checked = false));
        c15[index].checked = value;
        setBrandOptions(c15);
        setSelectedBrand(getActiveMenu(brandOptions).id);
        break;
      case "background-sub":
        const c16 = [...backgroundSubOptions];
        c16.forEach((el) => (el.checked = false));
        c16[index].checked = value;
        setBackgroundSubOptions(c16);
        break;
      case "image_dimension":
        const c17 = [...imageDimensionsOptions];
        c17.forEach((el) => (el.checked = false));
        c17[index].checked = value;
        setImageDimensionsOptions(c17);
        break;
      default:
        const c100 = [...placementOptions];
        c100.forEach((el) => (el.checked = false));
        c100[index].checked = value;
        setPlacementOptions(c100);
        break;
    }
    generateProductContent();
  };

  const shuffle = (array) => {
    let currentIndex = array.length,
      randomIndex;

    // While there remain elements to shuffle.
    while (currentIndex !== 0) {
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];
    }

    return array;
  };

  const onKeyUpEditor = () => {
    generateProductContent();
  };

  const generateProductContent = () => {
    let str = "";
    let str1 = [];
    // str += product + " ";
    if (placementSub !== "") {
      str += getActiveMenu(placementOptions).id + " " + placementSub + " with ";
    }
    if (baseTextureSub !== "") {
      str +=
        getActiveMenu(baseTextureOptions).id.toLowerCase() +
        " " +
        baseTextureSub +
        " texture ";
    }
    if (surroundingSub !== "") {
      str +=
        getActiveMenu(surroundingOptions).id.toLowerCase() +
        " " +
        surroundingSub +
        " ";
    }
    if (getActiveMenu(backgroundSubOptions).value !== "") {
      str +=
        getActiveMenu(backgroundOptions).id.toLowerCase() +
        " " +
        getActiveMenu(backgroundSubOptions).value.toLowerCase();
    }
    if (str.length > 0) {
      str += ".";
    }
    if (
      advanced &&
      lightningSub !== "" &&
      getActiveMenu(lightningOptions).id !== ""
    ) {
      str1.push(getActiveMenu(lightningOptions).id + ", " + lightningSub);
    }
    if (advanced && getActiveMenu(colorsOptions).id !== "") {
      str1.push(getActiveMenu(colorsOptions).id);
    }
    if (advanced && getActiveMenu(compositionOptions).id !== "") {
      str1.push(getActiveMenu(compositionOptions).id);
    }
    if (advanced && getActiveMenu(cameraAngleOptions).id !== "") {
      str1.push(getActiveMenu(cameraAngleOptions).id);
    }
    if (advanced && getActiveMenu(moodOptions).id !== "") {
      str1.push(getActiveMenu(moodOptions).id);
    }
    if (advanced && getActiveMenu(customStyleOptions).id !== "") {
      str1.push(getActiveMenu(customStyleOptions).id);
    }
    if (advanced && useBrand && useBrandColor && selectedColor !== "") {
      str1.push(selectedColor);
    }
    let advancedOptions = str1.length > 0 ? str1.join(", ") + "." : "";
    if (str.length > 0 && advancedOptions.length > 0) {
      str += " ";
    }
    str += advancedOptions;
    str = str.charAt(0).toUpperCase() + str.slice(1);
    setContent(str);
  };

  const getActiveMenu = (arr) => {
    const chk = arr.find((k) => k.checked);
    if (chk) return chk;
    else return arr[0];
  };

  const changeUseBrand = (val) => {
    setUseBrand(val);
    if (val == true && brandOptions.length > 0) {
      setSelectedBrand(getActiveMenu(brandOptions).id);
    } else {
      setSelectedBrand(null);
    }
  };

  const changeUseBrandColor = (val) => {
    setUseBrandColor(val);
    if (val == false) {
      setSelectedColor("");
    }
  };

  const preworkBrand = (arr) => {
    const res = [];
    for (let i = 0; i < arr.length; i++) {
      const brandName = arr[i].brand_name;
      const position = arr[i].position;
      const isActive = Number(arr[i].is_active) === 1 ? true : false;
      const brandColors = arr[i].colors ? arr[i].colors.split(",") : [];
      const brandImages = arr[i].images ? arr[i].images.split(",") : [];
      res.push({
        id: arr[i].id,
        brandName,
        brandColors,
        brandImages,
        isActive,
        position,
      });
    }
    if (showBrandFilters) {
      setBrandOptions(
        res.map((el, index) => ({
          key: index,
          id: el["id"],
          label: el["brandName"],
          checked: el["isActive"],
        }))
      );
    }
    return res;
  };

  const getBrands = async () => {
    try {
      checkToken();
      const data = await getApi("/getBrands", "POST", {user_id : user.user.id});
      setBrands(preworkBrand(data.data));
    } catch (err) {
      console.log("error", err);
    }
  };

  const checkParam = () => {
    const paramValue = searchParams.get("edit-image");
    if (paramValue) {
      const decode_x = atob(paramValue);
      const parameters = JSON.parse(decode_x);
      setStage("edit");
      setNewImgUrl(parameters.image);
      setMaskImage(parameters.mask);
      setImageWidth(parameters.width);
      setImageHeight(parameters.height);
      setOrgImageWidth(parameters.width);
      setOrgImageHeight(parameters.height);
    }
  };

  useEffect(() => {
    if (!checkPermission(user)) {
      showNoti("error", "Permission denied. Please contact the administrator.");
      setTimeout(() => {
        navigate("/");
      }, 2000);
    } else {
      checkParam();
      getBrands();
    }
  }, []);

  const showBrandFilters = useMemo(() => {
    return isPossible(getLicense(user?.user), [[LICENSE.PROFESSIONAL]]);
  }, [user?.user]);

  useEffect(() => {
    generateProductContent();
  }, [selectedColor, advanced]);

  useEffect(() => {
    if (useBuilderText) {
      setMagicText(content);
    } else {
      setMagicText("");
    }
  }, [content, useBuilderText]);

  useEffect(() => {
    setSelectedImage("");
    setSelectedColor("");
    const selectedBrandData = brands.find(
      (brand) => brand.id === selectedBrand
    );
    if (
      selectedBrandData &&
      selectedBrandData.brandImages &&
      selectedBrandData.brandImages.length > 0
    ) {
      setSelectedImage(selectedBrandData.brandImages[0]);
    }
    // if (selectedBrandData && selectedBrandData.brandColors && selectedBrandData.brandColors.length > 0) {
    //   setSelectedColor(selectedBrandData.brandColors[0]);
    // }
  }, [selectedBrand]);

  const renderBrandInfo = () => {
    const selectedBrandData = brands.find(
      (brand) => brand.id === selectedBrand
    );
    if (selectedBrandData) {
      return (
        <div className="brand-info">
          <p>Brand Logos</p>
          <div className="logo-body mt-10">
            {selectedBrandData.brandImages &&
              selectedBrandData.brandImages.length > 0 ? (
              selectedBrandData.brandImages.map((k, index) => (
                <div
                  className={`logo-container ${selectedImage === k ? "active" : ""
                    }`}
                  key={index}
                >
                  <CImage src={k} onClick={() => setSelectedImage(k)} />
                </div>
              ))
            ) : (
              <span className="text-info">No brand logos available.</span>
            )}
          </div>

          <div
            className="cursor-pointer mt-10 flex"
            onClick={() => changeUseBrandColor(!useBrandColor)}
          >
            <p>Use Brand Color Pallete?&nbsp;</p>
            <CToggle
              isOn={useBrandColor}
              onClick={() => changeUseBrandColor(!useBrandColor)}
            />
          </div>
          {useBrandColor && showBrandFilters && (
            <div className="color-body mt-10">
              {selectedBrandData.brandColors &&
                selectedBrandData.brandColors.length > 0 ? (
                selectedBrandData.brandColors.map((color, index) => (
                  <div
                    className={`color-card ${selectedColor === color ? "active" : ""
                      }`}
                    key={index}
                    onClick={() => setSelectedColor(color)}
                  >
                    <div
                      className="color-item"
                      style={{ backgroundColor: color }}
                    ></div>
                  </div>
                ))
              ) : (
                <span className="text-info">No brand colors available.</span>
              )}
            </div>
          )}
        </div>
      );
    } else {
      return <p>Select a brand kit to see its information.</p>;
    }
  };

  const renderUpload = () => {
    return (
      <div className="section-upload">
        <div className="head">
          <Slide className="flex1">
            <h1>
              <span>Product</span> Photoshoot
            </h1>
          </Slide>
        </div>
        <div className="image-upload-area mt-10">
          <input
            type="file"
            // accept="image/*"
            accept=".png, .webp, .jpg, .jpeg"
            onInput={handleImageUpload}
            onChange={(e) => (e.target.value = null)}
            style={{ display: "none" }}
            ref={fileInputRef}
          />
          <div className="custom-upload" onClick={onImageUpload}>
            <p className="lbl-bold">
              Use a Brush to remove objects or paint in new ones
            </p>
            <img src={Upload} alt="upload img" />
            <p>To Get Started, Select a Sample asset or upload an image</p>
          </div>
        </div>
        <div className="mt-30 mb-30">
          {newImgUrl && (
            <div className="product-area">
              <div className="border-gradient border-10">
                <img src={newImgUrl} alt="new img" />
              </div>
              <div className="inner-div mt-15 checkbox-wrapper">
                <input
                  type="checkbox"
                  id="useRemoveBg"
                  checked={useRemoveBg}
                  onChange={() => setUseRemoveBg(!useRemoveBg)}
                />
                <label htmlFor="useRemoveBg">Do you want to remove the product background?</label>
              </div>
              <CButton
                addClass="gradient active full"
                mainClass="w-full mt-10"
                onClick={onEditImage}
                size="medium"
              >
                Setup Your Product Scene Details
              </CButton>
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderTabTemplate = () => {
    return (
      <div className="tab-template">
        <div className="lbl-description mt-15">
          Select One of our Professional Scene
        </div>
        <div className="row mt-20">
          <p>Category:</p>
          <div className="content-wrap mt-5">
            <CDropdown
              id="category"
              addClass="product full"
              action={handleOptRatio}
              activeMenu={getActiveMenu(categoryOptions)}
              menu={categoryOptions}
            />
          </div>
        </div>
        <div className="row mt-15 top-results-view">
          {shuffle(CATEGORIES).map((r, index) => (
            <div className="image-row" key={index}>
              <img src={r.url} alt="painting-view" />
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderTabMagic = () => {
    return (
      <div className="tab-magic">
        <div className="lbl-description mt-5">
          Craft Your Photoshoot Magic Prompt in the form:
        </div>
        <div className="row mt-15 craft-options">
          {/* <div className="row mt-10 flex-content">
              <div className="inner-div">
                <label>Product</label>
                <CInput
                  value={product}
                  placeholder="Truffle Oil Bottle"
                  onChange={(e) => setProduct(e.target.value)}
                  onKeyUp={onKeyUpEditor}
                  addClass="full mt-5 product"
                  required={true}
                />
              </div>
            </div> */}
          <div className="row mt-10 flex-content">
            <div className="inner-div">
              <label htmlFor="placement">Placement</label>
              <CDropdown
                id="placement"
                addClass="product mt-5"
                action={handleOptRatio}
                activeMenu={getActiveMenu(placementOptions)}
                menu={placementOptions}
              />
            </div>
            <div className="inner-div">
              <label>&nbsp;</label>
              <CInput
                value={placementSub}
                onChange={(e) => setPlacementSub(e.target.value)}
                onKeyUp={onKeyUpEditor}
                addClass="product mt-5"
              />
            </div>
          </div>
          <div className="row mt-10 flex-content">
            <div className="inner-div">
              <label htmlFor="base_texture">Base Texture</label>
              <CDropdown
                id="base_texture"
                addClass="product mt-5"
                action={handleOptRatio}
                activeMenu={getActiveMenu(baseTextureOptions)}
                menu={baseTextureOptions}
              />
            </div>
            <div className="inner-div">
              <label>&nbsp;</label>
              <CInput
                value={baseTextureSub}
                onChange={(e) => setBaseTextureSub(e.target.value)}
                onKeyUp={onKeyUpEditor}
                addClass="product mt-5"
              />
            </div>
          </div>
          <div className="row mt-10 flex-content">
            <div className="inner-div">
              <label htmlFor="surrounding">Surrounding</label>
              <CDropdown
                id="surrounding"
                addClass="product mt-5"
                action={handleOptRatio}
                activeMenu={getActiveMenu(surroundingOptions)}
                menu={surroundingOptions}
              />
            </div>
            <div className="inner-div">
              <label>&nbsp;</label>
              <CInput
                value={surroundingSub}
                onChange={(e) => setSurroundingSub(e.target.value)}
                onKeyUp={onKeyUpEditor}
                addClass="product mt-5"
              />
            </div>
          </div>
          <div className="row mt-10 flex-content">
            <div className="inner-div">
              <label htmlFor="background">Background</label>
              <CDropdown
                id="background"
                addClass="product mt-5"
                action={handleOptRatio}
                activeMenu={getActiveMenu(backgroundOptions)}
                menu={backgroundOptions}
              />
            </div>
            <div className="inner-div">
              <label>&nbsp;</label>
              <CDropdown
                id="background-sub"
                addClass="product mt-5"
                action={handleOptRatio}
                activeMenu={getActiveMenu(backgroundSubOptions)}
                menu={backgroundSubOptions}
              />
              {/* <CreatableSelect
                onChange={handleBackgroundSub}
                options={varBackgroundSub}
                defaultValue={{
                  key: 1,
                  value: "white wall",
                  checked: true,
                  label: "white wall",
                }}
                className="react-select-container mt-5"
                classNamePrefix="react-select"
                styles={colourStyles}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: "neutral30",
                    primary: "black",
                  },
                })}
              /> */}
            </div>
          </div>
        </div>
        <div className="row mt-15 advanced-options">
          <div
            className="advanced-btn cursor-pointer"
            onClick={() => setAdvanced(!advanced)}
          >
            <p>
              <strong>Advanced Photography Settings</strong>
            </p>
            <CToggle isOn={advanced} onClick={() => setAdvanced(!advanced)} />
          </div>
          {advanced && (
            <div className="row">
              <div className="row mt-10 flex-content">
                <div className="inner-div">
                  <label htmlFor="lightning">Lighting</label>
                  <CDropdown
                    id="lightning"
                    addClass="product mt-5"
                    action={handleOptRatio}
                    activeMenu={getActiveMenu(lightningOptions)}
                    menu={lightningOptions}
                  />
                </div>
                <div className="inner-div">
                  <label>&nbsp;</label>
                  <CInput
                    value={lightningSub}
                    onChange={(e) => setLightningSub(e.target.value)}
                    onKeyUp={onKeyUpEditor}
                    addClass="product mt-5"
                  />
                </div>
              </div>
              <div className="row mt-10 flex-content">
                <div className="inner-div">
                  <label htmlFor="colors">Colors</label>
                  <CDropdown
                    id="colors"
                    addClass="product mt-5"
                    action={handleOptRatio}
                    activeMenu={getActiveMenu(colorsOptions)}
                    menu={colorsOptions}
                  />
                </div>
                <div className="inner-div">
                  <label htmlFor="composition">Composition</label>
                  <CDropdown
                    id="composition"
                    addClass="product mt-5"
                    action={handleOptRatio}
                    activeMenu={getActiveMenu(compositionOptions)}
                    menu={compositionOptions}
                  />
                </div>
              </div>
              <div className="row mt-10 flex-content">
                <div className="inner-div">
                  <label htmlFor="mood">Mood</label>
                  <CDropdown
                    id="mood"
                    addClass="product mt-5"
                    action={handleOptRatio}
                    activeMenu={getActiveMenu(moodOptions)}
                    menu={moodOptions}
                  />
                </div>
                <div className="inner-div">
                  <label htmlFor="custom_style">Style</label>
                  <CDropdown
                    id="custom_style"
                    addClass="product mt-5"
                    action={handleOptRatio}
                    activeMenu={getActiveMenu(customStyleOptions)}
                    menu={customStyleOptions}
                  />
                </div>
              </div>
              <div className="row mt-10 flex-content">
                <div className="inner-div">
                  <label htmlFor="camera_angle">Camera Angles</label>
                  <CDropdown
                    id="camera_angle"
                    addClass="product full mt-5"
                    action={handleOptRatio}
                    activeMenu={getActiveMenu(cameraAngleOptions)}
                    menu={cameraAngleOptions}
                  />
                </div>
              </div>
              <div className="row mt-10">
                <div
                  className="negative-txt cursor-pointer"
                  onClick={() => setUseNegativeText(!useNegativeText)}
                >
                  <p>Negative Text</p>
                  <CToggle
                    isOn={useNegativeText}
                    onClick={() => setUseNegativeText(!useNegativeText)}
                  />
                </div>
                {useNegativeText && (
                  <CTextarea
                    placeholder=""
                    onChange={(e) => setNegativeText(e.target.value)}
                    rows={2}
                    addClass="product mt-5"
                    active={true}
                    value={negativeText}
                  ></CTextarea>
                )}
              </div>
              <div className="row mt-10">
                <div className="flex-content mt-5">
                  <div
                    className={`cursor-pointer use-brand-div ${showBrandFilters ? "" : "checkbox-disabled"
                      }`}
                    onClick={() => changeUseBrand(!useBrand)}
                  >
                    <p>Use Brand Kit?</p>
                    <CToggle
                      isOn={useBrand}
                      onClick={() => changeUseBrand(!useBrand)}
                    />
                  </div>
                  <div className="inner-div">
                    <CDropdown
                      id="brand-option"
                      proLabel={!showBrandFilters}
                      disabled={!showBrandFilters || !useBrand}
                      addClass="product full"
                      action={handleOptRatio}
                      activeMenu={getActiveMenu(brandOptions)}
                      menu={brandOptions}
                    />
                  </div>
                </div>
              </div>
              {useBrand && showBrandFilters && (
                <div className="row mt-10">{renderBrandInfo()}</div>
              )}
            </div>
          )}
        </div>
        <div className="row mt-15 generation-options">
          <p>
            <strong>Generation Settings</strong>
          </p>
          <div className="row mt-5 flex-content">
            <div className="inner-div">
              <label htmlFor="variation">Numbers of Variations</label>
              <CDropdown
                id="variation"
                addClass="product mt-5"
                action={handleOptRatio}
                // disabled={user?.user?.platform === ENGINE_TYPE.creativio}
                activeMenu={getActiveMenu(variationOptions)}
                menu={variationOptions}
              />
            </div>
            <div className="inner-div">
              <label htmlFor="render">Render Strength</label>
              <CDropdown
                id="render"
                addClass="product mt-5"
                action={handleOptRatio}
                activeMenu={getActiveMenu(renderOptions)}
                menu={renderOptions}
              />
            </div>
          </div>
          <div className="row mt-5 flex-content">
            <div className="inner-div">
              <label htmlFor="style">Generation Style</label>
              <CDropdown
                id="style"
                addClass="product mt-5"
                action={handleOptRatio}
                activeMenu={getActiveMenu(styleOptions)}
                menu={styleOptions}
              />
            </div>
            <div className="inner-div">
              <label htmlFor="prompt">Prompt Strength</label>
              <CDropdown
                id="prompt"
                addClass="product mt-5"
                action={handleOptRatio}
                activeMenu={getActiveMenu(promptOptions)}
                menu={promptOptions}
              />
            </div>
          </div>
          <div className="row mt-5 flex-content">
            <div className="inner-div">
              <label htmlFor="image_dimension">Image Dimension</label>
              <CDropdown
                id="image_dimension"
                addClass="product mt-5 full"
                action={handleOptRatio}
                activeMenu={getActiveMenu(imageDimensionsOptions)}
                menu={imageDimensionsOptions}
              />
            </div>
          </div>
          {/* <div className="row mt-5 flex-content">
            <div className="inner-div mt-5 checkbox-wrapper">
              <input
                type="checkbox"
                id="aspectRatio"
                checked={useAspectRatio}
                onChange={() => setUseAspectRatio(!useAspectRatio)}
              />
              <label htmlFor="aspectRatio">Aspect Ratio</label>
            </div>
            <div className="inner-div mt-5 checkbox-wrapper">
              <input
                type="checkbox"
                id="upSize"
                checked={useUpSize}
                onChange={() => setUseUpSize(!useUpSize)}
              />
              <label htmlFor="upSize">UpSize</label>
            </div>
          </div> */}
        </div>
      </div>
    );
  };

  const renderEdit = () => {
    return (
      <div className="section-edit">
        <div className="head">
          <Slide className="flex1">
            <h1>
              <span className="title-span">
                {!!onExit && (
                  <button onClick={onExit} type="button">
                    <ExitSvg />
                  </button>
                )}{" "}
                Product Photoshoot
              </span>{" "}
              Scene Setup
            </h1>
          </Slide>
        </div>
        <div className="edit-area">
          <div className="edit-image-area">
            {newImgUrl && (
              <ImageEditor
                imageUrl={newImgUrl}
                imgW={imageWidth}
                imgH={imageHeight}
                onUpdateResizeImage={updateResizeImage}
              />
            )}
          </div>
          <div className="assisted-setting-area">
            <div className="cap1">Generate Photoshoot</div>
            <div
              className="chk-builder-txt flex txt-lbl cursor-pointer"
              onClick={() => setUseBuilderText(!useBuilderText)}
            >
              <p>Use this prompt &nbsp;</p>
              <CToggle
                isOn={useBuilderText}
                onClick={() => setUseBuilderText(!useBuilderText)}
              />
            </div>
            <CTextarea
              placeholder="Ex: A Truffle Oil Bottle standing on a tree stump surounded by golden bokeh against a magical jungle in the background"
              onChange={(e) => setContent(e.target.value)}
              rows={3}
              addClass="product mt-10"
              active={true}
              value={content}
              readOnly={true}
            ></CTextarea>
            <div className="tab-area">
              <div className="tab-head">
                {/* <div onClick={() => setActiveTab('template')} className={`tab ${activeTab === 'template' ? 'active' : ''}`}>Templates</div> */}
                <div
                  onClick={() => setActiveTab("magic")}
                  className={`tab ${activeTab === "magic" ? "active" : ""}`}
                >
                  Magic Editor
                </div>
              </div>
              <div className="tab-body">
                {/* {activeTab === 'template' && renderTabTemplate()} */}
                {activeTab === "magic" && renderTabMagic()}
              </div>
            </div>
          </div>
        </div>
        <div className="action-bar mt-15">
          <CButton
            addClass="gradient active py-22i"
            onClick={onMagicPrompt}
            size="medium"
          >
            <Magic className="btn-icon" />
            Prompt Magic
          </CButton>
          <CTextarea
            placeholder="Ex: Beautiful flowers and a blob object frozen in a block of ice, isolated on warm-grey background"
            onChange={(e) => setMagicText(e.target.value)}
            value={magicText}
            rows={2}
          ></CTextarea>
          <CButton
            addClass="gradient active py-22i"
            onClick={() => onGenerateImage()}
            size="medium"
          >
            Generate Image
          </CButton>
        </div>
      </div>
    );
  };

  const renderResult = () => {
    return (
      <div className="section-result">
        <div className="content-area">
          <div className="content-body">
            <div className="image-area">
              <BrushCanvas
                mainCaption="Product"
                subCaption="Enhanced Variations"
                ref={brushCanvasRef}
                image={{
                  src: resultImg?.image,
                  width: imageWidth,
                  height: imageHeight,
                }}
                orgImage={{
                  src: activeResultImg?.image,
                  width: imageWidth,
                  height: imageHeight,
                }}
                history={{
                  layers,
                  setLayers,
                  backupLayers,
                  setBackupLayers,
                  redoStep,
                  setRedoStep,
                }}
                newcanvasWidth={Number(imageWidth)}
                newcanvasHeight={Number(imageHeight)}
                onRemove={onReGenerateImage}
                onChange={onChangeCanvas}
                onExit={handleBack}
              />
            </div>
          </div>
          <div className="result-preview">
            {resultImgs?.map((k, index) => (
              <div
                className={`preview-img mb-10 ${activeResultImg.id === k.id ? "active" : ""
                  }`}
                key={index}
                onClick={() => selResultImg(index)}
              >
                <img src={k.image} alt={`preview-paint-${index}`} />
              </div>
            ))}
          </div>
        </div>
        <div className="action-bar">
          <CButton
            addClass="gradient active py-22i"
            onClick={handleBack}
            size="medium"
          >
            Back
          </CButton>
          <CTextarea
            placeholder="Ex: Beautiful flowers and a blob object frozen in a block of ice, isolated on warm-grey background"
            onChange={(e) => setResultMagicText(e.target.value)}
            value={resultMagicText}
            rows={2}
          ></CTextarea>
          <CButton
            addClass="gradient active py-22i"
            onClick={onReGenerateImage}
            size="medium"
          >
            {reGenerateBtnTitle}
          </CButton>
        </div>
      </div>
    );
  };

  return (
    <DashboardLayout
      page="edit"
      caption="Product Studio"
      message="Enhance Your product Photography with AI"
      license={[[LICENSE.BUSINESS], [LICENSE.STARTER]]}
    >
      <div id="enhance" className="body-container">
        {stage === STAGES.upload && renderUpload()}
        {stage === STAGES.edit && renderEdit()}
        {stage === STAGES.result && renderResult()}
      </div>
    </DashboardLayout>
  );
}
