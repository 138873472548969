import React from "react";
import Modal from "react-modal";
import { ReactComponent as IconClose } from "assets/imgs/icon_close.svg";
import { ReactComponent as IconVideo } from "assets/imgs/modal/video.svg";
import { CButton } from "components/atoms/CButton";
import { useState } from "react";
import "./index.scss";

export function VideoStatusModal({
  show = false,
  video = {},
  onClose = null,
  onShowVideoStatus = null,
}) {
  const [showVideoStatusPopup, setshowVideoStatusPopup] = useState(false);

  return (
    <Modal
      isOpen={show}
      ariaHideApp={false}
      onClick={onClose}
      className="video-status-modal"
    >
      <IconClose
        className="cursor-pointer ico-close"
        onClick={() => onClose(showVideoStatusPopup)}
      />
      <div className="description-area">
        <h2>
          <IconVideo className="head-span" />
          &nbsp;Generating Motion...
        </h2>
        <p>
          View your animation generation status by clicking the{" "}
          <span className="head-span">View Generation Status</span> button or
          view the animation in the{" "}
          <span className="head-span">Animation Feed</span> when it's complete.
        </p>
        <div className="checkbox-wrapper mt-20">
          <input
            type="checkbox"
            id="showVideoStatusPopup"
            checked={showVideoStatusPopup}
            onChange={() => setshowVideoStatusPopup(!showVideoStatusPopup)}
          />
          <label htmlFor="showVideoStatusPopup">Don't show this again</label>
        </div>
        <div className="button-container mt-25">
          <CButton
            mainClass="button"
            addClass="gradient full"
            active={true}
            onClick={() => onShowVideoStatus(video.id)}
          >
            View Generation Status
          </CButton>
          <CButton
            mainClass="button"
            addClass="gradient full"
            active={true}
            onClick={() => onClose(showVideoStatusPopup)}
          >
            Close
          </CButton>
        </div>
      </div>
    </Modal>
  );
}
