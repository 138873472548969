import { useContext, useState, useEffect } from "react";
import Modal from "react-modal";
import { ReactComponent as IconClose } from "assets/imgs/icon_close.svg";

import { ReactComponent as IconCRM } from "assets/imgs/modal/agency_crm.svg";
import { ReactComponent as IconMembers } from "assets/imgs/modal/agency_members.svg";
import { ReactComponent as IconVIPAccess } from "assets/imgs/modal/agency_vipaccess.svg";
import AgencyExample from "assets/imgs/modal/agency_example.png";
import { LayoutContext } from "components/contexts/LayoutContextContainer";
import { LICENSE } from "utils/constants";

import "./index.scss";

export function AgencyMembershipModal({ show = false, onClose = () => { }, user }) {
  const { onPaddleCheckoutOpen } = useContext(LayoutContext);

  const handleUpgradeBtnClick = () => {
    onClose();
    // if (user?.user?.source === 'jvzoo') {
    window.open('https://creativio.io/jvz-agency', '_blank');
    // } else if (user?.user?.source === 'paddle') {
    // onPaddleCheckoutOpen(LICENSE.AGENCY);
    // }
  };

  return (
    <Modal
      isOpen={show}
      ariaHideApp={false}
      onClick={onClose}
      className="membership-modal"
    >
      <div className="membership-modal__content">
        <IconClose className="cursor-pointer ico-close" onClick={onClose} />
        <div className="user-area">
          <h2 className="membership-modal__title">
            YES! Upgrade to Agency License
          </h2>
          <h3 className="membership-modal__subtitle">
            UNLOCK The NEW Client Management + Team Members
          </h3>
          <div className="mt-20 flex-group">
            <img
              className="sample-img"
              src={AgencyExample}
              alt="example photoshoot"
            />
            <div className="features">
              <div className="feature">
                <div className="main-title">
                  <IconCRM />
                  Client Management
                </div>
                <p>
                  Integrate your client interactions seamlessly with our Internal CRM Module. Designed for digital agencies, this tool simplifies client management, allowing you to focus on what truly matters.
                </p>
              </div>
              <div className="feature">
                <div className="main-title">
                  <IconMembers />
                  Add Extra Team Members
                </div>
                <p>
                  Empower your agency by adding up to 5 extra team members or virtual assistants to your Creativio account. Collaborate effortlessly and scale your creative output without limits.
                </p>
              </div>
              <div className="feature">
                <div className="main-title">
                  <IconVIPAccess />
                  VIP Access To Al Webinars
                </div>
                <p>
                  Enjoy complimentary access to bi-weekly AI webinars and workshops. Stay ahead of the curve with insights and strategies from leading industry experts.
                </p>
              </div>
            </div>
          </div>
          <div className="stroke-line mt-20 mb-20" />
          <div className="last-group">
            <div className="last-caption">
              <h3 className="last-caption__title">
                Start an Agency Business Today
              </h3>
              <p className="mt-10">
                Harness the full power of Creativio AI Agency and expand your horizons by offering product photography services to clients world wide while building a Sustainable Recurring Agency Business.
                <b>This isn't just an upgrade; it's a game-changer.</b>
              </p>
            </div>
            <button className="last-caption__not-now" onClick={onClose}>
              Not now
            </button>
            <div className="action-area">
              <button
                className="btn-upgrade"
                onClick={handleUpgradeBtnClick}
              >
                <label>UPGRADE NOW</label>
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
