import "./styles.scss";
import { DashboardLayout } from "components/layouts/DashboardLayout";
import { useContext, useEffect, useRef, useState } from "react";
import { Slide, Fade } from "react-awesome-reveal";
import { CButton } from "components/atoms/CButton";
import { ReactComponent as Plus } from "assets/imgs/plus_white.svg";
import { ReactComponent as ArrowBack } from "assets/imgs/arrow_back.svg";
import { LICENSE } from "utils/constants";
import { getApi } from "utils/customNetwork";
import { LayoutContext } from "components/contexts/LayoutContextContainer";
import { CTextarea } from "components/atoms/CTextarea";
import { CDropdown } from "components/atoms/CDropdown";
import Placeholder from "assets/imgs/placeholder.png";
import ClientTable from "./clientTable";
import useTable from "hooks/useTable";
import ConfirmationModal from 'components/atoms/Modal/ConfirmationModal';

const rowsPerPage = 10;

export function Client() {
    const { showNoti, setLoading, checkToken } = useContext(LayoutContext);
    const fileInputRef = useRef(null);
    const [stage, setStage] = useState("view"); // view || new || update
    const [newClientImageURL, setNewClientImageURL] = useState(null); // new image - URL
    const [countryOptions, setCountryOptions] = useState([]);
    const [selClient, setSelClient] = useState([]);
    const initialValues = {
        name: '',
        business_name: '',
        business_description: '',
        business_address: '',
        business_email: '',
        business_phone: '',
        city: '',
        country: '',
        contract_value: '',
        category: '',
        business_niche: '',
        business_domain_field: '',
        business_fb_page: '',
        business_linkedin_profile: '',
        business_twitter_profile: '',
        business_youtube_profile: '',
        image: '',
    };
    const [formData, setFormData] = useState(initialValues);
    const [filteredClients, setFilteredClients] = useState([]);
    // const [keyword, setKeyword] = useState("");
    const [page, setPage] = useState(1);
    const { slice, range } = useTable(filteredClients, page, rowsPerPage);
    const [showClientDelCnfm, setShowClientDelCnfm] = useState(false);
    const [itemToDel, setItemToDel] = useState(null);

    const getClients = async () => {
        try {
            checkToken();
            setLoading(true);
            const data = await getApi("/getClients", "POST", {});
            setFilteredClients(data.clients);
        } catch (err) {
            showNoti("error", err);
        }
        setLoading(false);
    };

    const getCountry = async () => {
        try {
            checkToken();
            setLoading(true);
            const country = await getApi("/getCountry", "POST", {});
            const transformedData = country.country.map(country => {
                if (country.is_enable === 1) {
                    return {
                        key: country.id,
                        id: country.sortname,
                        icon: null,
                        color: "white",
                        checked: false,
                        label: country.name,
                    };
                } else {
                    return null;
                }
            }).filter(Boolean);
            const defaultValue = {
                key: -1,
                id: '',
                icon: null,
                color: "white",
                checked: true,
                label: "-- Select Country --",
            };
            transformedData.unshift(defaultValue);
            setCountryOptions(transformedData);
        } catch (err) {
            showNoti("error", err);
        }
        setLoading(false);
    };

    useEffect(() => {
        getClients();
        getCountry();
    }, []);

    const onClientImageUpload = () => {
        fileInputRef.current.click();
    };

    const onRemoveClientAvatar = () => {
        setNewClientImageURL(null);
        if (selClient) {
            setFormData({
                ...formData,
                image: selClient.image
            });
        }
        else {
            setFormData({
                ...formData,
                image: ''
            });
        }
    }

    const OnCreateClient = () => {
        setStage('update');
        setFormData(initialValues);
        setSelClient(null);
    };

    const onCloseClientForm = () => {
        setStage('view');
        setFormData(initialValues);
        setSelClient(null);
        setNewClientImageURL(null);
    };

    // Function to handle changes in form fields
    const handleChange = (e) => {
        const { name, value } = e.target;
        // Update the form data state with the new value
        setFormData({
            ...formData,
            [name]: value
        });
    };

    // Function to handle file upload
    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        if (!file) return;

        if (file) {
            const reader = new FileReader();
            reader.onload = () => {
                setNewClientImageURL(reader.result);
                // Update the form data state with the uploaded file
                setFormData((prevFormData) => ({
                    ...prevFormData,
                    image: file
                }));
            };
            reader.readAsDataURL(file);
        }
    };

    const getActiveMenu = (arr) => {
        const chk = arr.find((k) => k.checked);
        if (chk) return chk;
        else return arr[0];
    };

    useEffect(() => {
        if (countryOptions && countryOptions.length > 0) {
            setFormData({
                ...formData,
                country: getActiveMenu(countryOptions).id
            });
        }
    }, [countryOptions]);

    const handleOptRatio = (id, index, value) => {
        switch (id) {
            case "country":
                const customOptions = [...countryOptions];
                customOptions.forEach((el) => (el.checked = false));
                customOptions[index].checked = value;
                setCountryOptions(customOptions);
                break;
            default:
                break;
        }
    };

    const handleClientEdit = (item) => {
        item.contract_value = '';
        setSelClient(item);
        setStage('update');
        setFormData(item);
    };

    const handleTblDelete = async (item) => {
        setItemToDel(item);
        setShowClientDelCnfm(true);
    };

    const closeDelModal = () => {
        setShowClientDelCnfm(false);
        setItemToDel(null);
    };

    const cnfmDel = async (item) => {
        setLoading(true);
        try {
            checkToken();
            await getApi(`/deleteClient/${item.id}`, "DELETE");
            const updatedFilteredRecords = filteredClients.filter(client => client.id !== item.id);
            setFilteredClients(updatedFilteredRecords);
            setShowClientDelCnfm(false);
            setItemToDel(null);
            showNoti("success", "Client Deleted successfully");
        } catch (err) {
            showNoti("error", err);
        }
        setLoading(false);
    };

    const handleReset = () => {
        setNewClientImageURL(null);
        if (selClient) {
            setFormData({
                ...selClient,
                country: selClient.country,
                image: selClient.image
            });
        } else {
            setFormData({
                ...initialValues,
                country: getActiveMenu(countryOptions).id[0],
                image: ''
            });
        }
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (formData.name == '') {
            showNoti("info", "Please enter a client name.");
            return;
        }
        if (formData.business_name == '') {
            showNoti("info", "Please enter a business name.");
            return;
        }
        if (formData.business_description == '') {
            showNoti("info", "Please enter a business description.");
            return;
        }
        if (formData.business_email == '') {
            showNoti("info", "Please enter a business email.");
            return;
        }
        if (formData.business_niche == '') {
            showNoti("info", "Please enter a business niche.");
            return;
        }
        let api = "/storeClient";
        const newFormData = new FormData();
        newFormData.append('name', formData.name);
        newFormData.append('business_name', formData.business_name);
        newFormData.append('business_description', formData.business_description);
        newFormData.append('business_address', formData.business_address);
        newFormData.append('business_email', formData.business_email);
        newFormData.append('business_phone', formData.business_phone);
        newFormData.append('city', formData.city);
        newFormData.append('country', formData.country);
        // newFormData.append('contract_value', formData.contract_value);
        newFormData.append('category', formData.category);
        newFormData.append('business_niche', formData.business_niche);
        newFormData.append('business_domain_field', formData.business_domain_field);
        newFormData.append('business_fb_page', formData.business_fb_page);
        newFormData.append('business_linkedin_profile', formData.business_linkedin_profile);
        newFormData.append('business_twitter_profile', formData.business_twitter_profile);
        newFormData.append('business_youtube_profile', formData.business_youtube_profile);
        if (newClientImageURL != null) {
            newFormData.append('image', formData.image);
        }

        if ((selClient && selClient.id)) {
            api = "/updateClient";
            newFormData.append('id', selClient.id);
        }
        try {
            setLoading(true);
            checkToken();
            const data = await getApi(api, "POST", newFormData);
            if (selClient?.id) {
                const existingClientIndex = filteredClients.findIndex(client => client.id === (data.client[0]?.id));
                if (existingClientIndex !== -1) {
                    const updatedClients = [...filteredClients];
                    updatedClients[existingClientIndex] = data.client[0];
                    setFilteredClients(updatedClients);
                    showNoti("success", "Client Updated Successfully!");
                }
            }
            else {
                setPage(1);
                setFilteredClients([...data.client, ...filteredClients]);
                showNoti("success", "Client Created Successfully!");
            }
            onCloseClientForm();
        } catch (err) {
            showNoti("error", err);
        }
        setLoading(false);
    };

    const renderClients = () => {
        return (
            <div>
                <ClientTable
                    slice={slice}
                    range={range}
                    page={page}
                    handleTblEdit={handleClientEdit}
                    handleTblDelete={handleTblDelete}
                    setPage={setPage}
                />
                {
                    showClientDelCnfm && (
                        <ConfirmationModal
                            isOpen={showClientDelCnfm}
                            onClose={closeDelModal}
                            itemModule="Client"
                            onConfirm={() => cnfmDel(itemToDel)}
                            itemName={itemToDel ? itemToDel.name : ''}
                        />
                    )
                }

            </div>
        )
    };

    const renderUpdateClients = () => {
        return (
            <div className="update-client-area">
                <h2>{formData?.id ? "Edit" : "Create New"} Client</h2>
                <form onSubmit={handleSubmit}>
                    <div className="form-group mt-10 outer-div">
                        <div className="flex1">
                            <p>Client Name <sup className="text-danger">*</sup></p>
                            <div className="input-field mt-10">
                                <input
                                    type="text"
                                    id="name"
                                    name="name"
                                    placeholder="John Doe"
                                    value={formData.name}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                        </div>
                        <div className="flex1">
                            <p>Client Business Name <sup className="text-danger">*</sup></p>
                            <div className="input-field mt-10">
                                <input
                                    type="text"
                                    id="business_name"
                                    name="business_name"
                                    placeholder="Business name"
                                    value={formData.business_name}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                        </div>
                    </div>
                    <div className="form-group mt-10 outer-div">
                        <div className="flex1">
                            <p>Client Business Description <sup className="text-danger">*</sup></p>
                            <CTextarea
                                id="business_description"
                                name="business_description"
                                placeholder="Business Description"
                                onChange={handleChange}
                                rows={2}
                                addClass="product mt-10"
                                active={true}
                                value={formData.business_description}
                                required
                            />
                        </div>
                        <div className="flex1">
                            <p>Business Address</p>
                            <CTextarea
                                id="business_address"
                                name="business_address"
                                placeholder="Business Address"
                                onChange={handleChange}
                                rows={2}
                                addClass="product mt-10"
                                active={true}
                                value={formData.business_address}
                            />
                        </div>
                    </div>
                    <div className="form-group mt-10 outer-div">
                        <div className="flex1">
                            <p>Business Email <sup className="text-danger">*</sup></p>
                            <div className="input-field mt-10">
                                <input
                                    type="email"
                                    id="business_email"
                                    name="business_email"
                                    placeholder="john.doe@email.com"
                                    value={formData.business_email}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                        </div>
                        <div className="flex1">
                            <p>Business Phone Number</p>
                            <div className="input-field mt-10">
                                <input
                                    type="number"
                                    id="business_phone"
                                    name="business_phone"
                                    placeholder="Business Phone Number"
                                    value={formData.business_phone}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="form-group mt-10 outer-div">
                        <div className="flex1">
                            <p>City</p>
                            <div className="input-field mt-10">
                                <input
                                    type="text"
                                    id="city"
                                    name="city"
                                    placeholder="City"
                                    value={formData.city}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className="flex1">
                            <p>Country</p>
                            <CDropdown
                                id="country"
                                name="country"
                                addClass="w-full mt-10"
                                action={handleOptRatio}
                                activeMenu={getActiveMenu(countryOptions)}
                                menu={countryOptions}
                            />
                        </div>
                    </div>
                    <div className="form-group mt-10 outer-div">
                        <div className="flex1">
                            <p>Category</p>
                            <div className="input-field mt-10">
                                <input
                                    type="text"
                                    id="category"
                                    name="category"
                                    placeholder="Category"
                                    value={formData.category}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className="flex1">
                            <p>Contract Value</p>
                            <div className="input-field mt-10">
                                <input
                                    type="text"
                                    id="contract_value"
                                    name="contract_value"
                                    placeholder="Contract Value"
                                    value={formData.contract_value}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="form-group mt-10 outer-div">
                        <div className="flex1">
                            <p>Business Niche <sup className="text-danger">*</sup></p>
                            <div className="input-field mt-10">
                                <input
                                    type="text"
                                    id="business_niche"
                                    name="business_niche"
                                    placeholder="Business Niche"
                                    value={formData.business_niche}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                        </div>
                        <div className="flex1">
                            <p>Business Domain Field</p>
                            <div className="input-field mt-10">
                                <input
                                    type="text"
                                    id="business_domain_field"
                                    name="business_domain_field"
                                    placeholder="Business Domain Field"
                                    value={formData.business_domain_field}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="form-group mt-10 outer-div">
                        <div className="flex1">
                            <p>Business Facebook Page</p>
                            <div className="input-field mt-10">
                                <input
                                    type="text"
                                    id="business_fb_page"
                                    name="business_fb_page"
                                    placeholder="Business Facebook Page"
                                    value={formData.business_fb_page}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className="flex1">
                            <p>Business LinkedIn Profile</p>
                            <div className="input-field mt-10">
                                <input
                                    type="text"
                                    id="business_linkedin_profile"
                                    name="business_linkedin_profile"
                                    placeholder="Business LinkedIn Profile"
                                    value={formData.business_linkedin_profile}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="form-group mt-10 outer-div">
                        <div className="flex1">
                            <p>Business Twitter Profile</p>
                            <div className="input-field mt-10">
                                <input
                                    type="text"
                                    id="business_twitter_profile"
                                    name="business_twitter_profile"
                                    placeholder="Business Twitter Profile"
                                    value={formData.business_twitter_profile}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className="flex1">
                            <p>Business Youtube Profile</p>
                            <div className="input-field mt-10">
                                <input
                                    type="text"
                                    id="business_youtube_profile"
                                    name="business_youtube_profile"
                                    placeholder="Business Youtube Profile"
                                    value={formData.business_youtube_profile}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="client-profile-box mt-10">
                        <div className="avatar-box">
                            {newClientImageURL ? (
                                <div className="client-avatar">
                                    <img src={newClientImageURL} alt="profile image" />
                                </div>
                            ) : (
                                formData.image ? (
                                    <div className="client-avatar">
                                        <img src={formData.image} alt="profile image" />
                                    </div>
                                ) : (
                                    <div className="client-avatar">
                                        <img src={Placeholder} alt="profile image" />
                                    </div>
                                )
                            )}
                        </div>
                        <div className="avatar-btns">
                            <CButton mainClass="mt-10"
                                addClass="gradient p-5I mr-5" active={true} onClick={onClientImageUpload}>
                                <input
                                    type="file"
                                    accept=".png, .jpg, .jpeg"
                                    onInput={handleFileUpload}
                                    onChange={(e) => e.target.value = null}
                                    style={{ display: "none" }}
                                    ref={fileInputRef}
                                />
                                {(formData.image || newClientImageURL) ? "Change" : "Add"}
                            </CButton>

                            {newClientImageURL ? (
                                <CButton mainClass="mt-10"
                                    addClass="gradient p-5I mr-5" active={true} onClick={onRemoveClientAvatar}>
                                    Remove
                                </CButton>
                            ) : null}
                        </div>
                    </div>
                    <div className="form-group mt-25">
                        <CButton mainClass="mt-10"
                            addClass="gradient p-5I mr-5" type="submit" active={true}>
                            Save
                        </CButton>
                        <CButton mainClass="mt-10"
                            addClass="gradient p-5I mr-5" type="button" onClick={handleReset} active={true}>
                            Reset
                        </CButton>
                    </div>
                </form>
            </div>
        )
    };

    return (
        <DashboardLayout
            page="client"
            caption="Manage Clients"
            license={[[LICENSE.AGENCY, LICENSE.BUSINESS], [LICENSE.AGENCY, LICENSE.STARTER]]}
            message="Add and Update your Clients"
        >
            <div id="clients" className="body-container">
                <div className="head">
                    <Slide className="flex1">
                        <h1>
                            <span>Manage</span> Clients
                        </h1>
                    </Slide>
                    {stage === "view" && (
                        <CButton
                            mainClass="mt-20"
                            addClass="gradient p-5I"
                            active={true}
                            onClick={OnCreateClient}
                        >
                            <Plus className="btn-icon" />
                            Add New Client
                        </CButton>
                    )}
                    {stage === "update" && (
                        <CButton
                            mainClass="mt-20"
                            addClass="gradient p-5I"
                            active={true}
                            onClick={onCloseClientForm}
                        >
                            <ArrowBack className="btn-icon" />
                            Back To Clients
                        </CButton>
                    )}
                </div>
                {stage === "view" && renderClients()}
                {stage === "update" && renderUpdateClients()}
            </div>
        </DashboardLayout>
    );
}