/* eslint-disable jsx-a11y/anchor-is-valid */
import { DashboardLayout } from "components/layouts/DashboardLayout";
import "./styles.scss";
import { useContext, useEffect, useState, useMemo } from "react";
import { CButton } from "components/atoms/CButton";
import CToolTip from "components/atoms/CToolTip";
import { Account } from "./account";
// import ProgressBar from "@ramonak/react-progress-bar";
import { LayoutContext } from "components/contexts/LayoutContextContainer";
import { getApi } from "utils/customNetwork";
import { Connects } from "./connects";
import { Billings } from "./billing";
import { showLicense } from "utils/util";
import { LICENSE } from "utils/constants";
import getFullName from "utils/getFullName";
import { Slide } from "react-awesome-reveal";

export function Setting() {
  const {
    setLoading,
    user,
    setUser,
    showNoti,
    checkToken,
    upgradeLicense,
    onUpgradeLicense,
  } = useContext(LayoutContext);
  const [activePage, setActivePage] = useState("account"); // account | integrations | billing

  const userProfile = useMemo(() => user?.user, [user]);


  const shortName = () => {
    if (userProfile.first_name !== "") {
      return userProfile.first_name.substring(0, 2).toUpperCase();
    } else {
      return userProfile.email.substring(0, 2).toUpperCase();
    }
  };

  const getUser = async () => {
    setLoading(true);
    try {
      checkToken();
      const data = await getApi("/getUser", "POST", {
        user_id: user.user.id
      });
      setUser({ ...user, user: data.user });
    } catch (err) {
      showNoti("error", err);
    }
    setLoading(false);
  };
  const updateUser = async (param) => {
    setLoading(true);
    try {
      checkToken();
      const data = await getApi("/updateUser", "POST", param);
      setUser({ ...user, user: data.user });
      showNoti("success", "User profile update successfully");
    } catch (err) {
      showNoti("error", err);
    }
    setLoading(false);
  };

  const updateUserAvatar = async (param) => {
    setLoading(true);
    try {
      checkToken();
      const data = await getApi("/updateUserAvatar", "POST", param);
      setUser({ ...user, user: data.user });
      showNoti("success", "User profile picture updated successfully!");
    } catch (err) {
      showNoti("error", err);
    }
    setLoading(false);
  };

  const removeUserAvatar = async () => {
    setLoading(true);
    try {
      checkToken();
      const data = await getApi("/removeUserAvatar", "POST");
      setUser({ ...user, user: data.user });
      showNoti("success", "User profile picture removed successfully!");
    } catch (err) {
      showNoti("error", err);
    }
    setLoading(false);
  };

  useEffect(() => {
    getUser();
    // eslint-disable-next-line
  }, []);

  const handleChangePlatform = async (e) => {
    const platformName = e.target.value;
    if (!platformName) return;

    try {
      setLoading(true);

      const data = await getApi("/v1/users/me/change-platform", "PATCH", {
        platformName,
      });

      setUser({ ...user, user: data.user });
    } catch (e) {
      showNoti("error", e);
    } finally {
      setLoading(false);
    }
  };

  const handleChangePrivacy = async (keepPrivate) => {
    if (!user?.user?.license.includes(LICENSE.PROFESSIONAL)) return;
    // if (user?.user?.license !== LICENSE.PROFESSIONAL) return;
    try {
      setLoading(true);
      const data = await getApi("/v1/users/me/change-privacy-image", "PATCH", {
        privacy: keepPrivate,
      });
      setUser({ ...user, user: data.user });
    } catch (e) {
      showNoti("error", e);
    } finally {
      setLoading(false);
    }
  };

  const licensePriority = [
    LICENSE.AGENCY,
    LICENSE.ANIMATION,
    LICENSE.PROFESSIONAL,
    LICENSE.BUSINESS,
    LICENSE.STARTER,
  ];

  const getLicenses = (userLicenses) => {
    let buttons = [];

    if (!Array.isArray(userLicenses)) {
      buttons.push(
        <div key={LICENSE.FREE_TRIAL}>
          <CButton addClass="primary active">
            {showLicense([LICENSE.FREE_TRIAL])} License
          </CButton>
        </div>
      );
      return buttons;
    }

    const hasBusiness = userLicenses.includes(LICENSE.BUSINESS);
    const hasStarter = userLicenses.includes(LICENSE.STARTER);

    licensePriority.forEach(license => {
      if (!userLicenses.includes(license)) {
        if (userLicenses.length > 0 && (license === LICENSE.BUSINESS || license === LICENSE.STARTER) && !hasBusiness && !hasStarter) {
          buttons.push(
            <div key={license}>
              <CToolTip text={`Purchase the Business or Starter Edition to unlock features.`}>
                <CButton onClick={() => onUpgradeLicense(license)} addClass="primary active purchase-button">
                  Purchase {showLicense([license])} Edition
                </CButton>
              </CToolTip>
            </div>
          );
        } else if ((license === LICENSE.BUSINESS && hasStarter) || (license === LICENSE.STARTER && hasBusiness)) {
          // User has one of BUSINESS or STARTER, hide the upgrade button for the other
          return;
        } else if (upgradeLicense.includes(license)) {
          buttons.push(
            <div key={license}>
              <CToolTip text={`Upgrade to the ${showLicense([license])} license to access additional features.`}>
                <CButton onClick={() => onUpgradeLicense(license)} addClass="primary light">
                  Upgrade to {showLicense([license], true)}
                </CButton>
              </CToolTip>
            </div>
          );
        } else {
          buttons.push(
            <div key={license}>
              <CToolTip text={`Purchase the below listed licenses to enable an upgrade to the ${showLicense([license])} license.`}>
                <CButton addClass="primary light disabled" disabled>
                  Upgrade to {showLicense([license], true)}
                </CButton>
              </CToolTip>
            </div>
          );
        }
      } else {
        buttons.push(
          <div key={license}>
              <CButton addClass="primary active own-license">
                {showLicense([license])} License
                </CButton>
          </div>
        );
      }
    });

    if (userLicenses.length === 0) {
      buttons.push(
        <div key={LICENSE.FREE_TRIAL}>
          <CButton addClass="primary active own-license">
            {showLicense([LICENSE.FREE_TRIAL])} License
          </CButton>
        </div>
      );
    }

    return buttons.filter(button => button !== null); // Filter out null values if a license upgrade button is to be hidden
  };

  // const priorityLicenses = [
  //   LICENSE.AGENCY,
  //   LICENSE.ANIMATION,
  //   LICENSE.PROFESSIONAL,
  //   LICENSE.BUSINESS,
  //   LICENSE.STARTER,
  //   LICENSE.FREE_TRIAL
  // ];

  // const getAllLicense = (licenseArray) => {
  //   if (!Array.isArray(licenseArray) || licenseArray.length === 0) {
  //     return LICENSE.FREE_TRIAL;
  //   }

  //   const customSort = (a, b) => {
  //     return priorityLicenses.indexOf(a) - priorityLicenses.indexOf(b);
  //   };

  //   licenseArray.sort(customSort);

  //   return licenseArray.map(license => (
  //     <CButton key={license} addClass="primary active">
  //       {showLicense(license)} License
  //     </CButton>
  //   ));
  // };

  const getTopLicense = (licenseArray) => {
    if (!Array.isArray(licenseArray) || licenseArray.length === 0) {
      return LICENSE.FREE_TRIAL;
    }

    const topLicense = licensePriority.find(license => licenseArray.includes(license));
    return topLicense ? topLicense : LICENSE.FREE_TRIAL;
  };

  return (
    <DashboardLayout
      page="setting"
      caption="Settings"
      message="Welcome to Creativio AI"
    >
      <div id="setting" className="body-container">
        <Slide>
          <h1>
            <span>User</span> Settings
          </h1>
        </Slide>
        {userProfile && (
          <div className="setting-container">
            <div className="profile-area">
              <div className="profile-card">
                <div className="user-info">
                  <div className="user-initial">
                    {(
                      userProfile.profile_img ? (
                        <div className="user-avatar">
                          <img src={userProfile.profile_img} alt="profile" />
                        </div>
                      ) : (
                        <div className="user-avatar">{shortName(user)}</div>
                      )
                    )}
                  </div>
                  <div className="user-detail">
                    <h3>{getFullName(userProfile)}</h3>
                    <span className="mb-10">{userProfile.email}</span>
                    <div className="mt-5 license-detail">
                      {getLicenses(userProfile.license)}
                    </div>
                    {/* {!upgradeLicense ? (
                      <div className="mt-5 license-detail">
                        {getAllLicense(userProfile.license)}
                      </div>
                    ) : (
                      <div className="upgrade-btn">
                        <div className="mt-5 license-detail">
                          {getAllLicense(userProfile.license)}
                        </div>
                        <div className="mt-5">
                          <CButton
                            addClass="primary active"
                            onClick={onUpgradeLicense}
                          >
                            Upgrade To {showLicense(upgradeLicense, true)}
                          </CButton>
                        </div>
                      </div>
                    )} */}
                  </div>
                </div>
              </div>
              <div className="credits-info">
                <h1>
                  <span>Credits</span> Analytics
                </h1>
                <div className="credit-detail">
                  <p className="lbl-txt mt-10">
                    {userProfile.license.includes(LICENSE.PROFESSIONAL) ? (
                      <>Unlimited amount of credits on </>
                    ) : (
                      <>
                        Credits Status: <b>{Math.floor(userProfile.credits)}</b>{" "}
                        Left of{" "}
                      </>
                    )}
                    your {showLicense([getTopLicense(userProfile.license)])} Account
                  </p>
                </div>
              </div>
            </div>
            <div className="tab-area">
              <div className="btn-actions">
                <CButton
                  addClass="primary"
                  active={activePage === "account"}
                  onClick={() => setActivePage("account")}
                >
                  Account
                </CButton>
                <CButton
                  addClass="primary"
                  active={activePage === "integrations"}
                  onClick={() => setActivePage("integrations")}
                >
                  Integrations
                </CButton>
                <CButton
                  addClass="primary"
                  active={activePage === "billing"}
                  onClick={() => setActivePage("billing")}
                >
                  Billing
                </CButton>
              </div>
              {activePage === "account" && (
                <Account
                  profile={userProfile}
                  handleChangePlatform={handleChangePlatform}
                  handleUpdate={updateUser}
                  handleAvatarUpdate={updateUserAvatar}
                  handleChangePrivacy={handleChangePrivacy}
                  handleAvatarRemove={removeUserAvatar}
                />
              )}
              {activePage === "integrations" && (
                <Connects profile={userProfile} />
              )}
              {activePage === "billing" && <Billings />}
            </div>
          </div>
        )}
      </div>
    </DashboardLayout>
  );
}
