import React, { useRef, useEffect, useState } from "react";

export const checkIfInsideCircle = (mouseX, mouseY, cx, cy, radius) => {
  const distanceSquared = (mouseX - cx) ** 2 + (mouseY - cy) ** 2;
  return distanceSquared <= radius ** 2;
};

const ResizableImageCanvas = ({
  imageUrl = null,
  canvasWidth = 512,
  canvasHeight = 512,
  rectOrg = null,
  onUpdateImage = () => { },
}) => {
  const canvasRef = useRef(null);
  const [ctx, setCtx] = useState(null);
  const [img, setImg] = useState(null);
  const [aspectRatio, setAspectRatio] = useState(1);
  const [rect, setRect] = useState(rectOrg);
  const [movement, setMovement] = useState("");
  const [showRect, setShowRect] = useState(false);
  const [prevX, setPrevX] = useState(0);
  const [prevY, setPrevY] = useState(0);

  const getImageData = () => {
    if (ctx) {
      if (showRect) drawImage();
      const canvas = canvasRef.current;
      const dataURL = canvas.toDataURL();
      onUpdateImage(dataURL);
      if (showRect) drawRect();
    }
    return null;
  };

  useEffect(() => {
    const img = new Image();
    img.crossOrigin = "anonymous";
    img.onload = () => setImg(img);
    img.src = imageUrl;
  }, [imageUrl]);

  const drawImage = () => {
    if (!ctx) return;
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
    ctx.save();
    ctx.translate(rect.x + rect.w / 2, rect.y + rect.h / 2);
    ctx.rotate((rect.angle * Math.PI) / 180);
    ctx.drawImage(img, -rect.w / 2, -rect.h / 2, rect.w, rect.h);
    ctx.restore();
    // ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
    // ctx.drawImage(img, rect.x, rect.y, rect.w, rect.h);
  };

  const drawRect = () => {
    if (!ctx) return;
    ctx.beginPath();
    ctx.rect(rect.x, rect.y, rect.w, rect.h);
    ctx.strokeStyle = "#7371fc";
    ctx.lineWidth = 2;
    ctx.stroke();

    // Draw 4 small filled circles at the corners of the rectangle
    const cornerCircleRadius = 6;
    const cornerCirclePositions = [
      { x: rect.x, y: rect.y }, // Top-left corner
      { x: rect.x + rect.w, y: rect.y }, // Top-right corner
      { x: rect.x, y: rect.y + rect.h }, // Bottom-left corner
      { x: rect.x + rect.w, y: rect.y + rect.h }, // Bottom-right corner
    ];
    ctx.fillStyle = "white"; // Fill color for the circles
    cornerCirclePositions.forEach((corner) => {
      ctx.beginPath();
      ctx.arc(corner.x, corner.y, cornerCircleRadius, 0, 2 * Math.PI);
      ctx.fill();
    });

    // Define positions for additional circles outside the existing ones
    const additionalCirclePositions = [
      { x: rect.x - 10, y: rect.y - 10 }, // Top-left
      { x: rect.x + rect.w + 10, y: rect.y - 10 }, // Top-right
      { x: rect.x - 10, y: rect.y + rect.h + 10 }, // Bottom-left
      { x: rect.x + rect.w + 10, y: rect.y + rect.h + 10 }, // Bottom-right
    ];
    // Draw additional circles
    ctx.fillStyle = "white"; // Change color for additional circles
    additionalCirclePositions.forEach((corner) => {
      ctx.beginPath();
      ctx.arc(corner.x, corner.y, cornerCircleRadius, 0, 2 * Math.PI);
      ctx.fill();
    });

        // Draw 4 small filled circles in the middle of the rectangle
        const middleCircleRadius = 6;
        const dtX = rect.w / 2
        const dtY = rect.h / 2
        // const dtX = Math.floor(rect.w / 2)
        // const dtY = Math.floor(rect.h / 2)
        const middleCirclePositions = [
            { x: rect.x + dtX, y: rect.y }, // Top-middle
            { x: rect.x + dtX, y: rect.y + rect.h }, // Bottom-middle
            { x: rect.x, y: rect.y + dtY }, // Left-middle
            { x: rect.x + rect.w, y: rect.y + dtY }, // Right-middle
        ];
        ctx.fillStyle = 'white'; // Fill color for the circles
        middleCirclePositions.forEach((middle) => {
            ctx.beginPath();
            ctx.arc(middle.x, middle.y, middleCircleRadius, 0, 2 * Math.PI);
            ctx.fill();
        });
    setShowRect(true);
  };

  const changeRect = (move, e) => {
    let isShift = false;
    if (e.shiftKey) {
      isShift = true;
    }
    const dx = e.clientX - prevX;
    const dy = e.clientY - prevY;
    let newX = rect.x;
    let newY = rect.y;
    let newW = rect.w;
    let newH = rect.h;

    let deltaX = dx;
    let deltaY = dy;
    deltaY = deltaX * aspectRatio;

    let newAngle = rect.angle || 0; // If angle is not provided, default to 0
    if (isShift) {
      switch (move) {
        case "move":
          newX += dx;
          newY += dy;
          break;
        case "resize_A":
          newX += deltaX;
          newY += deltaX;
          newW -= deltaX;
          newH -= deltaY;
          break;
        case "resize_B":
          newY -= deltaY;
          newW += deltaX;
          newH += deltaY;
          break;
        case "resize_C":
          newW += deltaX;
          newH += deltaY;
          break;
        case "resize_D":
          newX += deltaX;
          newW -= deltaX;
          newH -= deltaY;
          break;
        case "rotate_A":
        case "rotate_B":
        case "rotate_C":
        case "rotate_D":
          const centerX = rect.x + rect.w / 2;
          const centerY = rect.y + rect.h / 2;

          // Calculate the angle of rotation based on mouse movement
          const angleDelta = Math.atan2(dy, dx) - Math.atan2(prevY - centerY, prevX - centerX);
          newAngle += angleDelta;

          // Rotate the rectangle around its center
          const rotatedPoint = rotatePoint(centerX, centerY, centerX, centerY, angleDelta);

          // Calculate new position of the rectangle
          newX = rotatedPoint.x - rect.w / 2;
          newY = rotatedPoint.y - rect.h / 2;
          break;
        default:
          drawImage();
          break;
      }
    } else {
      switch (move) {
        case "move":
          newX += dx;
          newY += dy;
          break;
        case "resize_A":
          newX += dx;
          newY += dy;
          newW -= dx;
          newH -= dy;
          break;
        case "resize_B":
          newY += dy;
          newW += dx;
          newH -= dy;
          break;
        case "resize_C":
          newW += dx;
          newH += dy;
          break;
        case "resize_D":
          newX += dx;
          newW -= dx;
          newH += dy;
          break;
        case "rotate_A":
        case "rotate_B":
        case "rotate_C":
        case "rotate_D":
          const centerX = rect.x + rect.w / 2;
          const centerY = rect.y + rect.h / 2;

          // Calculate the angle of rotation based on mouse movement
          const angleDelta = Math.atan2(dy, dx) - Math.atan2(prevY - centerY, prevX - centerX);
          newAngle += angleDelta;

          // Rotate the rectangle around its center
          const rotatedPoint = rotatePoint(centerX, centerY, centerX, centerY, angleDelta);

          // Calculate new position of the rectangle
          newX = rotatedPoint.x - rect.w / 2;
          newY = rotatedPoint.y - rect.h / 2;
          break;
        case "resize_AB":
          newY += dy;
          newH -= dy;
          break;
        case "resize_BC":
          newW += dx;
          break;
        case "resize_CD":
          newH += dy;
          break;
        case "resize_AD":
          newX += dx;
          newW -= dx;
          break;
        default:
          drawImage();
          break;
      }
    }
    setRect((prevRect) => ({
      ...prevRect,
      x: newX,
      y: newY,
      w: newW,
      h: newH,
      angle: newAngle,
    }));
    setPrevX(e.clientX);
    setPrevY(e.clientY);
  };

  const svgIcon = encodeURIComponent(`
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
        <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
        <g id="SVGRepo_iconCarrier">
          <path d="M4.06189 13C4.02104 12.6724 4 12.3387 4 12C4 7.58172 7.58172 4 12 4C14.5006 4 16.7332 5.14727 18.2002 6.94416M19.9381 11C19.979 11.3276 20 11.6613 20 12C20 16.4183 16.4183 20 12 20C9.61061 20 7.46589 18.9525 6 17.2916M9 17H6V17.2916M18.2002 4V6.94416M18.2002 6.94416V6.99993L15.2002 7M6 20V17.2916" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
        </g>
      </svg>
    `);

  // Function to rotate a point around another point by a given angle
  function rotatePoint(x, y, centerX, centerY, angle) {
    const radians = angle * (Math.PI / 180);
    const cosAngle = Math.cos(radians);
    const sinAngle = Math.sin(radians);
    const newX = (x - centerX) * cosAngle - (y - centerY) * sinAngle + centerX;
    const newY = (x - centerX) * sinAngle + (y - centerY) * cosAngle + centerY;
    return { x: newX, y: newY };
  }

  const checkMovement = (tx, ty) => {
    const cvs1 = canvasRef.current;
    const rectCvs1 = cvs1.getBoundingClientRect();

    let mouseX = Number(tx) - rectCvs1.left;
    let mouseY = Number(ty) - rectCvs1.top;

    // let mouseX = Number(tx) - parentOffsetX
    // let mouseY = Number(ty) - parentOffsetY
    // let middleW = rect.w / 2;
    // let middleH = rect.h / 2;
    let middleW = Math.floor(rect.w/2)
    let middleH = Math.floor(rect.h/2)

    // corner A
    if (checkIfInsideCircle(mouseX, mouseY, rect.x, rect.y, 6)) {
      canvasRef.current.style.cursor = "nwse-resize";
      return "resize_A";
    }
    // corner B
    if (checkIfInsideCircle(mouseX, mouseY, rect.x + rect.w, rect.y, 6)) {
      canvasRef.current.style.cursor = "nesw-resize";
      return "resize_B";
    }
    // corner C
    if (
      checkIfInsideCircle(mouseX, mouseY, rect.x + rect.w, rect.y + rect.h, 6)
    ) {
      canvasRef.current.style.cursor = "nwse-resize";
      return "resize_C";
    }
    // corner D
    if (checkIfInsideCircle(mouseX, mouseY, rect.x, rect.y + rect.h, 6)) {
      canvasRef.current.style.cursor = "nesw-resize";
      return "resize_D";
    }

    // rotate corner A
    if (checkIfInsideCircle(mouseX, mouseY, rect.x - 10, rect.y - 10, 6)) {
      canvasRef.current.style.cursor = `url("data:image/svg+xml;utf8,${svgIcon}") 12 12, auto`;
      // canvasRef.current.style.cursor = "wait";
      return "rotate_A";
    }
    // rotate corner B
    if (checkIfInsideCircle(mouseX, mouseY, rect.x + rect.w + 10, rect.y - 10, 6)) {
      canvasRef.current.style.cursor = `url("data:image/svg+xml;utf8,${svgIcon}") 12 12, auto`;
      // canvasRef.current.style.cursor = "wait";
      return "rotate_B";
    }
    // rotate corner C
    if (
      checkIfInsideCircle(mouseX, mouseY, rect.x + rect.w + 10, rect.y + rect.h + 10, 6)
    ) {
      canvasRef.current.style.cursor = `url("data:image/svg+xml;utf8,${svgIcon}") 12 12, auto`;
      // canvasRef.current.style.cursor = "wait";
      return "rotate_C";
    }
    // rotate corner D
    if (checkIfInsideCircle(mouseX, mouseY, rect.x - 10, rect.y + rect.h + 10, 6)) {
      canvasRef.current.style.cursor = `url("data:image/svg+xml;utf8,${svgIcon}") 12 12, auto`;
      // canvasRef.current.style.cursor = "wait";
      return "rotate_D";
    }

    // corner AB
    if (checkIfInsideCircle(mouseX, mouseY, rect.x + middleW, rect.y, 6)) {
        canvasRef.current.style.cursor = 'ns-resize';
        return 'resize_AB'
    }
    // corner CD
    if (checkIfInsideCircle(mouseX, mouseY, rect.x + middleW, rect.y + rect.h, 6)) {
        canvasRef.current.style.cursor = 'ns-resize';
        return 'resize_CD'
    }
    // corner BC
    if (checkIfInsideCircle(mouseX, mouseY, rect.x + rect.w, rect.y + middleH, 6)) {
        canvasRef.current.style.cursor = 'ew-resize';
        return 'resize_BC'
    }
    // corner AD
    if (checkIfInsideCircle(mouseX, mouseY, rect.x, rect.y + middleH, 6)) {
        canvasRef.current.style.cursor = 'ew-resize';
        return 'resize_AD'
    }
    return "";
  };
  const isInsideRect = (tx, ty) => {
    const cvs2 = canvasRef.current;
    const rectCvs2 = cvs2.getBoundingClientRect();

    let x = Number(tx) - rectCvs2.left;
    let y = Number(ty) - rectCvs2.top;
    if (
      x >= rect.x && // Check if x is greater than or equal to the left boundary
      x <= rect.x + rect.w && // Check if x is less than or equal to the right boundary
      y >= rect.y && // Check if y is greater than or equal to the top boundary
      y <= rect.y + rect.h // Check if y is less than or equal to the bottom boundary
    ) {
      return true; // Mouse click is inside the rectangle
    } else {
      return false; // Mouse click is outside the rectangle
    }
  };
  const handleMouseDown = (e) => {
    if (showRect) {
      let move = checkMovement(e.clientX, e.clientY);
      if (move !== "") {
        drawRect();
        setShowRect(true);
        setMovement(move);
      } else {
        if (isInsideRect(e.clientX, e.clientY)) {
          drawRect();
          setShowRect(true);
          setMovement("move");
        } else {
          setShowRect(false);
          drawImage();
          setMovement("");
        }
      }
      setPrevX(e.clientX);
      setPrevY(e.clientY);
    } else if (isInsideRect(e.clientX, e.clientY)) {
      setPrevX(e.clientX);
      setPrevY(e.clientY);
      drawRect();
      setShowRect(true);
      setMovement("move");
    } else {
      setShowRect(false);
      drawImage();
      setMovement("");
    }
  };

  const handleMouseUp = (e) => {
    setMovement("");
    getImageData();
  };

  const handleMouseMove = (e) => {
    let chkCorner = checkMovement(e.clientX, e.clientY);
    if (chkCorner === "") {
      if (isInsideRect(e.clientX, e.clientY)) {
        // change mouse cursor to movement
        canvasRef.current.style.cursor = "move";
      } else {
        canvasRef.current.style.cursor = "default";
      }
    }

    let move = movement;
    if (move !== "") {
      changeRect(move, e);
    }

    if (!showRect) {
      drawRect();
      setShowRect(true);
    }
  };

  const handleMouseLeave = (e) => {
    setShowRect(false);
    drawImage();
    setMovement("");
    const canvas = canvasRef.current;
    const dataURL = canvas.toDataURL();
    onUpdateImage(dataURL);
  }

  useEffect(() => {
    if (img && ctx) {
      drawImage();
      // drawRect();
      setAspectRatio(rect.w / rect.h);
      getImageData();
    }
  }, [img, rect]);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctxOrigin = canvas.getContext("2d");
    setAspectRatio(rectOrg.w / rectOrg.h);
    setCtx(ctxOrigin);
  }, []);

  return (
    <canvas
      ref={canvasRef}
      width={canvasWidth}
      height={canvasHeight}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseLeave}
    />
  );
};

export default ResizableImageCanvas;