import React, {
  useContext,
  useEffect,
  useState,
  useCallback,
  useMemo,
} from "react";

import { LayoutContext } from "components/contexts/LayoutContextContainer";
import InfoCard from "components/molecules/admin/InfoCard";
// import RealtimeTerminal from "components/molecules/admin/RealtimeTerminal";
import { Slide } from "react-awesome-reveal";
import { getApi } from "utils/customNetwork";
import { AdminLayout } from "components/layouts/AdminLayout";

import { ReactComponent as ImageSvg } from "assets/imgs/admin/logs/image.svg";
// import ImageGenerations from "components/molecules/admin/ImageGenerations";
import LogsCharts from "components/molecules/admin/LogsCharts";
import { CommonTable } from "components/Table";
// import { ICONS } from "assets";
import { Box, Tooltip } from "@mui/material";
import { ReactComponent as EyeIcon } from "assets/imgs/admin/logs/eye.svg";
import { ReactComponent as ImageIcon } from "assets/imgs/admin/logs/image.svg";

const LIMIT = 15;

const getTotalNumber = (data = { success: 0, failure: 0 }) => {
  return +data.success + +data.failure;
};

const Logs = () => {
  const { checkToken, showNoti } = useContext(LayoutContext);
  const [terminal, setTerminal] = useState({
    page: 1,
    total: 0,
    data: [],
  });

  const [imageLogs, setImageLogs] = useState({
    page: 1,
    total: 0,
    data: [],
  });

  const [realtimeTerminalLogs, setRealtimeTerminalLogs] = useState([]);

  const [statistics, setStatistics] = useState({});

  const [loading, setLoading] = useState(false);

  // const onLoadImageLogs = async () => {
  //   await onGetData(imageLogs.page + 1).then((result) => {
  //     if (!result) return;
  //     setImageLogs((p) => ({
  //       data: [...p.data, ...result.data],
  //       total: result.meta?.total,
  //       page: p.page + 1,
  //     }));
  //   });
  // };

  // const onLoadTerminalLogs = async () => {
  //   await onGetData(terminal.page + 1).then((result) => {
  //     if (!result) return;
  //     setTerminal((p) => ({
  //       data: [...p.data, ...result.data],
  //       total: result.meta?.total,
  //       page: p.page + 1,
  //     }));
  //   });
  // };

  const hasMoreImageLogs = useMemo(() => {
    return imageLogs.total > imageLogs.data.length;
  }, [imageLogs]);
  const hasMoreTerminalLogs = useMemo(() => {
    return terminal.total > terminal.data.length;
  }, [terminal]);

  // const onGetData = useCallback(
  //   async (page) => {
  //     let result = null;
  //     try {
  //       checkToken();
  //       const data = await getApi(
  //         `/admin/service/logs/list?perPage=${LIMIT}&page=${page}`,
  //         "GET"
  //       );
  //       result = data;
  //     } catch (e) {
  //       showNoti("error", e);
  //     }
  //     return result;
  //   },
  //   [checkToken, showNoti]
  // );

  const onGetStatistics = useCallback(async () => {
    setLoading(true);
    try {
      checkToken();
      const data = await getApi("/admin/service/logs/statistic", "GET");
      setStatistics(data.data);
    } catch (e) {
      showNoti("error", e);
    } finally {
      setLoading(false);
    }
  }, [checkToken, showNoti]);

  // useEffect(() => {
  //   onGetData(1).then((result) => {
  //     if (!result) return;

  //     setImageLogs((p) => ({
  //       ...p,
  //       data: result.data,
  //       total: result.meta.total,
  //     }));
  //   });

  //   onGetData(1).then((result) => {
  //     if (!result) return;

  //     setTerminal((p) => ({
  //       ...p,
  //       data: result.data,
  //       total: result.meta.total,
  //     }));
  //   });

  //   onGetStatistics();
  // }, [onGetData, onGetStatistics]);

  useEffect(() => {
    onGetStatistics();
  }, [onGetStatistics]);

  const formatter = (value) => {
    if (typeof value === "object" && value) {
      return JSON.stringify(value);
    }

    return value;
  };

  const ActionsContainer = ({ item }) => {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
        }}
      >
        <Tooltip title="View Payload">
          <EyeIcon
            onClick={() => {
              if (item.originalImages) {
                const image = item.originalImages[0];
                window.open(image, "_blank");
              }
            }}
            style={{ width: 24, height: 24, cursor: "pointer" }}
          />
        </Tooltip>

        <Tooltip title="View Image">
          <ImageIcon
            onClick={() => {
              if (item.generateImages) {
                const image = item.generateImages[0];
                window.open(image, "_blank");
              }
            }}
            style={{ width: 20, height: 20, cursor: "pointer" }}
          />
        </Tooltip>
      </Box>
    );
  };

  const imageGenerationsColumns = [
    {
      id: "createdAt",
      fieldName: "createdAt",
      label: "Time",
      sorting: false,
      align: "center",
      cellStyle: {
        minWidth: 180,
      },
    },
    {
      id: "parameters",
      fieldName: "parameters",
      label: "Payload",
      render: ({ rowData }) => formatter(rowData.parameters),
      align: "center",
      sorting: false,
    },
    {
      id: "platform",
      fieldName: "platform",
      label: "Engine",
      align: "center",
      sorting: false,
    },

    {
      id: "credits",
      fieldName: "credits",
      label: "Credits",
      align: "center",
      sorting: false,
    },

    {
      id: "status",
      fieldName: "status",
      label: "Status",
      align: "center",
      sorting: false,
    },
    {
      id: "id",
      fieldName: "id",
      label: "Actions",
      align: "center",
      render: (row) => {
        return <ActionsContainer item={row.rowData} />;
      },
      cellStyle: {
        minWidth: 180,
      },
      sorting: false,
    },
  ];

  const fetchData = async (params) => {
    try {
      checkToken();
      const data = await getApi(`/admin/service/logs/list`, "GET", null, {
        ...params?.params,
      });
      setImageLogs(data);
    } catch (e) {
      showNoti("error", e);
    }
  };

  const getCodeStyles = (code) => {
    const formatCode = +code;

    if (formatCode >= 200 && formatCode <= 299) {
      return "code-success";
    }

    if (formatCode >= 400 && formatCode <= 499) {
      return "code-warning";
    }

    if (formatCode >= 500 && formatCode <= 599) {
      return "code-error";
    }
  };

  const getCodeLabel = (code) => {
    const formatCode = +code;

    if (formatCode >= 200 && formatCode <= 299) {
      return "Success";
    }

    if (formatCode >= 400 && formatCode <= 499) {
      return "Failed";
    }

    if (formatCode >= 500 && formatCode <= 599) {
      return "Server error";
    }
  };

  const formatEntryValue = (value) => {
    if (typeof value === "object" && value) {
      return JSON.stringify(value);
    }
    return value;
  };

  const realtimeTerminalColumns = [
    {
      id: "real_time_logs",
      fieldName: "real_time_logs",
      label: "Realtime Terminal Logs",
      render: ({ rowData }) => {
        const entries = Object.entries(rowData);
        return (
          <React.Fragment>
            <ul
              className="realtime-terminal__inner-list"
              style={{ listStyleType: "none", padding: 0 }}
            >
              {entries.map(([name, value], index) => {
                return (
                  <li
                    key={index}
                    style={{
                      textAlign: "left",
                      listStyleType: "none",
                      marginBottom: "8px",
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <span
                        className="realtime-terminal__title"
                        style={{ color: "#7371fc", marginRight: "8px" }}
                      >
                        {name}:
                      </span>
                      <p
                        className={`realtime-terminal__value ${
                          name === "status" ? getCodeStyles(value) : ""
                        }`}
                        style={{ margin: 0 }}
                      >
                        {formatEntryValue(value)}{" "}
                        {name === "status" && getCodeLabel(value)}
                      </p>
                    </div>
                  </li>
                );
              })}
            </ul>
            <div
              className="realtime-terminal__divider"
              style={{ textAlign: "left" }}
            >
              ------------------- End Call --------------------
            </div>
          </React.Fragment>
        );
      },
      align: "center",
      sorting: false,
    },
  ];

  const fetchDataRealTerminalLogs = async (params) => {
    try {
      checkToken();
      const data = await getApi(`/admin/service/logs/list`, "GET", null, {
        ...params?.params,
      });
      setRealtimeTerminalLogs(data);
    } catch (e) {
      showNoti("error", e);
    }
  };

  return (
    <AdminLayout page="logs" message="API Logs page">
      <div className="admin-dashboard body-container">
        <div className="head">
          <Slide className="flex1">
            <h1>
              <span>API</span> Logs
            </h1>
          </Slide>
        </div>
        <div className="card-area mt-15 mb-15">
          <InfoCard
            title="API Calls"
            Icon={ImageSvg}
            value={getTotalNumber(statistics.totalApiCalls)}
          />
          <InfoCard
            title="Photos Generated"
            Icon={ImageSvg}
            value={getTotalNumber(statistics.photosGenerated)}
          />
          <InfoCard
            title="Product Photos"
            Icon={ImageSvg}
            value={getTotalNumber(statistics.productPhotos)}
          />
          <InfoCard
            title="Text to Image"
            Icon={ImageSvg}
            value={getTotalNumber(statistics.testToImage)}
          />
          <InfoCard
            title="AI Studio Editor"
            Icon={ImageSvg}
            value={getTotalNumber(statistics.aiStudioEditor)}
          />
        </div>
        <LogsCharts statistics={statistics} />
        {/* <ImageGenerations
          data={{
            data: imageLogs.data,
            hasMore: hasMoreImageLogs,
            onLoad: onLoadImageLogs,
          }}
        /> */}
        <Box>
          <Slide className="flex1">
            <h1>
              <span>Image Generation</span> Logs
            </h1>
          </Slide>
          <CommonTable
            columns={imageGenerationsColumns}
            rows={imageLogs?.data}
            apiPagination={true}
            count={imageLogs?.meta?.total}
            fetchData={fetchData}
            apiPageInfo={imageLogs?.meta}
            loading={loading}
          />
        </Box>

        <Box sx={{ marginTop: "2rem" }}>
          <Slide className="flex1">
            <h1>
              <span>Realtime</span> Terminal Logs
            </h1>
          </Slide>
          <CommonTable
            columns={realtimeTerminalColumns}
            rows={realtimeTerminalLogs?.data}
            apiPagination={true}
            count={realtimeTerminalLogs?.meta?.total}
            fetchData={fetchDataRealTerminalLogs}
            apiPageInfo={realtimeTerminalLogs?.meta}
            loading={loading}
          />
          {/* <RealtimeTerminal
            data={{
              data: terminal.data,
              hasMore: hasMoreTerminalLogs,
              onLoad: onLoadTerminalLogs,
            }}
          /> */}
        </Box>
      </div>
    </AdminLayout>
  );
};

export default Logs;
