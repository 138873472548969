import CloseIcon from "@mui/icons-material/Close";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SpaceDashboardIcon from "@mui/icons-material/SpaceDashboard";
import MenuIcon from "@mui/icons-material/Menu";
import SubjectIcon from "@mui/icons-material/Subject";
import AutoAwesomeMosaicIcon from "@mui/icons-material/AutoAwesomeMosaic";
import MoreVertSharpIcon from "@mui/icons-material/MoreVertSharp";
import ArrowDropUp from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { ReactComponent as ResetLoop } from "../assets/images/arrow-repeat.svg";
import { ReactComponent as Trash } from "../assets/images/trash.svg";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import SearchIcon from "@mui/icons-material/Search";

export const ICONS = {
  Close: CloseIcon,
  InfoOutlinedIcon: InfoOutlinedIcon,
  Eye: VisibilityIcon,
  EyeOff: VisibilityOff,
  Email: MailOutlineIcon,
  Edit: EditIcon,
  Delete: DeleteIcon,
  Dashboard: SpaceDashboardIcon,
  Menu: MenuIcon,
  Subject: SubjectIcon,
  Section: AutoAwesomeMosaicIcon,
  MoreVertSharpIcon: MoreVertSharpIcon,
  ArrowDropDownIcon: ArrowDropDownIcon,
  ArrowDropUp: ArrowDropUp,
  CheckCircleIcon: CheckCircleIcon,
  CancelIcon: CancelIcon,
  ResetLoop: ResetLoop,
  Trash: Trash,
  DragIndicatorIcon: DragIndicatorIcon,
  FilterAltIcon: FilterAltIcon,
  Search: SearchIcon,
  ArrowDown: ArrowDropDownIcon,
};
