import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getApi } from "utils/customNetwork";
import { CButton } from "components/atoms/CButton";
import { CInput } from "components/atoms/CInput";
import { CDropdown } from "components/atoms/CDropdown";
import { LayoutContext } from "components/contexts/LayoutContextContainer";
import { getOffsetX, getOffsetY, getScale, getSelectedItem } from "store/utils";
import useHistoryDispatch from "hooks/history/useHistoryStudio";
import { DRAW_OBJECT_TYPES } from "constants/studio";
import { varColors } from "pages/ColorChanger/variable";

import "react-fontpicker-ts-lite/dist/index.css";
import "./index.scss";

const ColorChangePopup = () => {
  const dispatch = useDispatch();
  const { historyLayer } = useHistoryDispatch();
  const { showNoti, setLoading } = useContext(LayoutContext);
  const selectedItem = useSelector(getSelectedItem);
  const scale = useSelector(getScale);
  const offsetX = useSelector(getOffsetX);
  const offsetY = useSelector(getOffsetY);
  const [searchPrompt, setSearchPrompt] = useState("");
  const [colorOptions, setColorOptions] = useState(varColors);

  const getImageObject = () => {
    let imageObject = null;
    if (selectedItem.type === DRAW_OBJECT_TYPES.image) {
      imageObject = selectedItem;
    } else if (selectedItem.type === DRAW_OBJECT_TYPES.group) {
      const objects = selectedItem.getObjects ? selectedItem.getObjects() : [];
      imageObject = objects.find(
        (item) => item.type === DRAW_OBJECT_TYPES.image
      );
    }

    return imageObject;
  };

  const handleOptRatio = (id, index, value) => {
    switch (id) {
      case "color-change":
        const c1 = [...colorOptions];
        c1.forEach((el) => (el.checked = false));
        c1[index].checked = value;
        setColorOptions(c1);
        break;
    }
  };

  const getActiveMenu = (arr) => {
    const chk = arr.find((k) => k.checked);
    if (chk) return chk;
    else return arr[0];
  };

  const onColorChange = async () => {
    historyLayer();

    if (!searchPrompt.trim()) {
      showNoti("error", "Please enter the search prompt");
      return;
    }

    if (!selectedItem) {
      showNoti("error", "Please select a picture");
      return;
    }

    try {
      const selectedColor = getActiveMenu(colorOptions).id;
      const formData = new FormData();

      const imageObject = getImageObject();
      if (!imageObject || imageObject.type !== DRAW_OBJECT_TYPES.image) {
        showNoti("error", "Please select a picture");
        return;
      }

      if (imageObject.imageType === "upload") {
        formData.append("type", "upload");
        formData.append("image", imageObject.data?.file);
      } else {
        formData.append("type", "url");
        formData.append("url", imageObject.data?.url);
      }

      formData.append("mode", "search");
      formData.append("color", selectedColor);
      formData.append("width", imageObject.width);
      formData.append("height", imageObject.height);
      formData.append("search_prompt", searchPrompt);

      setLoading(true);
      const data = await getApi("/generate/changeColor", "POST", formData);
      const img = new Image();
      img.crossOrigin = "anonymous";
      img.onload = function () {
        imageObject.setImg(img);
        imageObject.setImageType("url");
        imageObject.setData({ url: data.data?.image });

        imageObject.clearContext();
        imageObject.draw({ scale, offsetX, offsetY });
        imageObject.drawResizeRect({ scale, offsetX, offsetY });
      };
      img.src = data.data?.image;
    } catch (err) {
      showNoti("error", err);
    }
    setLoading(false);
  };

  useEffect(() => {
    // Event handler for detecting focus on <CInput>
    function handleFocus() {
      // Prevent shortcut key events from being triggered
      document.addEventListener("keydown", stopEventPropagation);
    }

    // Event handler for detecting loss of focus on <CInput>
    function handleBlur() {
      // Remove event listener to prevent propagation when not focused
      document.removeEventListener("keydown", stopEventPropagation);
    }

    // Event handler to stop event propagation
    function stopEventPropagation(event) {
      event.stopPropagation();
    }

    const inputElement = document.getElementById("search-object");
    if (inputElement) {
      inputElement.addEventListener("focus", handleFocus);
      inputElement.addEventListener("blur", handleBlur);
    }

    return () => {
      // Cleanup function to remove event listeners
      if (inputElement) {
        inputElement.removeEventListener("focus", handleFocus);
        inputElement.removeEventListener("blur", handleBlur);
      }
    };
  }, []);

  return (
    <div className="color-change-popup">
      <div className="color-change-popup__flex-content">
        <div className="color-change-popup__inner-div">
          <label htmlFor="search-object">Search Prompt</label>
          <CInput
            id="search-object"
            value={searchPrompt}
            onChange={(e) => setSearchPrompt(e.target.value)}
            addClass="product"
          />
        </div>
        <div className="color-change-popup__inner-div">
          <label htmlFor="color-change">Select Color</label>
          <CDropdown
            id="color-change"
            addClass="product full"
            action={handleOptRatio}
            activeMenu={getActiveMenu(colorOptions)}
            menu={colorOptions}
          />
        </div>
      </div>
      <CButton
        addClass="color-change-popup__button full secondary ml-5 py-10"
        onClick={onColorChange}
        size="medium"
      >
        Change Color
      </CButton>
    </div>
  );
};

export default ColorChangePopup;
