import { AdminLayout } from "components/layouts/AdminLayout";
import { getApi } from "utils/customNetwork";
import { Tabs } from "./tabs";
import { useState, useContext } from "react";
import { Slide } from "react-awesome-reveal";
import { AuthenticationModal } from "components/atoms/Modal/AuthenticationModal";
import { LayoutContext } from "components/contexts/LayoutContextContainer";
import "./styles.scss";
import { decryptObjectValues, encryptObjectValues } from "./encryptor";

export function AdminSettings() {
  const { showNoti, checkToken } = useContext(LayoutContext);
  const [formData, setFormData] = useState({
    mail: {
      mail_mailer: "",
      mail_host: "",
      mail_port: "",
      mail_username: "",
      mail_password: "",
      mail_encryption: "",
      mail_from_address: "",
      mailgun_domain: "",
      mailgun_api_key: "",
      mailgun_secret: "",
    },
    chat_gpt: {
      chatgpt_api: "",
      chatgpt_api_key: "",
    },
    stability: {
      stability_api_base_url: "",
      stability_api_key: "",
      stability_ai_engine: "",
      stability_api_v2_alpha_base_url: "",
      stability_api_stable_diffusion_3_base_url: "",
    },
    creativio: {
      creativio_service_base_url: "",
      creativio_service_base_url_local: "",
    },
    google_cloud: {
      google_cloud_project_id: "",
      google_cloud_storage_bucket: "",
      google_cloud_storage_path_prefix: "",
    },
    others: {
      proof_api_url: "",
      clipdrop_api_url: "",
      clipdrop_api_key: "",
      paddle_api_url: "",
      paddle_api_key: "",
      clickbank_api_secret: "",
      convertkit_form_id: "",
      convertkit_api_key: "",
      convertkit_api_secret_key: "",
    },
  });
  const [isModalOpen, setModalOpen] = useState(true);
  const [isAuthorized, setAuthorized] = useState(false);
  const handleChange = (e) => {
    const { name, value } = e.target;
    const keys = name.split(".");
    if (keys.length > 1) {
      setFormData((prevData) => {
        const newData = { ...prevData };
        let currentLevel = newData;
        keys.forEach((key, index) => {
          if (index === keys.length - 1) {
            currentLevel[key] = value;
          } else {
            currentLevel[key] = currentLevel[key] || {};
            currentLevel = currentLevel[key];
          }
        });
        return newData;
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await getApi("/admin/submit-api-form", "POST", {
        formData: encryptObjectValues(formData),
      });
      if (response?.formData) {
        setFormData(decryptObjectValues(response?.formData));
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const handleModalSubmit = async (e, password) => {
    e.preventDefault();

    try {
      checkToken();
      const response = await getApi("/admin/check-admin-password", "POST", {
        password: password,
      });
      const data = response;
      const STATIC_PASSWORD = data.password;
      if (password === STATIC_PASSWORD) {
        setAuthorized(true);
        setModalOpen(false);
        try {
          const response = await getApi("/admin/fetch-api-form");
          if (response) {
            setFormData((prevFormData) => {
              const mergedData = { ...prevFormData };
              const categories = Object.keys(response);
              for (let i = 0; i < categories.length; i++) {
                const category = categories[i];
                if (
                  response[category] &&
                  typeof response[category] === "object"
                ) {
                  const keys = Object.keys(response[category]);
                  for (let j = 0; j < keys.length; j++) {
                    const key = keys[j];
                    mergedData[category][key] = Boolean(response[category][key])
                      ? response[category][key]
                      : prevFormData[category][key];
                  }
                }
              }
              return decryptObjectValues(mergedData);
            });
          }
        } catch (error) {
          console.error("Error calling API on unmount:", error);
        }
      } else {
        setAuthorized(false);
        showNoti("error", "Incorrect password!");
      }
    } catch (error) {
      showNoti("error", "An error occurred while fetching the password.");
    }
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };
  const tabs = [
    {
      label: "Mail Setting",
      content: isAuthorized ? (
        <div className="mail-settings settings">
          <h4>General Settings(Send mail)</h4>
          <form onSubmit={handleSubmit}>
            <div className="form-group api-setting">
              <label htmlFor="mail_mailer">
                Mail Mailer:
              </label>
              <div className="input-with-icon mt-5">
                <input
                  type="text"
                  id="mail_mailer"
                  name="mail.mail_mailer"
                  placeholder="Mail Driver / Mail Mailer"
                  value={formData.mail.mail_mailer}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="form-group api-setting">
              <label htmlFor="mail_host">Mail Host:</label>
              <div className="input-with-icon mt-5">
                <input
                  type="text"
                  id="mail_host"
                  name="mail.mail_host"
                  placeholder="Mail Host"
                  value={formData.mail.mail_host}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="form-group api-setting">
              <label htmlFor="mail_port">Mail Port:</label>
              <div className="input-with-icon mt-5">
                <input
                  type="text"
                  id="mail_port"
                  name="mail.mail_port"
                  placeholder="Mail Port"
                  value={formData.mail.mail_port}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="form-group api-setting">
              <label htmlFor="mail_username">Mail Username:</label>
              <div className="input-with-icon mt-5">
                <input
                  type="text"
                  id="mail_username"
                  name="mail.mail_username"
                  placeholder="Mail Username"
                  value={formData.mail.mail_username}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="form-group api-setting">
              <label htmlFor="mail_password">Mail Password:</label>
              <div className="input-with-icon mt-5">
                <input
                  type="text"
                  id="mail_password"
                  name="mail.mail_password"
                  placeholder="Mail Password"
                  value={formData.mail.mail_password}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="form-group api-setting">
              <label htmlFor="mail_encryption">Mail Encryption:</label>
              <div className="input-with-icon mt-5">
                <input
                  type="text"
                  id="mail_encryption"
                  name="mail.mail_encryption"
                  placeholder="Mail Encryption"
                  value={formData.mail.mail_encryption}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="form-group api-setting">
              <label htmlFor="mail_from_address">Mail From Address:</label>
              <div className="input-with-icon mt-5">
                <input
                  type="text"
                  id="mail_from_address"
                  name="mail.mail_from_address"
                  placeholder="Mail From Address"
                  value={formData.mail.mail_from_address}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="form-group api-setting">
              <label htmlFor="mailgun_domain">Mailgun Domain:</label>
              <div className="input-with-icon mt-5">
                <input
                  type="text"
                  id="mailgun_domain"
                  name="mail.mailgun_domain"
                  placeholder="Mailgun Domain"
                  value={formData.mail.mailgun_domain}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="form-group api-setting">
              <label htmlFor="mailgun_api_key">Mailgun API Key:</label>
              <div className="input-with-icon mt-5">
                <input
                  type="text"
                  id="mailgun_api_key"
                  name="mail.mailgun_api_key"
                  placeholder="Mailgun API Key"
                  value={formData.mail.mailgun_api_key}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="form-group api-setting">
              <label htmlFor="mailgun_secret">Mailgun Secret:</label>
              <div className="input-with-icon mt-5">
                <input
                  type="text"
                  id="mailgun_secret"
                  name="mail.mailgun_secret"
                  placeholder="Mailgun Secret"
                  value={formData.mail.mailgun_secret}
                  onChange={handleChange}
                />
              </div>
            </div>
            <button className="custom-button primary active" type="submit">
              Submit
            </button>
          </form>
        </div>
      ) : null,
    },
    {
      label: "Chat GPT",
      content: isAuthorized ? (
        <div className="chat-gpt-settings settings">
          <h4>Chat GPT Settings</h4>
          <form onSubmit={handleSubmit}>
            <div className="form-group api-setting">
              <label htmlFor="chatgpt_api">Chat GPT API URL:</label>
              <div className="input-with-icon mt-5">
                <input
                  type="text"
                  id="chatgpt_api"
                  name="chat_gpt.chatgpt_api"
                  placeholder="Chat GPT API URL"
                  value={formData.chat_gpt.chatgpt_api}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="form-group api-setting">
              <label htmlFor="chatgpt_api_key">Chat GPT API Key:</label>
              <div className="input-with-icon mt-5">
                <input
                  type="text"
                  id="chatgpt_api_key"
                  name="chat_gpt.chatgpt_api_key"
                  placeholder="Chat GPT API Key"
                  value={formData.chat_gpt.chatgpt_api_key}
                  onChange={handleChange}
                />
              </div>
            </div>
            <button className="custom-button primary active" type="submit">
              Submit
            </button>
          </form>
        </div>
      ) : null,
    },
    {
      label: "Stability",
      content: isAuthorized ? (
        <div className="stability-settings settings">
          <h4>Stability Settings</h4>
          <form onSubmit={handleSubmit}>
            <div className="form-group api-setting">
              <label htmlFor="stability_api_base_url">
                Stability API Base URL:
              </label>
              <div className="input-with-icon mt-5">
                <input
                  type="text"
                  id="stability_api_base_url"
                  name="stability.stability_api_base_url"
                  placeholder="Stability API Base URL"
                  value={formData.stability.stability_api_base_url}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="form-group api-setting">
              <label htmlFor="stability_api_stable_diffusion_3_base_url">
                Stable Diffusion 3 Base URL:
              </label>
              <div className="input-with-icon mt-5">
                <input
                  type="text"
                  id="stability_api_stable_diffusion_3_base_url"
                  name="stability.stability_api_stable_diffusion_3_base_url"
                  placeholder="Stable Diffusion 3 Base URL"
                  value={formData.stability.stability_api_stable_diffusion_3_base_url}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="form-group api-setting">
              <label htmlFor="stability_api_key">Stability API Key:</label>
              <div className="input-with-icon mt-5">
                <input
                  type="text"
                  id="stability_api_key"
                  name="stability.stability_api_key"
                  placeholder="Stability API Key"
                  value={formData.stability.stability_api_key}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="form-group api-setting">
              <label htmlFor="stability_ai_engine">Stability AI Engine:</label>
              <div className="input-with-icon mt-5">
                <input
                  type="text"
                  id="stability_ai_engine"
                  name="stability.stability_ai_engine"
                  placeholder="Stability AI Engine"
                  value={formData.stability.stability_ai_engine}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="form-group api-setting">
              <label htmlFor="stability_api_v2_alpha_base_url">
                Stability V2 Alpha Base URL:
              </label>
              <div className="input-with-icon mt-5">
                <input
                  type="text"
                  id="stability_api_v2_alpha_base_url"
                  name="stability.stability_api_v2_alpha_base_url"
                  placeholder="Stability V2 Alpha Base URL"
                  value={formData.stability.stability_api_v2_alpha_base_url}
                  onChange={handleChange}
                />
              </div>
            </div>
            <button className="custom-button primary active" type="submit">
              Submit
            </button>
          </form>
        </div>
      ) : null,
    },
    {
      label: "Creativio",
      content: isAuthorized ? (
        <div className="creativio-settings settings">
          <h4>Creativio Settings</h4>
          <form onSubmit={handleSubmit}>
            <div className="form-group api-setting">
              <label htmlFor="creativio_service_base_url">
                Creativio Service Base URL:
              </label>
              <div className="input-with-icon mt-5">
                <input
                  type="text"
                  id="creativio_service_base_url"
                  name="creativio.creativio_service_base_url"
                  placeholder="Creativio Service Base URL"
                  value={formData.creativio.creativio_service_base_url}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="form-group api-setting">
              <label htmlFor="creativio_service_base_url_local">
                Creativio Local Service Base URL:
              </label>
              <div className="input-with-icon mt-5">
                <input
                  type="text"
                  id="creativio_service_base_url_local"
                  name="creativio.creativio_service_base_url_local"
                  placeholder="Creativio Local Service Base URL"
                  value={formData.creativio.creativio_service_base_url_local}
                  onChange={handleChange}
                />
              </div>
            </div>
            <button className="custom-button primary active" type="submit">
              Submit
            </button>
          </form>
        </div>
      ) : null,
    },
    {
      label: "Google Cloud",
      content: isAuthorized ? (
        <div className="google-cloud-settings settings">
          <h4>Payment Settings</h4>
          <form onSubmit={handleSubmit}>
            <div className="form-group api-setting">
              <label htmlFor="google_cloud_project_id">
                Google Cloud Product ID:
              </label>
              <div className="input-with-icon mt-5">
                <input
                  type="text"
                  id="google_cloud_project_id"
                  name="google_cloud.google_cloud_project_id"
                  placeholder="Google Cloud Product ID"
                  value={formData.google_cloud.google_cloud_project_id}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="form-group api-setting">
              <label htmlFor="google_cloud_storage_bucket">
                Google Cloud Storage Bucket:
              </label>
              <div className="input-with-icon mt-5">
                <input
                  type="text"
                  id="google_cloud_storage_bucket"
                  name="google_cloud.google_cloud_storage_bucket"
                  placeholder="Google Cloud Storage Bucket"
                  value={formData.google_cloud.google_cloud_storage_bucket}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="form-group api-setting">
              <label htmlFor="google_cloud_storage_path_prefix">
                Google Cloud Storage Path Prefix:
              </label>
              <div className="input-with-icon mt-5">
                <input
                  type="text"
                  id="google_cloud_storage_path_prefix"
                  name="google_cloud.google_cloud_storage_path_prefix"
                  placeholder="Google Cloud Storage Path Prefix"
                  value={formData.google_cloud.google_cloud_storage_path_prefix}
                  onChange={handleChange}
                />
              </div>
            </div>
            <button className="custom-button primary active" type="submit">
              Submit
            </button>
          </form>
        </div>
      ) : null,
    },
    {
      label: "Other / Payment",
      content: isAuthorized ? (
        <div className="payment-settings settings">
          <h4>Payment Settings</h4>
          <form onSubmit={handleSubmit}>
            <div className="form-group api-setting">
              <label htmlFor="proof_api_url">Proof API URL:</label>
              <div className="input-with-icon mt-5">
                <input
                  type="text"
                  id="proof_api_url"
                  name="others.proof_api_url"
                  placeholder="Proof API URL"
                  value={formData.others.proof_api_url}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="form-group api-setting">
              <label htmlFor="clipdrop_api_key">Clipdrop API Key:</label>
              <div className="input-with-icon mt-5">
                <input
                  type="text"
                  id="clipdrop_api_key"
                  name="others.clipdrop_api_key"
                  placeholder="Clipdrop API Key"
                  value={formData.others.clipdrop_api_key}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="form-group api-setting">
              <label htmlFor="clipdrop_api_url">Clipdrop API URL:</label>
              <div className="input-with-icon mt-5">
                <input
                  type="text"
                  id="clipdrop_api_url"
                  name="others.clipdrop_api_url"
                  placeholder="Clipdrop API URL"
                  value={formData.others.clipdrop_api_url}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="form-group api-setting">
              <label htmlFor="paddle_api_url">Paddle API URL:</label>
              <div className="input-with-icon mt-5">
                <input
                  type="text"
                  id="paddle_api_url"
                  name="others.paddle_api_url"
                  placeholder="Paddle API Key"
                  value={formData.others.paddle_api_url}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="form-group api-setting">
              <label htmlFor="paddle_api_key">Paddle API Key:</label>
              <div className="input-with-icon mt-5">
                <input
                  type="text"
                  id="paddle_api_key"
                  name="others.paddle_api_key"
                  placeholder="Paddle API Key"
                  value={formData.others.paddle_api_key}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="form-group api-setting">
              <label htmlFor="clickbank_api_secret">Clickbank API Key:</label>
              <div className="input-with-icon mt-5">
                <input
                  type="text"
                  id="clickbank_api_secret"
                  name="others.clickbank_api_secret"
                  placeholder="Clickbank API Key"
                  value={formData.others.clickbank_api_secret}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="form-group api-setting">
              <label htmlFor="convertkit_api_key">Convertkit API Key:</label>
              <div className="input-with-icon mt-5">
                <input
                  type="text"
                  id="convertkit_api_key"
                  name="others.convertkit_api_key"
                  placeholder="Convertkit API Key"
                  value={formData.others.convertkit_api_key}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="form-group api-setting">
              <label htmlFor="convertkit_api_secret_key">
                Convertkit Secret Key:
              </label>
              <div className="input-with-icon mt-5">
                <input
                  type="text"
                  id="convertkit_api_secret_key"
                  name="others.convertkit_api_secret_key"
                  placeholder="Convertkit Secret Key"
                  value={formData.others.convertkit_api_secret_key}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="form-group api-setting">
              <label htmlFor="convertkit_form_id">Convertkit Form ID:</label>
              <div className="input-with-icon mt-5">
                <input
                  type="text"
                  id="convertkit_form_id"
                  name="others.convertkit_form_id"
                  placeholder="Convertkit Form ID"
                  value={formData.others.convertkit_form_id}
                  onChange={handleChange}
                />
              </div>
            </div>
            <button className="custom-button primary active" type="submit">
              Submit
            </button>
          </form>
        </div>
      ) : null,
    },
  ];

  return (
    <AdminLayout
      page="dashboard"
      caption="Dashboard"
      message="Welcome back admin"
    >
      <div className="admin-users body-container">
        <div className="head">
          <Slide className="flex1">
            <h1>
              <span>API</span> Settings
            </h1>
          </Slide>
        </div>
        <Tabs tabs={tabs} />
      </div>
      <AuthenticationModal
        isOpen={isModalOpen}
        onClose={handleModalClose}
        onSubmit={handleModalSubmit}
      />
    </AdminLayout>
  );
}
