import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

const CToolTip = ({ text, children }) => {
    const [isVisible, setIsVisible] = useState(false);

    const handleMouseEnter = () => {
        setIsVisible(true);
    };

    const handleMouseLeave = () => {
        setIsVisible(false);
    };

    return (
        <div className="ctooltip-container" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            {children}
            {isVisible && (
                <div className="ctooltip-content">
                    {text}
                </div>
            )}
        </div>
    );
};

CToolTip.propTypes = {
    text: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
};

export default CToolTip;