import React from "react";
import { Typography, Box, styled } from "@mui/material";

const Text = styled(Typography)({
  textAlign: "center",
});
const MainBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

const NoDataFound = (props) => {
  const { searchText, message, style } = props;
  return (
    <MainBox sx={style}>
      {searchText ? (
        <Text variant="h6" sx={{ py: 1 }}>
          No results found for &nbsp;
          <strong>&quot;{searchText}&quot;</strong>.
          <br />
          Try checking for typos or using complete words.
        </Text>
      ) : (
        <Text variant="h6" sx={{ py: 1 }}>
          {message}
        </Text>
      )}
      <Text variant="h4" paragraph sx={{ fontWeigth: "bold" }}>
        No Data Found
      </Text>
    </MainBox>
  );
};

export default NoDataFound;
