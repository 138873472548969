import { LayoutContext } from "components/contexts/LayoutContextContainer";
import { DashboardLayout } from "components/layouts/DashboardLayout"
import { convertHeicImage } from "pages/Edit/utils";
import { ReactComponent as DownloadImg } from 'assets/imgs/create/download.svg';
import UploadView from "pages/MagicRemover/components/UploadView"
import { useContext, useEffect, useState } from "react";
import { getApi } from "utils/customNetwork";
import { formatDataUrl } from "utils/getDataUrl";
import { useDispatch, useSelector } from "react-redux";
import { getImage, getResult } from "store/slices/enhance/utils";
import { setImage, setResult } from "store/slices/enhance/reducer";
import { download } from "utils/imageUtil";
import { LICENSE } from "utils/constants";

import './index.scss'

const HdClarity = () => {
    const { setLoading, showNoti } = useContext(LayoutContext);
    const [showButton, setShowButton] = useState(false)
    const result = useSelector(getResult);
    const image = useSelector(getImage);
    const dispatch = useDispatch();

    const handleImageUpload = async (event) => {
        const file = event.target.files[0];

        if (!file) return;
        const reader = new FileReader();

        if (file.type === "" || file.type === "image/heic") {
            const blob = await convertHeicImage(file);
            handleImageToText(reader);
            reader.readAsDataURL(blob);
            return;
        }
        handleImageToText(reader);
        reader.readAsDataURL(file);
    };

    const handleImageToText = (reader) => {
        reader.onload = async () => {

            try {
                setLoading(true)
                const dataUrl = formatDataUrl(reader.result);

                const result = await getApi('/v1/images/generate/hd_clarity', 'POST', { image: dataUrl })

                dispatch(setImage(reader.result))
                dispatch(setResult(result.data))
                setLoading(false)
            } catch (e) {
                showNoti('error', e)
                setLoading(false)
            }
        }
    }

    const onShow = () => setShowButton(true);
    const onHide = () => setShowButton(false);

    const onDownload = () => download(result.imagePath, 'enhanced-image')

    useEffect(() => {
        dispatch(setImage(null));
        dispatch(setResult(null));
    }, []);

    return <DashboardLayout
        page="image-to-text"
        caption="Product Studio"
        message="Enhance Your product Photography with AI"
        license={[[LICENSE.PROFESSIONAL, LICENSE.BUSINESS], [LICENSE.PROFESSIONAL, LICENSE.STARTER]]}
    >
        <main id="enhance" className="body-container enhance-image">
            <UploadView
                content={{
                    mainTitle: <><span>Image</span> HD Clarity</>,
                    title: 'Upload an image for hd clarity',
                    subtitle: 'upload an image'
                }}
                onChange={handleImageUpload}
            />
            {result?.imagePath && image && <section className="image-section">
                <div className="image-item">
                    <p className="image-description">Before</p>
                    {image && <img src={image} alt='initial' className="image" />}
                </div>
                <div className="image-item">
                    <p className="image-description">After</p>
                    {result?.imagePath && <div
                        className="image-wrap"
                        onMouseOver={onShow}
                        onMouseLeave={onHide}
                        onTouchStart={onShow}
                        onTouchEnd={onHide}>
                        {showButton && <button onClick={onDownload} type='button' className="download-button"><DownloadImg /></button>}
                        <img
                            src={result.imagePath}
                            alt='result'
                            className="image"
                        />
                    </div>}
                </div>
            </section>}
        </main>

    </DashboardLayout>
}

export default HdClarity