import React, { useState } from 'react';
import './styles.scss';

export function Tabs({ tabs }) {
  const [activeTab, setActiveTab] = useState(tabs[0].label);

  return (
    <div className="tabs">
      <ul className="tab-list">
        {tabs.map(tab => (
          <li
            key={tab.label}
            className={`tab-list-item ${tab.label === activeTab ? 'active' : ''}`}
            onClick={() => setActiveTab(tab.label)}
          >
            {tab.label}
          </li>
        ))}
      </ul>
      <div className="tab-content">
        {tabs.map(tab => (
          tab.label === activeTab ? <div key={tab.label}>{tab.content}</div> : null
        ))}
      </div>
    </div>
  );
}
