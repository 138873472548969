import { useContext, useState, useEffect } from "react";
import Modal from "react-modal";
import { ReactComponent as IconClose } from "assets/imgs/icon_close.svg";

import { ReactComponent as IconPig } from "assets/imgs/modal/pig.svg";
import { ReactComponent as IconAIEdit } from "assets/imgs/modal/ai_edit.svg";
import { ReactComponent as IconAI } from "assets/imgs/modal/ai.svg";
import ImgExample from "assets/imgs/modal/example.png";
import { LayoutContext } from "components/contexts/LayoutContextContainer";
import { LICENSE } from "utils/constants";

import "./index.scss";

export function BasicMembershipModal({ show = false, onClose = () => { }, user }) {
  const { onPaddleCheckoutOpen } = useContext(LayoutContext);

  const handleStarterUpgradeBtnClick = () => {
    onClose();
    if (user?.user?.source === 'jvzoo') {
      window.open('https://creativio.io/jvz-special', '_blank');
    } else if (user?.user?.source === 'paddle') {
      onPaddleCheckoutOpen(LICENSE.STARTER);
    }
  };

  const handleBusinessUpgradeBtnClick = () => {
    onClose();
    if (user?.user?.source === 'jvzoo') {
      window.open('https://creativio.io/jvz-special', '_blank');
    } else if (user?.user?.source === 'paddle') {
      onPaddleCheckoutOpen(LICENSE.BUSINESS);
    }
  };

  return (
    <Modal
      isOpen={show}
      ariaHideApp={false}
      onClick={onClose}
      className="basic-membership-modal"
    >
      <div className="membership-modal__content">
        <IconClose className="cursor-pointer ico-close" onClick={onClose} />
        <div className="user-area">
          <h2 className="membership-modal__title">
            YES! Purchase Business or Starter License
          </h2>
          <h3 className="membership-modal__subtitle">
            UNLOCK The NEW AI Studio Editor + 5000 Credits
          </h3>
          <div className="mt-20 flex-group">
            <img
              className="sample-img"
              src={ImgExample}
              alt="example photoshoot"
            />
            <div className="features">
              <div className="feature">
                <div className="main-title">
                  <IconPig />
                  5000 Credits
                </div>
                <p>
                  Bid farewell to limitations. Dive into your projects, let your
                  creativity flow, and never worry about running out of credits
                  again. Your vision deserves uninterrupted execution.
                </p>
              </div>
              <div className="feature">
                <div className="main-title">
                  <IconAIEdit />
                  AI Design Studio
                </div>
                <p>
                  Welcome to the heart of innovation. Our AI Design Studio isn't
                  just a tool—it's your personal design assistant. Tailor
                  images, tweak visuals, and transform every project into a
                  masterpiece.
                </p>
              </div>
              <div className="feature">
                <div className="main-title">
                  <IconAI />
                  Advanced Tools
                </div>
                <p>
                  Why settle for basic when you can have the best? Equip
                  yourself with cutting-edge tools powered by the latest AI
                  advancements. Enhance, edit, and elevate with precision and
                  flair.
                </p>
              </div>
            </div>
          </div>
          <div className="stroke-line mt-20 mb-20" />
          <div className="last-group">
            <div className="last-caption">
              <h3 className="last-caption__title">
                Don't Just Design. Dominate.
              </h3>
              <p className="mt-10">
                Harness the full power of Creativio AI Professional and redefine
                what's possible in the realm of digital imagery.
                <b>This isn't just an upgrade; it's a game-changer.</b> Are you
                ready to lead the visual revolution?
              </p>
            </div>
            <button className="last-caption__not-now" onClick={onClose}>
              Not now
            </button>
            <div className="action-area">
              <button
                className="btn-upgrade"
                onClick={handleStarterUpgradeBtnClick}
              >
                <label>UPGRADE TO STARTER</label>
              </button>
              <button
                className="btn-upgrade"
                onClick={handleBusinessUpgradeBtnClick}
              >
                <label>UPGRADE TO BUSINESS</label>
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
