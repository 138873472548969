import React from "react";
import {
  FormControl,
  InputLabel,
  OutlinedInput,
  FormHelperText,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { COLORS } from "../../utils/theme";

export const CommonInput = ({
  label,
  handleBlur,
  handleChange,
  onKeyDown,
  error,
  value,
  name,
  type = "text",
  endAdornment = null,
  id,
  multiline,
  style,
  placeholder,
  sx,
  minRows,
  startAdornment,
  urlInput,
  errorProps,
  size = "small",
  ...rest
}) => {
  const theme = useTheme();
  return (
    <FormControl
      {...rest}
      fullWidth
      error={Boolean(error)}
      sx={
        !multiline
          ? { ...theme.typography.customInput, ...sx }
          : { ...theme.typography.customMulti, ...sx }
      }
    >
      {label ? (
        <InputLabel
          sx={{
            color: COLORS.white,
            fontSize: "1rem",
            "&.Mui-focused": {
              color: COLORS.white,
            },
          }}
          size={size}
        >
          {label}
        </InputLabel>
      ) : null}
      <OutlinedInput
        id={id || name}
        type={type}
        value={value}
        name={name}
        onKeyPress={(e) => onKeyDown && onKeyDown(e)}
        onBlur={handleBlur && handleBlur}
        onChange={handleChange && handleChange}
        label={label}
        inputProps={{}}
        endAdornment={endAdornment}
        startAdornment={startAdornment}
        multiline={multiline}
        size={size}
        sx={{
          ...style,
          color: COLORS.white,
          input: {
            "&::placeholder": {
              opacity: 0.8,
              color: COLORS.darkShadesGray,
            },
          },
          "&.Mui-focused fieldset": {
            borderColor: urlInput ? COLORS.border : "#7371fc",
          },
          "&:hover fieldset": {
            borderColor: `${urlInput ? COLORS.border : "#7371fc"} !important`,
          },

          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: urlInput ? COLORS.border : "#7371fc",
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#7371fc",
            borderWidth: "1px !important",
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#7371fc !important",
          },

          borderRadius: "0.6rem",
        }}
        placeholder={placeholder}
        minRows={minRows}
      />
      {error && (
        <FormHelperText {...{ sx: errorProps }} error>
          {error}
        </FormHelperText>
      )}
    </FormControl>
  );
};
