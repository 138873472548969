import * as React from 'react';
import { Tooltip } from 'react-tooltip';
import './styles.scss'

export function CButton({
    mainClass = "",
    addClass = "", // primary || secondary || dark || gradient || gradient2
    disabled = false,
    onClick = () => { },
    active = false,
    size = "",
    type = "button",
    children,
    tooltipContent,
    tooltipId,
    tooltipVariant = "light",
}) {
    return (
        <div className={`${mainClass}`} style={{ display: 'inline-block' }}>
            <button
                className={`custom-button ${addClass} ${active ? 'active' : ''} ${size}`}
                disabled={disabled}
                onClick={onClick}
                type={type}
                data-tooltip-id={tooltipId}
                data-tooltip-variant={tooltipVariant}
                data-tooltip-content={tooltipContent}
            >
                {children}
            </button>
            <Tooltip id={tooltipId} style={{ zIndex: 1 }} />
        </div>
    )
}