import { useContext, useState, useEffect } from "react";
import Modal from "react-modal";
import { ReactComponent as IconClose } from "assets/imgs/icon_close.svg";

import { ReactComponent as IconLimitless } from "assets/imgs/modal/animation_limitless.svg";
import { ReactComponent as IconHDVideo } from "assets/imgs/modal/animation_hd_video.svg";
import { ReactComponent as IconAI } from "assets/imgs/modal/animation_ai.svg";
import ImgExample from "assets/imgs/modal/animation_example.png";
import { LayoutContext } from "components/contexts/LayoutContextContainer";
import { LICENSE } from "utils/constants";

import "./index.scss";

export function AnimationMembershipModal({ show = false, onClose = () => { }, user }) {
  const { onPaddleCheckoutOpen } = useContext(LayoutContext);

  const handleUpgradeBtnClick = () => {
    onClose();
    // if (user?.user?.source === 'jvzoo') {
    window.open('https://creativio.io/jvz-animation-studio', '_blank');
    // } else if (user?.user?.source === 'paddle') {
    // onPaddleCheckoutOpen(LICENSE.ANIMATION);
    // }
  };

  return (
    <Modal
      isOpen={show}
      ariaHideApp={false}
      onClick={onClose}
      className="membership-modal"
    >
      <div className="membership-modal__content">
        <IconClose className="cursor-pointer ico-close" onClick={onClose} />
        <div className="user-area">
          <h2 className="membership-modal__title">
            YES! Upgrade to Animation Studio License
          </h2>
          <h3 className="membership-modal__subtitle">
            UNLOCK The NEW AI Animation Studio + Unlimited Credits
          </h3>
          <div className="mt-20 flex-group">
            <img
              className="sample-img"
              src={ImgExample}
              alt="example photoshoot"
            />
            <div className="features">
              <div className="feature">
                <div className="main-title">
                  <IconLimitless />
                  Limitless Animation
                </div>
                <p>
                  Say goodbye to mundane product shots. With unlimited access to animation credits, transform countless AI-generated photographs into animated wonders, ensuring your brand's visuals are boundless.
                </p>
              </div>
              <div className="feature">
                <div className="main-title">
                  <IconHDVideo />
                  Image To Video In HD
                </div>
                <p>
                  Elevate your animations to cinematic quality with Ultra-HD resolution. Each frame is a masterpiece, designed to captivate and engage like never before.
                </p>
              </div>
              <div className="feature">
                <div className="main-title">
                  <IconAI />
                  Attention-Grabbing Efects
                </div>
                <p>
                  Dive into an extensive library of exclusive animation effects and templates. Crafted by experts, these tools are designed to elevate your content and set you apart from the competition.
                </p>
              </div>
            </div>
          </div>
          <div className="stroke-line mt-20 mb-20" />
          <div className="last-group">
            <div className="last-caption">
              <h3 className="last-caption__title">
                Don't Just Shine - Animate It!
              </h3>
              <p className="mt-10">
                Harness the full power of Creativio AI Animation Studio and redefine what's possible in the realm of digital imagery. <b>This isn't just an upgrade; it's a game-changer.</b> Are you ready to lead the visual animation revolution?
              </p>
            </div>
            <button className="last-caption__not-now" onClick={onClose}>
              Not now
            </button>
            <div className="action-area">
              <button
                className="btn-upgrade"
                onClick={handleUpgradeBtnClick}
              >
                <label>UPGRADE NOW</label>
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
