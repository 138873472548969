import { LayoutContext } from "components/contexts/LayoutContextContainer";
import { DashboardLayout } from "components/layouts/DashboardLayout";
// import UploadView from "components/atoms/UploadView";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { convertHeicImage } from "pages/Edit/utils";
import { useContext, useEffect, useRef, useState } from "react";
import { Slide } from "react-awesome-reveal"
import CImage from "components/atoms/CImage";
import { CButton } from "components/atoms/CButton";
import { CInput } from "components/atoms/CInput";
import { CTextarea } from "components/atoms/CTextarea";
import { CToggle } from "components/atoms/CToggle";
import CRadio from "components/atoms/CRadio";
import { ReactComponent as ExitSvg } from "assets/imgs/studioCreator/exit.svg";
import BrushCanvas from "components/atoms/ImageEditor/BrushCanvas";
import UploadStep from "./UploadStep";
import { getApi } from "utils/customNetwork";
import { LICENSE } from "utils/constants";
import { CDropdown } from "components/atoms/CDropdown";
import { useDispatch, useSelector } from "react-redux";
import {
    varColors,
} from "./variable";
import {
    getBackupLayers,
    getImageSize,
    getImageType,
    getLayers,
    getNewImage,
    getOrgImageSize,
    getRedoStep,
    getResultImage,
    getSelectedImage,
    getStep,
    getReplaceMode,
} from "store/slices/colorChanger/utils";
import {
    setSelectedImage,
    setBackupLayers,
    setImageSize,
    setImageType,
    setLayers,
    setRedoStep,
    setResultImage,
    setStep,
    setReplaceMode,
} from "store/slices/colorChanger/reducer";

import './index.scss';

const ColorChanger = () => {
    const { showNoti, setLoading, checkToken } = useContext(LayoutContext);
    const dispatch = useDispatch();
    const brushCanvasRef = useRef(null);
    const step = useSelector(getStep);
    const imageSize = useSelector(getImageSize);
    const orgImageSize = useSelector(getOrgImageSize);
    const selectedImage = useSelector(getSelectedImage);
    const resultImage = useSelector(getResultImage);
    const [colorOptions, setColorOptions] = useState(varColors);
    const newImage = useSelector(getNewImage);
    const layers = useSelector(getLayers);
    const backupLayers = useSelector(getBackupLayers);
    const redoStep = useSelector(getRedoStep)
    const imageType = useSelector(getImageType);
    const replaceMode = useSelector(getReplaceMode);
    const [searchPrompt, setSearchPrompt] = useState('');
    const MODES = [
        {
            value: "mask",
            label: "Mask",
        },
        {
            value: "search",
            label: "Search",
        },
    ];

    const handleColorChanger = async () => {
        const selectedColor = getActiveMenu(colorOptions).id;
        if (replaceMode == 'mask') {
            if (brushCanvasRef.current.isEdited() === 0) {
                showNoti("info", "Please mask the object");
                return;
            }
        }
        if (replaceMode == 'search' && searchPrompt == '') {
            showNoti("info", "Please enter the search prompt");
            return;
        }

        let result = resultImage
        // call change color API
        const formData = new FormData();
        formData.append("mode", replaceMode);
        formData.append("type", imageType);
        formData.append("color", selectedColor);
        if (imageType === "url") {
            formData.append("url", resultImage);
        } else {
            formData.append("image", newImage);
        }
        formData.append("width", imageSize.width);
        formData.append("height", imageSize.height);
        if (replaceMode == 'mask') {
            const base64image = brushCanvasRef.current.getImage();
            formData.append("mask_image", base64image);
        } else {
            formData.append("search_prompt", searchPrompt);
        }

        try {
            setLoading(true);
            checkToken();
            const data = await getApi("/generate/changeColor", "POST", formData);
            const img = data.data?.image;
            dispatch(setImageType("url"));
            dispatch(setResultImage(img));
            dispatch(setImageSize({ width: data.data?.width, height: data.data?.height }));
            result = img;
        } catch (err) {
            showNoti("error", err);
        }
        setLoading(false);
        return result
    };

    const handleOptRatio = (id, index, value) => {
        switch (id) {
            case "color":
                const c1 = [...colorOptions];
                c1.forEach((el) => (el.checked = false));
                c1[index].checked = value;
                setColorOptions(c1);
                break;
        };
    };

    const getActiveMenu = (arr) => {
        const chk = arr.find((k) => k.checked);
        if (chk) return chk;
        else return arr[0];
    };

    const onExit = () => {
        dispatch(setStep('upload'));
        dispatch(setLayers([]));
        dispatch(setBackupLayers([]));
        dispatch(setRedoStep(0));
        dispatch(setImageType('upload'));
        dispatch(setSelectedImage(null));
        dispatch(setResultImage(null));
        setSearchPrompt('');
        setColorOptions(varColors);
    }

    useEffect(() => {
        dispatch(setStep('upload'));
        dispatch(setLayers([]));
        dispatch(setBackupLayers([]));
        dispatch(setRedoStep(0));
        dispatch(setSelectedImage(null));
        dispatch(setImageType('upload'));
        dispatch(setResultImage(null));
    }, []);

    const renderEdit = () => {
        return (
            <div className="edit-section">
                <div className="head">
                    <Slide className="flex1">
                        <h1>
                            <span className="title-span">{!!onExit && <button onClick={onExit} type='button'>
                                <ExitSvg />
                            </button>} Color Changer</span> Scene Setup
                        </h1>
                    </Slide>
                </div>
                <div className="edit-area">
                    <div className="replace-image">
                        {step === "edit" && replaceMode === "mask" && (
                            <div className="replace-object-brush-canvas">
                                <BrushCanvas
                                    maskType="white"
                                    ref={brushCanvasRef}
                                    image={{
                                        src: resultImage,
                                        width: imageSize.width,
                                        height: imageSize.height
                                    }}
                                    orgImage={{
                                        src: selectedImage,
                                        width: orgImageSize.width,
                                        height: orgImageSize.height
                                    }}
                                    onRemove={handleColorChanger}
                                    history={{
                                        redoStep,
                                        layers,
                                        backupLayers,
                                        setRedoStep: (step) => dispatch(setRedoStep(step)),
                                        setLayers: (layers) => dispatch(setLayers(layers)),
                                        setBackupLayers: (backupLayers) => dispatch(setBackupLayers(backupLayers))
                                    }}
                                />
                            </div>
                        )}
                        {step === "edit" && replaceMode === "search" && (
                            <div className="replace-image-box">
                                <LazyLoadImage
                                    className=''
                                    alt='picture'
                                    effect="blur"
                                    src={resultImage}
                                    width={768}
                                    height={768}
                                    useIntersectionObserver
                                />
                            </div>
                        )}
                    </div>
                    <div className="options-area">
                        <div className="cap1">Color Changer</div>
                        <div className="select-btn mt-15">
                            <fieldset>
                                <legend>Select Mode</legend>
                                {MODES.map((mode, index) => (
                                    <CRadio
                                        key={index}
                                        checked={replaceMode === mode.value}
                                        label={mode.label}
                                        name="mode"
                                        value={mode.value}
                                        onChange={(e) => dispatch(setReplaceMode(e.target.value))}
                                    />
                                ))}
                            </fieldset>
                        </div>
                        <div className="input-fields row mt-15">
                            {replaceMode === "search" && (
                                <div>
                                    <label htmlFor="search_prompt">Search Object</label>
                                    <CTextarea
                                        placeholder=""
                                        onChange={(e) => setSearchPrompt(e.target.value)}
                                        rows={3}
                                        addClass="product mt-5"
                                        active={true}
                                        value={searchPrompt}
                                    ></CTextarea>
                                </div>
                            )}
                            <div>
                                <label htmlFor="color">Select Color</label>
                                <CDropdown
                                    id="color"
                                    addClass="product full mt-5"
                                    action={handleOptRatio}
                                    activeMenu={getActiveMenu(colorOptions)}
                                    menu={colorOptions}
                                />
                            </div>
                            <div className="row mt-15">
                                <CButton
                                    addClass="gradient2 active full p-10I"
                                    mainClass="w-full"
                                    active={true}
                                    onClick={() => handleColorChanger()}
                                >
                                    Change Color
                                </CButton>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    // const renderResult = () => { }

    return <DashboardLayout
        page="color-changer"
        caption="Product Studio"
        message="Enhance Your product Photography with AI"
        license={[[LICENSE.PROFESSIONAL, LICENSE.BUSINESS], [LICENSE.PROFESSIONAL, LICENSE.STARTER]]}
    >
        <div id="color-changer" className="body-container color-changer">
            {step === "upload" && <UploadStep />}
            {step === "edit" && renderEdit()}
        </div>
    </DashboardLayout>
};

export default ColorChanger;