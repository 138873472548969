import { useEffect, useState, useContext } from "react";
import { Header } from "components/atoms/Header";
import { Sidebar } from "components/atoms/Sidebar";
import ReactHelmet from "react-helmet";

import { LICENSE } from "utils/constants";
import { LayoutContext } from "components/contexts/LayoutContextContainer";
import { getLicense, isPossible, showLicense } from "utils/util";
import { useNavigate } from "react-router-dom";

import "./dashboard.scss";
import { getApi } from "utils/customNetwork";
import { getNextLicenses } from "utils/getUpgradeLicense";

export function DashboardLayout({
  page = "Dashboard",
  caption = "",
  message = "",
  children,
  customHeader,
  license = [],
}) {
  const navigate = useNavigate();
  const [showContent, setShowContent] = useState(false);
  const { user, showNoti, setUser, setToken, setLoading } =
    useContext(LayoutContext);

  const checkLicense = async (user) => {
    return await getApi("/checkUser", "POST", {
      user_id: user.id,
    });
  };

  const checkPaymentSuccess = () => {
    let checkCount = 0;

    let timeout = setTimeout(function request() {
      const item = window.localStorage.getItem("user");
      const value = item ? JSON.parse(item) : null;

      setLoading(true);

      if (checkCount >= 10) {
        clearTimeout(timeout);
        setLoading(false);
      } else {
        checkLicense(value.user)
          .then((data) => {
            const nextLicense = getNextLicenses(value.user);
            if (nextLicense.includes(getLicense(data.user)) || checkCount >= 10) {
              const expired = Math.floor(Date.now() / 1000) + 10 * 60; // 10 mins
              setToken(data.token);
              setUser({ user: data.user, token: data.token, expired });
              clearTimeout(timeout);
              setLoading(false);
            } else {
              checkCount++;
              timeout = setTimeout(request, 1000);
            }
          })
          .catch(() => {
            setLoading(false);
            clearTimeout(timeout);
          });
      }

    }, 1000);
  };

  const onPaddleSetup = () => {
    window.Paddle.Environment.set(process.env.REACT_APP_PADDLE_ENVIRONMENT);
    window.Paddle.Setup({
      seller: parseFloat(process.env.REACT_APP_PADDLE_SELLER_ID),
      pwAuth: process.env.REACT_APP_PADDLE_PW_AUTH,
      eventCallback: async function (data) {
        if (data.name === "checkout.completed") {
          window.open("https://creativio.io/special-thank-you", "_blank");
          checkPaymentSuccess();
        }
      },
    });
  };

  useEffect(() => {
    const isValidLicense = isPossible(getLicense(user?.user), license);

    if (!isValidLicense) {
      let missingLicense = "";
      for (const permissionSet of license) {
        for (const prmsn of permissionSet) {
          if (!user?.user?.license.includes(prmsn)) {
            missingLicense = [prmsn];
            if ((missingLicense.includes(LICENSE.BUSINESS) || missingLicense.includes(LICENSE.STARTER))) {
              missingLicense = [LICENSE.BUSINESS, LICENSE.STARTER];
            }
            break;
          }
        }
        if (missingLicense) break;
      }

      const formatLicense = showLicense(missingLicense, true);

      showNoti(
        "info",
        `You need to upgrade to a ${formatLicense} license to unlock this feature.`
      );
      navigate("/dashboard");
    } else {
      setShowContent(true);
    }
  }, [license, navigate, showNoti, user?.user]);

  if (!showContent) return null;

  return (
    <>
      <ReactHelmet>
        <script type="text/javascript">{onPaddleSetup()}</script>
      </ReactHelmet>
      <div className="app-dashboard">
        <Sidebar />
        <Header
          page={page}
          caption={caption}
          message={message}
          customHeader={customHeader}
        />
        {children}
      </div>
    </>
  );
}
