import { CButton } from "components/atoms/CButton";
import ConfirmationModal from 'components/atoms/Modal/ConfirmationModal';
import { LayoutContext } from "components/contexts/LayoutContextContainer";
import { useContext, useRef, useState } from "react";
import { ReactComponent as Man } from "assets/imgs/auth/people.svg";
import { ReactComponent as Envelope } from "assets/imgs/auth/envelope.svg";
import { ReactComponent as Lock } from "assets/imgs/auth/key.svg";
import { ReactComponent as Eye } from "assets/imgs/auth/eye.svg";
import { ReactComponent as EyeClose } from "assets/imgs/auth/eye_close.svg";
import Upload from "assets/imgs/upload.svg";
import { ENGINES } from "./constants";
import CRadio from "components/atoms/CRadio";
import ProLabel from "components/molecules/ProLabel";
import { LICENSE } from "utils/constants";
import { useMemo } from "react";

export function Account({
  profile = null,
  handleUpdate = () => { },
  handleAvatarUpdate = () => { },
  handleAvatarRemove = () => { },
  handleChangePlatform = () => { },
  handleChangePrivacy = () => { },
}) {
  const fileInputRef = useRef(null);
  const { showNoti, showMembershipModal } = useContext(LayoutContext);
  const [newProfileImageURL, setNewProfileImageURL] = useState(null); // new image - URL
  const [newProfileImage, setNewProfileImage] = useState(null); // new image - file
  const [firstName, setFirstName] = useState(profile?.first_name || '');
  const [lastName, setLastName] = useState(profile?.last_name || '');
  const [email, setEmail] = useState(profile?.email || '');
  const [password, setPassword] = useState("");
  const [cPassword, setCPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showCPassword, setShowCPassword] = useState(false);
  const [isHover, setIsHover] = useState(false);
  const [showRemoveProfilePicConfirmation, setShowRemoveProfilePicConfirmation] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const first_name = event.target.first_name.value;
    const last_name = event.target.last_name.value;
    const email = event.target.email.value;
    const param = {
      first_name,
      last_name,
      email,
    };

    const password = event.target.password.value;
    const cpassword = event.target.cPassword.value;
    if (password !== "") {
      if (cpassword === "") {
        showNoti("info", "Please input confirm password");
        return;
      } else if (password !== cpassword) {
        showNoti("error", "You should input same password");
        return;
      } else {
        param.new_password = password;
      }
    }
    handleUpdate(param);
  };

  const onUploadAvatar = () => {
    if (!newProfileImage) {
      showNoti("info", "Please upload an image");
      return;
    }
    setNewProfileImage(null);
    setNewProfileImageURL(null);
    const formData = new FormData();
    formData.append("avatar", newProfileImage);
    handleAvatarUpdate(formData);
  };

  const shortName = () => {
    if (profile.first_name !== "") {
      return profile.first_name.substring(0, 2).toUpperCase();
    } else {
      return profile.email.substring(0, 2).toUpperCase();
    }
  };

  const onImageUpload = () => {
    fileInputRef.current.click();
  };

  const onRemoveAvatar = () => {
    if (newProfileImage || newProfileImageURL) {
      setNewProfileImageURL(null);
      setNewProfileImage(null);
      return;
    }
    else {
      handleAvatarRemove();
      // setShowRemoveProfilePicConfirmation(true);
    }
  }

  const closeRemoveProfilePicConfirmationModal = () => {
    setShowRemoveProfilePicConfirmation(false);
    setItemToDelete(null);
  };

  const confirmDeletion = async (item) => {

  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setNewProfileImageURL(reader.result);
      };

      setNewProfileImage(file);
      reader.readAsDataURL(file);
    }
  };

  const proLabelProps = useMemo(
    () =>
      // (!Array.isArray(profile.license) && profile.license === LICENSE.FREE_TRIAL) ||
      //     (Array.isArray(profile.license) && profile.license.length === 1 && profile.license[0] === LICENSE.FREE_TRIAL)
      (!Array.isArray(profile.license) || (!(profile.license.includes(LICENSE.BUSINESS) || profile.license.includes(LICENSE.STARTER))))
        // profile.license === LICENSE.FREE_TRIAL
        ? {}
        : {
          isHover,
          onClick: (e) => {
            showMembershipModal();
          },
        },
    [isHover, showMembershipModal, profile]
  );

  return (
    <div className="account-tab mt-20">
      <div className="avatar-section">
        <div className="avatar-box">
          {newProfileImageURL ? (
            <div className="user-avatar">
              <img src={newProfileImageURL} alt="profile" />
            </div>
          ) : (
            profile.profile_img ? (
              <div className="user-avatar">
                <img src={profile.profile_img} alt="profile" />
              </div>
            ) : (
              <div className="user-avatar">{shortName(profile)}</div>
            )
          )}
        </div>
        <div className="avatar-btns">
          <CButton addClass="primary mr-5" active={true} onClick={onImageUpload}>
            <input
              type="file"
              // accept="image/*"
              accept=".png, .gif, .jpg, .jpeg"
              onInput={handleImageUpload}
              onChange={(e) => e.target.value = null}
              style={{ display: "none" }}
              ref={fileInputRef}
            />
            {(profile.profile_img || newProfileImageURL) ? "Change Profile Picture" : "Add Profile Picture"}
          </CButton>

          {profile.profile_img || newProfileImageURL ? (
            <CButton addClass="primary mr-5" active={true} onClick={onRemoveAvatar}>
              Remove
            </CButton>
          ) : null}

          {newProfileImage && newProfileImageURL && (
            <CButton addClass="primary" active={true} onClick={onUploadAvatar}>
              Upload
            </CButton>
          )}
        </div>
        {showRemoveProfilePicConfirmation && (
          <ConfirmationModal
            isOpen={showRemoveProfilePicConfirmation}
            onClose={closeRemoveProfilePicConfirmationModal}
            itemModule="Settings"
            onConfirm={() => confirmDeletion(null)}
            itemName=''
          />
        )}
      </div>
      <div className="option-section">
        <form className="account-form">
          <fieldset>
            <legend>Select You AI Generative Engine</legend>
            {ENGINES.map((engine, index) => (
              <CRadio
                key={index}
                checked={profile.platform === engine.value}
                label={engine.label}
                name="platform"
                value={engine.value}
                onChange={handleChangePlatform}
              />
            ))}
          </fieldset>
          <fieldset>
            <legend>
              Keep Your Genertions Private?
              <div
                onMouseOver={() => setIsHover(true)}
                onMouseLeave={() => setIsHover(false)}
              >
                {(!profile.license.includes(LICENSE.PROFESSIONAL)) && (
                  <ProLabel {...proLabelProps} />
                )}
              </div>
            </legend>
            <CRadio
              checked={!profile.is_privacy_image}
              label="No"
              name="private"
              value="no"
              disabled={!profile.license.includes(LICENSE.PROFESSIONAL)}
              onChange={() => {
                handleChangePrivacy(false);
              }}
            />
            <CRadio
              checked={profile.is_privacy_image}
              label="Yes"
              name="private"
              value="yes"
              disabled={!profile.license.includes(LICENSE.PROFESSIONAL)}
              onChange={() => {
                handleChangePrivacy(true);
              }}
            />
          </fieldset>
        </form>
      </div>
      <div className="personal-info-section">
        <h4>Personal Information</h4>
        <form onSubmit={handleSubmit}>
          <div className="row mt-15">
            <div className="form-group">
              <label htmlFor="first_name">First Name:</label>
              <div className="input-with-icon mt-5">
                <Man className="icon-img" />
                <input
                  type="text"
                  id="first_name"
                  placeholder="Firstname"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  required
                />
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="last_name">Last Name:</label>
              <div className="input-with-icon mt-5">
                <Man className="icon-img" />
                <input
                  type="text"
                  id="last_name"
                  placeholder="Lastname"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="mt-15">
            <div className="form-group">
              <label htmlFor="email">Email:</label>
              <div className="input-with-icon mt-5">
                <Envelope className="icon-img" />
                <input
                  type="email"
                  id="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  disabled
                />
              </div>
            </div>
          </div>
          <div className="row mt-15">
            <div className="form-group">
              <label htmlFor="password">New Password:</label>
              <div className="input-with-icon mt-5">
                <Lock className="icon-img" />
                <input
                  type={showPassword ? "text" : "password"}
                  id="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                {!showPassword ? (
                  <Eye
                    className="last-icon-img"
                    onClick={() => setShowPassword(!showPassword)}
                  />
                ) : (
                  <EyeClose
                    className="last-icon-img"
                    onClick={() => setShowPassword(!showPassword)}
                  />
                )}
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="cPassword">New Confirm Password:</label>
              <div className="input-with-icon mt-5">
                <Lock className="icon-img" />
                <input
                  type={showCPassword ? "text" : "password"}
                  id="cPassword"
                  placeholder="Confirm Password"
                  value={cPassword}
                  onChange={(e) => setCPassword(e.target.value)}
                />
                {!showCPassword ? (
                  <Eye
                    className="last-icon-img"
                    onClick={() => setShowCPassword(!showCPassword)}
                  />
                ) : (
                  <EyeClose
                    className="last-icon-img"
                    onClick={() => setShowCPassword(!showCPassword)}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="form-group mt-25">
            <CButton addClass="primary" type="submit" active={true}>
              Save
            </CButton>
          </div>
        </form>
      </div>
    </div>
  );
}
