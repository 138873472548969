import React from "react";
import {
  TableRow,
  TableCell,
  TableSortLabel,
  TableHead,
  styled,
  Typography,
  Box,
  Checkbox,
} from "@mui/material";
import { COLORS } from "../../../utils/theme";
import { ICONS } from "../../../assets";

const CustomTableCell = styled(TableCell)({
  borderBottom: "1px solid #760cfe !important",
});

export const CustomTableHead = ({
  columns,
  numSelected,
  rowCount,
  onSelectAllClick,
  orderBy,
  order,
  onRequestSort,
  selectable,
  actions,
  isNotDefaultSorted,
}) => {
  const createSortHandler = (event, property) => {
    onRequestSort(event, property);
  };
  const Header = styled(Typography)({
    fontWeight: "bold",
    fontSize: "0.875rem",
    color: COLORS.white,
    paddingLeft: 16,
    width: "auto !important",
  });
  return (
    <TableHead>
      <TableRow sx={{ backgroundColor: "#760cfe" }}>
        {selectable && (
          <CustomTableCell
            padding="checkbox"
            align={"center"}
            sx={{ border: "none !important" }}
          >
            <Checkbox
              style={{
                color:
                  numSelected === rowCount ? COLORS.primaryBlue : COLORS.border,
              }}
              checked={numSelected === rowCount}
              onClick={onSelectAllClick}
            />
          </CustomTableCell>
        )}
        {columns?.map?.((headCell, index) => {
          const sorting = !headCell.hasOwnProperty("sorting")
            ? true
            : headCell.sorting;
          return (
            <CustomTableCell
              key={headCell.id}
              style={headCell.cellStyle}
              align={headCell.align || "left"}
              sx={{ p: 0, py: 2, border: "none !important" }}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              {!sorting || isNotDefaultSorted ? (
                <Header
                  sx={{
                    width: "100%",
                    display: "flex",
                    px: 2,
                    justifyContent: headCell.align ? headCell.align : "start",
                    borderLeft: index !== 0 && `1px solid ${COLORS.border}`,
                  }}
                >
                  {headCell.label}
                </Header>
              ) : (
                <TableSortLabel
                  // hideSortIcon
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={(e) => createSortHandler(e, headCell.fieldName)}
                  sx={{
                    width: "100%",
                    display: "flex",
                    px: 2,
                    justifyContent: headCell.align ? headCell.align : "start",
                    borderLeft: index !== 0 && `1px solid ${COLORS.border}`,
                  }}
                  IconComponent={() => {
                    return (
                      <Box sx={{ display: "flex" }}>
                        <ICONS.ArrowDropUp
                          style={{
                            color:
                              orderBy === headCell.id && order === "asc"
                                ? "#7371fc"
                                : COLORS.border,
                          }}
                        />
                        <ICONS.ArrowDropDownIcon
                          style={{
                            color:
                              orderBy === headCell.id && order === "desc"
                                ? "#7371fc"
                                : COLORS.border,
                          }}
                        />
                      </Box>
                    );
                  }}
                >
                  <Header>{headCell.label}</Header>
                </TableSortLabel>
              )}
            </CustomTableCell>
          );
        })}
        {actions && (
          <CustomTableCell align={"center"}>
            <Header>ACTION</Header>
          </CustomTableCell>
        )}
      </TableRow>
    </TableHead>
  );
};
