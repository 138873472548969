import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    image: null,
    result: "",
    imageType: "upload", // upload || url
};

export const imageToVideoSlice = createSlice({
    name: "imageToVideo",
    initialState,
    reducers: {
        setImage: (state, action) => {
            state.image = action.payload
        },
        setResult: (state, action) => {
            state.result = action.payload
        },
        setImageType: (state, action) => {
            state.imageType = action.payload
        }
    }
})

export const { setImage, setResult, setImageType } = imageToVideoSlice.actions;

export default imageToVideoSlice.reducer