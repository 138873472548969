import React from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import "./custom.css";

const editorConfiguration = {
  toolbar: [
    "undo",
    "redo",
    "|",
    "heading",
    "underline",
    "bold",
    "italic",
    "bulletedlist",
    "numberedlist",
    "|",
    "outdent",
    "indent",
    "Alignment",
    "|",
    "fontColor",
    "fontFamily",
    "highlight",
    "|",
    "code",
    "codeblock",
    "horizontalLine",
    "ckBox",
    "blockQuote",
    "insertTable",
  ],
};

const CustomEditor = ({
  value,
  handleChangeEditor,
  name,
  code,
  error,
  required,
  ...props
}) => {
  return (
    <div {...props} style={{ width: "100%" }}>
      <div
        style={
          error !== undefined
            ? { border: "0.2px solid #e71313", borderRadius: "6px" }
            : {}
        }
      >
        <CKEditor
          {...props}
          editor={Editor}
          config={editorConfiguration}
          data={value || code}
          name={name}
          onReady={(editor) => {
            editor.ui.view.editable.element.style.minHeight = "200px";
          }}
          onChange={(event, editor) => {
            const data = editor.getData();
            handleChangeEditor(data);
          }}
        />
      </div>
    </div>
  );
};

export default CustomEditor;
