import { DashboardLayout } from "components/layouts/DashboardLayout"; import React from 'react';

import { useNavigate } from "react-router-dom";
import SpeechRecognition, {
    useSpeechRecognition,
} from "react-speech-recognition";
import { LayoutContext } from "components/contexts/LayoutContextContainer";
import { useContext, useEffect, useState } from "react";
import ReactSlider from "react-slider";
import { CTextarea } from "components/atoms/CTextarea";
import { Slide } from "react-awesome-reveal";
import { LICENSE } from "utils/constants";
import { ReactComponent as Magic } from "assets/imgs/magic.svg";
import { ReactComponent as Stars } from "assets/imgs/stars.svg";
import { ReactComponent as Plus } from "assets/imgs/plus.svg";
import { ReactComponent as ArrowRight } from "assets/imgs/arrow_right.svg";
import { CButton } from "components/atoms/CButton";
import "./index.scss";
import { CDropdown } from "components/atoms/CDropdown";
import { CInput } from "components/atoms/CInput";
import { StyleBtns } from "./styleBtns";
import { getApi } from "utils/customNetwork";
import useContent from "hooks/studio/useContent";
import {
  varRatioOptions,
  varColorToneOptions,
  varLightningOptions,
  varCompositionOptions,
  varEngineOptions,
  varLanguageOptions,
} from "./variable";
import { CToggle } from "components/atoms/CToggle";
import { PhotoCard } from "components/atoms/PhotoCard";
import { checkPermission, getLicense, isPossible, showLicense } from "utils/util";
const VoiceToImage = () => {
  const { onImportImage } = useContent()
    const [advanced, setAdvanced] = useState(false);
    const [imageCt, setImageCt] = useState(1); // 1,2,3,4,5
    const navigate = useNavigate();  // Initialize the navigate function
    const [positiveText, setPositiveText] = useState(""); // A lighthouse on a cliff
    const [negativeText, setNegativeText] = useState("");
    const [promptStrength, setPromptStrength] = useState(7);
    const [activeContentType, setActiveContentType] = useState("none");
    const [stage, setStage] = useState("generate"); // generate || result
    const { showNoti, setLoading, showImageModal, checkToken, user } = useContext(LayoutContext);
    const [seed, setSeed] = useState(0);
    const [activeBtn, setActiveBtn] = useState(""); // magic || enhance || negative
    const [generationSteps, setGenerationSteps] = useState(30);
    const [selectedEngine, setSelectedEngine] = useState(null);
    const [ratioOptions, setRatioOptions] = useState(varRatioOptions);
    const [languageOptions, setLanguageOptions] = useState(varLanguageOptions);
    const [colorToneOptions, setColorToneOptions] = useState(varColorToneOptions);
    const [lightningOptions, setLightningOptions] = useState(varLightningOptions);
    const [compositionOptions, setCompositionOptions] = useState(varCompositionOptions);
    const [engineOptions, setEngineOptions] = useState(varEngineOptions);
    const [resultImages, setResultImages] = useState([]);
    const [effect, setEffect] = useState("");
    const {
        transcript,
        listening,
        resetTranscript,
        browserSupportsSpeechRecognition,
  } = useSpeechRecognition();
  
    useEffect(() => {
      if (!checkPermission(user)) {
        showNoti("error", "Permission denied. Please contact the administrator.");
        setTimeout(() => {
          navigate("/");
        }, 2000);
      }
    }, []);

    useEffect(() => {
      if (engineOptions) {
        setSelectedEngine(getActiveMenu(engineOptions).id);
      }
    }, [engineOptions]);
    
    useEffect(() => {
      if (user && user.user?.platform) {
        const initialSelectedOption = engineOptions.find(option => option.id === user.user.platform);
    
        // If user platform is not found or not provided, default to the first option
        if (!initialSelectedOption) {
          setSelectedEngine(engineOptions[0]);
          setEngineOptions(prevOptions => {
            return prevOptions.map((option, index) => ({
              ...option,
              checked: index === 0 // Set checked for the first option
            }));
          });
        } else {
          setSelectedEngine(initialSelectedOption);
          // Update the dropdown options
          setEngineOptions(prevOptions => {
            return prevOptions.map(option => ({
              ...option,
              checked: option.id === user.user.platform
            }));
          });
        }
      }
    }, []);

    const handleResetTranscript = () => {
      resetTranscript();
      setPositiveText("");
  };

  if (!browserSupportsSpeechRecognition) {
      return <span>Browser does not support speech recognition.</span>;
  }
  const getMagicText = async (param) => {
    if (positiveText === "") {
      showNoti("info", "Please input a message");
      return;
    }
    setActiveBtn(param);
    try {
      setLoading(true);
      checkToken();
      const data = await getApi("/generateText", "POST", {
        message: positiveText,
      });
      setPositiveText(data.message);
    } catch (err) {
      showNoti("error", err);
    }
    setLoading(false);
  };

  const handleDelete = async (image_id, index) => {
    try {
      setLoading(true);
      checkToken();
      await getApi("/deleteImage", "POST", { image_id });
      showNoti("success", "Image Deleted Successfully!");
      let temp = [...resultImages];
      temp.splice(index, 1);
      setResultImages(temp);
    } catch (err) {
      showNoti("error", err);
    }
    setLoading(false);
  };

  const onReGenerateImage = () => {
    setResultImages([]);
    setStage("generate");
  };

    const checkLicense = (license) => {
      const isValid = isPossible(getLicense(user?.user), license);
  
      if (!isValid) {
        let missingLicense = "";
        for (const permissionSet of license) {
          for (const prmsn of permissionSet) {
            if (!user?.user?.license.includes(prmsn)) {
              missingLicense = [prmsn];
              if ((missingLicense.includes(LICENSE.BUSINESS) || missingLicense.includes(LICENSE.STARTER))) {
                missingLicense = [LICENSE.BUSINESS, LICENSE.STARTER];
              }
              break;
            }
          }
          if (missingLicense) break;
        }
  
        const formatLicense = showLicense(missingLicense, true);
  
        showNoti(
          "info",
          `You need to upgrade to a ${formatLicense} license to unlock this feature.`
        );
      }
  
      return isValid;
    };

    const handleEdit = (image) => {
      // if (user?.user?.license && !user.user.license.includes(LICENSE.PROFESSIONAL)) {
      //   showNoti(
      //     "info",
      //     `You need to upgrade to a Pro license to unlock this feature.`
      //   ); return;
      // }
      if (checkLicense([[LICENSE.PROFESSIONAL, LICENSE.BUSINESS], [LICENSE.PROFESSIONAL, LICENSE.STARTER]])) {
        onImportImage(image);
      }
    }
  
    const handleDownload = (imageUrl, fileName) => {
      fetch(imageUrl)
        .then((response) => response.blob())
        .then((blob) => {
          const url = URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = fileName;
          a.click();
          URL.revokeObjectURL(url);
        })
        .catch((error) => {
          console.error("Error downloading image:", error);
          showNoti("error", "Error downloading image");
        });
    };

    const onChangeSlider = (param) => {
      setImageCt(param);
    };

    const onUpdateStyle = (action, params) => {
      if (action === "select") {
        let eft = ""; // Enum: 3d-model analog-film anime cinematic comic-book digital-art enhance fantasy-art isometric line-art low-poly modeling-compound neon-punk origami photographic pixel-art tile-texture
        switch (params) {
          case "digital_art":
            eft = "digital-art";
            break;
          case "Neon":
            eft = "neon-punk";
            break;
          case "layered_paper":
            eft = "analog-film";
            break;
          case "pixel_art":
            eft = "pixel-art";
            break;
          case "product_photo":
            eft = "anime";
            break;
          case "three_d_art":
            eft = "3d-model";
            break;
          case "realistic":
            eft = "enhance";
            break;
          case "graffiti":
            eft = "line-art";
            break;
          case "low_poly":
            eft = "low-poly";
            break;
          default:
            eft = params;
        }
        setEffect(eft);
      }
    };

    const handleOptRatio = (id, index, value) => {
      switch (id) {
        case "language":
          const customlangOptions = [...languageOptions];
          customlangOptions.forEach((el) => (el.checked = false));
          customlangOptions[index].checked = value;
          setLanguageOptions(customlangOptions);
          break;
        case "ratio_option":
          const customOptions = [...ratioOptions];
          customOptions.forEach((el) => (el.checked = false));
          customOptions[index].checked = value;
          setRatioOptions(customOptions);
          break;
        case "color_tone_option":
          const customColorToneOptions = [...colorToneOptions];
          customColorToneOptions.forEach((el) => (el.checked = false));
          customColorToneOptions[index].checked = value;
          setColorToneOptions(customColorToneOptions);
          break;
        case "lightning_option":
          const customLihgtningOptions = [...lightningOptions];
          customLihgtningOptions.forEach((el) => (el.checked = false));
          customLihgtningOptions[index].checked = value;
          setLightningOptions(customLihgtningOptions);
          break;
        case "composition_option":
          const customCompositionOptions = [...compositionOptions];
          customCompositionOptions.forEach((el) => (el.checked = false));
          customCompositionOptions[index].checked = value;
          setCompositionOptions(customCompositionOptions);
          break;
        case "engine_option":
          const customEngineOptions = [...engineOptions];
          customEngineOptions.forEach((el) => (el.checked = false));
          customEngineOptions[index].checked = value;
          setEngineOptions(customEngineOptions);
          // setSelectedEngine(engineOptions[index].id);
          break;
        default:
      }
    };

    const getActiveMenu = (arr) => {
      const chk = arr.find((k) => k.checked);
      if (chk) return chk;
      else return arr[0];
    };

    const startListening = () => {
        SpeechRecognition.startListening({ language: getActiveMenu(languageOptions).id, continuous: true });
    };

    const stopListening = () => {
      SpeechRecognition.stopListening();
      setPositiveText((positiveText ? positiveText + ' ' + transcript : transcript));
      resetTranscript();
    };

  const onGenerateImage = async () => {
    let param = {};
    SpeechRecognition.stopListening();
    const updatedText = positiveText + ' ' + transcript;
    const updatedPositiveText = updatedText.trim().split(/[\s,\t,\n]+/).join(' ');
    setPositiveText(updatedPositiveText); // Update the state
    resetTranscript();
      if (updatedPositiveText !== "") param.positive_text = updatedPositiveText;
      if (activeBtn === 'negative' && negativeText !== "") param.negative_text = negativeText;
      if (!updatedPositiveText && !negativeText) {
        showNoti("info", "Please input a text of meaning for image content");
        return;
      }
      if (seed !== "") param.seed = seed;
      param.platform = selectedEngine;
  
      const ratio = getActiveMenu(ratioOptions);
      if (selectedEngine == 'stablediffusion3') {
        param.aspect_ratio = ratio.id;
      }
      else {
        switch (ratio.id) {
          case "square":
            param.width = 1024;
            param.height = 1024;
            break;
          case "wide":
            param.width = 1152;
            param.height = 896;
            break;
          case "portrait":
            param.width = 896;
            param.height = 1152;
            break;
          default:
        }
        param.image_count = imageCt;
        if (promptStrength !== "") param.prompt_strength = promptStrength;
        if (generationSteps !== "") param.steps = generationSteps;
        if (effect !== "") param.style_preset = effect;
        if (activeContentType === "photo") {
          param.style_preset = "photographic";
        } else if (activeContentType === "digital") {
          param.style_preset = "digital-art";
        } else if (activeContentType === "cinematic") {
          param.style_preset = "cinematic";
        }
        param.content_type = activeContentType;
        param.color_tone = getActiveMenu(colorToneOptions).label;
        param.lightning = getActiveMenu(lightningOptions).label;
        param.composition = getActiveMenu(compositionOptions).label;
      }
      try {
        setLoading(true);
        checkToken();
        const data = await getApi("/generateImage", "POST", param);
  
        setResultImages(data.data);
        setStage("result");
      } catch (err) {
        showNoti("error", err);
      }
      setLoading(false);
    };

    const renderSetting = () => {
      return (
        <div className="section-setting">
          <div className="inner-section engine-area">
            <label htmlFor="engine_option">Engine</label>
            <CDropdown
              addClass="full mt-5"
              id="engine_option"
              action={handleOptRatio}
              activeMenu={getActiveMenu(engineOptions)}
              menu={engineOptions}
            />
            <small><i><sup>*</sup>&nbsp;Stable Diffusion 3.0 only supports aspect ratio.</i></small>
          </div>
          <div className="inner-section">
            <div className="ratio-area mt-15">
              <p className="mb-5">Aspect Ratio</p>
              <CDropdown
                addClass="full"
                id="ratio_option"
                showIcon={true}
                action={handleOptRatio}
                activeMenu={getActiveMenu(ratioOptions)}
                menu={ratioOptions}
              />
            </div>
            {selectedEngine !== "stablediffusion3" && (
              <>
                <div className="slider-wrap mt-15 top-border">
                  <div className="slider-caption">
                    <p className="mb-5">Image Count</p>
                    <p>{imageCt}</p>
                  </div>
                  <ReactSlider
                    className="slider"
                    thumbClassName="slider-thumb"
                    trackClassName="slider-track"
                    onChange={onChangeSlider}
                    value={imageCt}
                    min={1}
                    max={10}
                  />
                </div>
                <div className="content-type-area top-border">
                  <p className="mt-15 mb-10">Content Type</p>
                  <div className="row">
                    <CButton
                      addClass="gradient2"
                      mainClass="flex1"
                      active={activeContentType === "none"}
                      onClick={() => setActiveContentType("none")}
                    >
                      None
                    </CButton>
                    <CButton
                      addClass="gradient2"
                      mainClass="flex1"
                      active={activeContentType === "photo"}
                      onClick={() => setActiveContentType("photo")}
                    >
                      Photo
                    </CButton>
                  </div>
                  <div className="row mt-15">
                    <CButton
                      addClass="gradient2"
                      mainClass="flex1"
                      active={activeContentType === "cinematic"}
                      onClick={() => setActiveContentType("cinematic")}
                    >
                      Cinematic
                    </CButton>
                    <CButton
                      addClass="gradient2"
                      mainClass="flex1"
                      active={activeContentType === "digital"}
                      onClick={() => setActiveContentType("digital")}
                    >
                      Digital
                    </CButton>
                  </div>
                </div>
                <div className="style-area mt-15 top-border">
                  <p className="mb-10">Styles</p>
                  <StyleBtns onUpdateStyle={onUpdateStyle} />
                </div>
                <div className="effect-area top-border mb-15">
                  <p className="mt-15 mb-5">Color Tone</p>
                  <CDropdown
                    id="color_tone_option"
                    addClass="full"
                    showIcon={true}
                    action={handleOptRatio}
                    activeMenu={getActiveMenu(colorToneOptions)}
                    menu={colorToneOptions}
                  />
                  <p className="mt-15 mb-5">Lighting</p>
                  <CDropdown
                    id="lightning_option"
                    addClass="full"
                    showIcon={true}
                    action={handleOptRatio}
                    activeMenu={getActiveMenu(lightningOptions)}
                    menu={lightningOptions}
                  />
                  <p className="mt-15 mb-5">Composition</p>
                  <CDropdown
                    id="composition_option"
                    addClass="full"
                    showIcon={true}
                    action={handleOptRatio}
                    activeMenu={getActiveMenu(compositionOptions)}
                    menu={compositionOptions}
                  />
                </div>
              </>
            )}
            <div className='advanced-setting top-border'>
              <div className="mt-15 mb-5 flex advanced-btn" onClick={() => setAdvanced(!advanced)}>
                <p>Advanced Settings</p>
                <CToggle isOn={advanced} onClick={() => setAdvanced(!advanced)} />
              </div>
              {advanced && (
                <div className="advanced">
                  {selectedEngine !== "stablediffusion3" && (
                    <div className="wrap mt-20">
                      <div className="item">
                        <p>Prompt Strength</p>
                        <CInput
                          type="number"
                          value={promptStrength}
                          min={0}
                          max={35}
                          onChange={(e) => setPromptStrength(e.target.value)}
                          addClass="w-100"
                        />
                      </div>
                      <div className="item">
                        <p>Generation Steps</p>
                        <CInput
                          type="number"
                          value={generationSteps}
                          min={10}
                          max={150}
                          onChange={(e) => setGenerationSteps(e.target.value)}
                          addClass="w-100"
                        />
                      </div>
                    </div>
                  )}
                  <div className="wrap mt-10">
                    <div className="item">
                      <p>Seed</p>
                      <CInput
                        type="number"
                        value={seed}
                        min={0}
                        max={9999999}
                        onChange={(e) => setSeed(e.target.value)}
                        addClass="w-100"
                      />
                    </div>
                  </div>
                  {selectedEngine !== "stablediffusion3" && (
                    <>
                      <div className="wrap mt-10">
                        <div className="item">
                          <p>Model</p>
                        </div>
                      </div>
                      <div className="wrap wrap-model">
                        <div className="item">
                          <CInput value="SDXL Beta" addClass="w-250" />
                        </div>
                        <div className="arrow-right">
                          <ArrowRight className="cursor-pointer" />
                        </div>
                      </div>
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      );
    };
    const renderSection1 = () => {
      return (
        <div className="section1">
          <Slide>
            <h1>
              <span>Voice To Image</span> Generation Tool
            </h1>
          </Slide>
          <Slide>
            <h1>Enter Your Prompt using mic</h1>
          </Slide>
          <div className="">
            <CButton
              addClass="gradient mr-15"
              active={activeBtn === "magic"}
              onClick={() => getMagicText("magic")}
            >
              <Magic className="btn-icon" />
              Prompt Magic
            </CButton>
            <CButton
              addClass="gradient"
              active={activeBtn === "enhance"}
              onClick={() => getMagicText("enhance")}
            >
              <Stars className="btn-icon" />
              Enhance Prompt
            </CButton>
          </div>
          <div className="mt-25">
          <label htmlFor="language-select">Choose a language: </label>
          <CDropdown
                addClass="full"
                id="language"
                showIcon={true}
                action={handleOptRatio}
                activeMenu={getActiveMenu(languageOptions)}
                menu={languageOptions}
              />
          </div>
          <div className="mt-25">
            <CTextarea
              placeholder="Ex: Beautiful flowers and a blob object frozen in a block of ice, isolated on warm-grey background"
              active={["magic", "enhance"].includes(activeBtn)}
              value={!listening ? positiveText : (positiveText ? positiveText + ' ' + transcript : transcript)}
              onChange={(e) => setPositiveText(e.target.value)}
              readOnly={listening}
            ></CTextarea>
          </div>
          <div className="mt-5">
                <p className="">Microphone: {listening ? 'Listing to your voice for prompt.' : 'Off. When you click on the start button, you will receive a prompt to use for further.'}</p>
            </div>
            <div className="btn-section mt-5">
                <CButton className="custom-button" addClass="gradient mr-5 active" onClick={startListening}>Start</CButton>
                <CButton className="custom-button" addClass="gradient mr-5 active" onClick={stopListening}>Stop</CButton>
                <CButton className="custom-button" addClass="gradient mr-5 active" onClick={handleResetTranscript}>Reset</CButton>
            </div>
          <div className="mt-25">
            <CButton
              addClass="gradient dark mr-15"
              active={activeBtn === "negative"}
              onClick={() => setActiveBtn("negative")}
            >
              <Plus className="btn-icon" />
              Add Negative Prompt
            </CButton>
          </div>
          {activeBtn === 'negative' && <div className="mt-10">
            <p className="text-semi-dark ml-5">Insert Negative Prompt:</p>
            <CTextarea
              active={["negative"].includes(activeBtn)}
              placeholder="Ex: Deformed, ugly, blurry, inestetic, etc..."
              // readOnly={!['negative'].includes(activeBtn)}
              onChange={(e) => setNegativeText(e.target.value)}
              value={negativeText}
            />
          </div>}
          <div className="mt-30 text-right">
            <CButton
              addClass="gradient active"
              onClick={onGenerateImage}
              size="medium"
            >
              Generate Image
            </CButton>
          </div>
        </div>
      );
    };
  
    const renderSection2 = () => {
      return (
        <div className="section2">
          <Slide>
            <h1>
              <span>Your Generated Images</span> Are Ready
            </h1>
          </Slide>
          <div className="mt-20 result-image-area">
            {resultImages.map((k, index) => (
              <PhotoCard
                key={index}
                image={k}
                images={resultImages}
                onView={showImageModal}
                onEdit={handleEdit}
                deleteImage={true}
                onDownload={handleDownload}
                onDelete={(image_id) => handleDelete(image_id, index)}
              />
            ))}
          </div>
          <div className="mt-30">
            <CButton
              addClass="gradient active"
              onClick={onReGenerateImage}
              size="medium"
            >
              Generate Again
            </CButton>
          </div>
        </div>
      );
    };
    return <DashboardLayout
        page="voice-to-image-changer"
        caption="Product Studio"
        message="Enhance Your product Photography with AI"
        license={[[LICENSE.PROFESSIONAL, LICENSE.BUSINESS], [LICENSE.PROFESSIONAL, LICENSE.STARTER]]}
    >
            <div id="create" className="body-container">
              {stage === "generate" && renderSection1()}
              {stage === "generate" && renderSetting()}
              {stage === "result" && renderSection2()}
            </div>
    </DashboardLayout>
};

export default VoiceToImage;