import Modal from "react-modal";
import { useContext, useEffect, useState } from "react";
import { ReactComponent as IconClose } from "assets/imgs/icon_close.svg";
import { getApi } from "utils/customNetwork";
import { LayoutContext } from "components/contexts/LayoutContextContainer";
import { CButton } from "components/atoms/CButton";
import { CDropdown } from "components/atoms/CDropdown";
import { LICENSE } from "utils/constants";

import "./index.scss";

export function InviteMemberModal({
  show = false,
  user = {},
  onClose = () => { },
  onHandleInvite = () => { },
}) {
  const { showNoti, setLoading, checkToken } = useContext(LayoutContext);
  const [invitationEmail, setInvitationEmail] = useState('');
  const [licenseOptions, setLicenseOptions] = useState([{}]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    if (invitationEmail == '') {
      showNoti("info", "Please enter an email");
      return;
    }
    if (getActiveMenu(licenseOptions).id == undefined || getActiveMenu(licenseOptions).id == null) {
      showNoti("info", "Please Select a license");
      return;
    }
    formData.append("email", invitationEmail);
    formData.append("license", getActiveMenu(licenseOptions).id);
    try {
      setLoading(true);
      checkToken();
      const data = await getApi("/agency/sendInvitation", "POST", formData);
      showNoti("success", "Invitation Send Successfully!");
      onHandleInvite(data.member);
      onClose();
    } catch (err) {
      showNoti("error", err);
    }
    setLoading(false);
  };

  const getActiveMenu = (arr) => {
    const chk = arr.find((k) => k.checked);
    if (chk) return chk;
    else return arr[0];
  };

  const handleOptRatio = (id, index, value) => {
    switch (id) {
      case "license":
        const c1 = [...licenseOptions];
        c1.forEach((el) => (el.checked = false));
        c1[index].checked = value;
        setLicenseOptions(c1);
        break;
      default:
        break;
    }
  };

  const ALLOWED_LICENSES = [
    {
      value: LICENSE.STARTER,
      label: "Starter",
    },
    {
      value: LICENSE.BUSINESS,
      label: "Business",
    },
    {
      value: LICENSE.PROFESSIONAL,
      label: "Professional",
    },
    {
      value: LICENSE.ANIMATION,
      label: "Animation",
    },
  ];

  const generateLicenseOptions = (userLicenses) => {
    const matchedLicenses = userLicenses.filter(license => {
      return Object.values(LICENSE).includes(license);
    });

    if (matchedLicenses.length === 0) {
      return [{
        key: 1,
        id: LICENSE.FREE_TRIAL,
        checked: true,
        label: "Free Trial",
      }];
    }

    const options = matchedLicenses.map((license, index) => {
      const option = ALLOWED_LICENSES.find(option => option.value === license);
      if (option) {
        return {
          key: index + 1,
          id: option.value,
          checked: true,
          label: option.label,
        };
      }
    }).filter(Boolean);

    const lastMatchIndex = options.findIndex(option => option.id === matchedLicenses[matchedLicenses.length - 1]);
    if (lastMatchIndex !== -1) {
      options[lastMatchIndex].checked = true;
    }
    return options;
  };

  useEffect(() => {
    if (user?.license) {
      const varOptions = generateLicenseOptions(user?.license);
      setLicenseOptions(varOptions);
    }
  }, [user]);

  return (
    <Modal
      isOpen={show}
      ariaHideApp={false}
      onClick={onClose}
      className="invite-member-modal"
    >
      <IconClose className="cursor-pointer ico-close" onClick={onClose} />
      <div className="invite-area">
        <h2 className="text-center">Invite Member</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group mt-10">
            <div className="invitation-email">
              <p>Email</p>
              <div className="input-field mt-10">
                <input
                  type="email"
                  id="email"
                  placeholder="Email"
                  value={invitationEmail}
                  onChange={(e) => setInvitationEmail(e.target.value)}
                  required
                />
              </div>
              <div className="mt-10">
                <p>License</p>
                <div className="mt-10">
                  <CDropdown
                    id="license"
                    addClass="bg-gray full"
                    showIcon={false}
                    action={handleOptRatio}
                    activeMenu={getActiveMenu(licenseOptions)}
                    menu={licenseOptions}
                  />
                </div>
              </div>
            </div>
            <div className="text-right">
              <CButton
                mainClass="mt-10"
                addClass="gradient p-5I"
                active={true}
                type="submit"
              >
                Send
              </CButton>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
}
