import logo from "assets/imgs/logo.svg";
import "./styles.scss";
import { useContext, useEffect, useState } from "react";
import { LayoutContext } from "components/contexts/LayoutContextContainer";
import { MenuItem } from "../MenuItem";

import IconHome from "assets/imgs/menu/icon_home.svg";
import IconPersonal from "assets/imgs/menu/icon_personal.svg";
import IconSetting from "assets/imgs/menu/icon_setting.svg";
import IconMail from "assets/imgs/menu/icon_mail.svg";
import IconStatistics from "assets/imgs/menu/icon_statistics.svg";
import IconLicense from "assets/imgs/menu/icon_license.svg";
import IconVideo from "assets/imgs/menu/icon_video.svg";
import IconLogs from "assets/imgs/menu/icon_logs.svg";
import { Link, useLocation } from "react-router-dom";

export function AdminSidebar() {
  const { mobileSide, adminUser } = useContext(LayoutContext);
  const [openMenu, setOpenMenu] = useState(false);
  const { pathname } = useLocation();

  const getActiveMenu = () => {
    if (pathname.indexOf("/" + "create") !== -1) {
      setOpenMenu(true);
    }
  };

  useEffect(() => {
    getActiveMenu();
  }, []);

  return (
    <div className={`sidebar-container ${mobileSide ? "mobile" : ""}`}>
      <div className="logo">
        <Link to="/admin/dashboard">
          <img src={logo} alt="logo" />
        </Link>
      </div>
      <div className="menu">
        <MenuItem
          user={adminUser}
          icon={IconHome}
          link="admin/dashboard"
          label="Dashboard"
        />
        <MenuItem
          user={adminUser}
          icon={IconVideo}
          link="admin/webinar"
          label="Webinar"
        />
        <MenuItem
          user={adminUser}
          icon={IconLicense}
          link="admin/licenses"
          label="License Manager"
        />
        <MenuItem
          user={adminUser}
          icon={IconPersonal}
          link="admin/users"
          label="User Management"
        />
        <MenuItem
          user={adminUser}
          icon={IconStatistics}
          link="admin/statistics"
          label="Statistics"
        />
        <MenuItem
          user={adminUser}
          icon={IconLogs}
          link="admin/logs"
          label="API Logs"
        />
        <MenuItem
          user={adminUser}
          icon={IconSetting}
          link="admin/settings"
          label="API Settings"
        />
        <MenuItem
          user={adminUser}
          icon={IconMail}
          link="admin/templates"
          label="Email Templates"
        />
      </div>
    </div>
  );
}
