export const isFunction = (fn) => {
  if (typeof fn === "function") {
    return true;
  }
  return false;
};
export const checkFormError = (errors) => {
  return Object.keys(errors).length !== 0;
};

export const toCapitalCase = (string) => {
  return string ? string.charAt(0).toUpperCase() + string.slice(1) : "";
};

export const sortCategoryList = (data) => {
  let sortData = data.map((item) => {
    return {
      id: item.id,
      name: toCapitalCase(item.name),
      value: item.id,
      logo: item.logo,
      mirror_of_data: item?.mirror_of_data,
    };
  });
  return sortData;
};

export const copyShareURL = async (text) => {
  if ("clipboard" in navigator) {
    return await navigator.clipboard.writeText(text);
  } else {
    return document.execCommand("copy", true, text);
  }
};

export const activePath = () => window.location.pathname || null;
