import "./styles.scss";
import { DashboardLayout } from "components/layouts/DashboardLayout";
import { useContext, useEffect, useRef, useState } from "react";
import { Slide, Fade } from "react-awesome-reveal";
import { CButton } from "components/atoms/CButton";
import { ReactComponent as Plus } from "assets/imgs/plus_white.svg";
import { LICENSE } from "utils/constants";
import { getApi } from "utils/customNetwork";
import { LayoutContext } from "components/contexts/LayoutContextContainer";
import { ReactComponent as IconRemove } from "assets/imgs/action/icon_delete.svg";
import { Tooltip } from "react-tooltip";
import ConfirmationModal from 'components/atoms/Modal/ConfirmationModal';
import { InviteMemberModal } from 'components/atoms/Modal/InviteMemberModal';

export function AgencyMember() {
    const { showNoti, setLoading, checkToken, user } = useContext(LayoutContext);
    const [members, setMembers] = useState([]);
    const [showMemberDelCnfm, setShowMemberDelCnfm] = useState(false);
    const [showInvitationModel, setShowInvitationModel] = useState(false);
    const [itemToDel, setItemToDel] = useState(null);

    const getMembers = async () => {
        try {
            checkToken();
            setLoading(true);
            const data = await getApi("/agency/getMembers", "POST", {});
            setMembers(data.members);
        } catch (err) {
            showNoti("error", err);
        }
        setLoading(false);
    };

    useEffect(() => {
        getMembers();
    }, []);

    const OnInviteMember = () => {
        if (members.length >= 5) {
            showNoti("error", "You have reached the maximum number of invitations.");
            return;
        }
        setShowInvitationModel(true);
    };

    const OnCloseInviteMemberModel = () => {
        setShowInvitationModel(false);
    };

    const onHandleInvite = (data) => {
        setMembers([...members, ...data]);
    };

    const handleTblDelete = async (item) => {
        setItemToDel(item);
        setShowMemberDelCnfm(true);
    };

    const closeDelModal = () => {
        setShowMemberDelCnfm(false);
        setItemToDel(null);
    };

    const cnfmDel = async (item) => {
        setLoading(true);
        try {
            checkToken();
            await getApi(`/agency/deleteMember/${item.id}`, "DELETE");
            const updatedMembersRecords = members.filter(member => member.id !== item.id);
            setMembers(updatedMembersRecords);
            setShowMemberDelCnfm(false);
            setItemToDel(null);
            showNoti("success", "Team Member Deleted successfully");
        } catch (err) {
            showNoti("error", err);
        }
        setLoading(false);
    };

    return (
        <DashboardLayout
            page="client"
            caption="Manage Agency Members"
            license={[[LICENSE.AGENCY, LICENSE.BUSINESS], [LICENSE.AGENCY, LICENSE.STARTER]]}
            message="Add and Delete your Agency Team Members"
        >
            <div id="agency-members" className="body-container">
                <div className="head">
                    <Slide className="flex1">
                        <h1>
                            <span>Manage</span> Team Members
                        </h1>
                    </Slide>
                    <CButton
                        mainClass="mt-20"
                        addClass="gradient p-5I"
                        active={true}
                        onClick={OnInviteMember}
                    >
                        <Plus className="btn-icon" />
                        Invite Team Member
                    </CButton>
                </div>
                <div className="members-section">
                    <div className="custom-table">
                        <table>
                            <thead>
                                <tr>
                                    <th className="tableHeader">Email</th>
                                    <th className="tableHeader">Role</th>
                                    <th className="tableHeader">Status</th>
                                    <th className="tableHeader">Created At</th>
                                    <th className="tableHeader">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {members.length === 0 ? (
                                    <tr>
                                        <td colSpan="5" className="tableCell text-center">
                                            No Records Available!
                                        </td>
                                    </tr>
                                ) : (
                                    members.map((el) => (
                                        <tr key={el.id}>
                                            <td className="tableCell">{el.invitedUser?.email}</td>
                                            <td className="tableCell">{el.invitedUser?.role}</td>
                                            <td className="tableCell">{el.status}</td>
                                            <td className="tableCell">{el.createdAt}</td>
                                            <td className="tableCell text-center">
                                                <IconRemove
                                                    data-tooltip-id="tip-delete"
                                                    data-tooltip-variant="light"
                                                    data-tooltip-content="Delete"
                                                    onClick={() => handleTblDelete(el)}
                                                />
                                            </td>
                                        </tr>
                                    )))}
                            </tbody>
                        </table>
                        <Tooltip id="tip-delete" />
                    </div>
                    {
                        showMemberDelCnfm && (
                            <ConfirmationModal
                                isOpen={showMemberDelCnfm}
                                onClose={closeDelModal}
                                itemModule="Team Member"
                                onConfirm={() => cnfmDel(itemToDel)}
                                itemName={itemToDel ? itemToDel.invitedUser?.email : ''}
                            />
                        )
                    }
                    {
                        showInvitationModel && (
                            <InviteMemberModal
                                show={showInvitationModel}
                                user={user.user}
                                onClose={OnCloseInviteMemberModel}
                                onHandleInvite={onHandleInvite}
                            />
                        )
                    }
                </div>
            </div>
        </DashboardLayout>
    );
}